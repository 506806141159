import React, { useState } from "react";
import OverviewTab from "../OverviewTab/OverviewTab";
import InvoicesTab from "../InvoicesTab/InvoicesTab";
import QuotesTab from "../QuotesTab/QuotesTab";
import CarsListTab from "../CarListTab/CarList";

const Tabs = (props) => {
  const tabs = {
    Overview: "Overview",
    Invoices: "Invoices",
    Quotes: "Quotes",
    CarsList: "Cars List",
    Statistics: "Statistics",
    Prices: "Prices",
  };

  const tabList = [
    {
      title: tabs.Overview,
    },
    {
      title: tabs.Invoices,
    },
    {
      title: tabs.Quotes,
    },
    {
      title: tabs.CarsList,
    },
    {
      title: tabs.Statistics,
    },
    {
      title: tabs.Prices,
    },
  ];

  const [selectedTab, setSelectedTab] = useState(tabList[0]);

  return (
    <>
      <div className="garage-details-page">
        <ul className="d-flex">
          {tabList.map((tab, index) => (
            <li key={index}>
              <a
                type="button"
                className={
                  selectedTab.title === tab.title ? "active" : "text-dark"
                }
                onClick={() => setSelectedTab(tab)}
              >
                {tab.title}
              </a>
            </li>
          ))}
        </ul>
        <div className="content">
          {selectedTab.title === tabs.Overview ? (
            <OverviewTab />
          ) : selectedTab.title === tabs.Invoices ? (
            <InvoicesTab />
          ) : selectedTab.title === tabs.Quotes ? (
            <QuotesTab />
          ) : selectedTab.title === tabs.CarsList ? (
            <CarsListTab />
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default Tabs;
