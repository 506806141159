import { Box, Button, Modal, Typography } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import IconButton from "@mui/material/IconButton";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import SoftInput from "components/SoftInput";
import AddIcon from "@mui/icons-material/Add";
import placeholderUser from "../../assets/images/placeholderUser.jpeg";
// import ChipDelete from "@mui/joy/ChipDelete";
import InputAdornment from "@mui/material/InputAdornment";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

export const EditCar = ({
  // open,
  // handleClose,
  style,
  inputFields,
  handleInputChange,
  handleAddField,
  handleImageUpload,
  makeName,
  image,
  setMakeName,
  brandName,
  setBrandName,
  // handleSave,
  uploadedImage,
  // modalName,
  // handleModelDelete
}) => {
  // const handleSubmit =(e) =>{
  //    e.preventDefault()
  //   if(modalName==='view'){
  //     handleClose()
  //   }else{
  //     handleSave()
  //   }
  const uuid = uuidv4;
  const location = useLocation();
  // }
  const navigate = useNavigate();
  const [carModels, setCarModels] = useState([]);
  console.log("🚀 ~ carModels:", carModels)
  const [modalName, setModalName] = useState("");
  const [data, setData] = useState({
    category_title: "",
    Brand_name: "",
    logo: "",
  });
  const [id, setId] = useState("");

  // Access individual query parameters
  const styles = {
    icon: {
      width: "1.3rem",
    },
    labelforinput: {
      position: "absolute",
      right: "0px",
      bottom: "12px",
      background: "lightblue",
      width: "30px",
      height: "30px",
      borderRadius: "50%",
      lineHeight: "-1",
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    previewImage: {
      width: "120px",
      height: "120px",
      borderRadius: "50%",
    },

    selectContainer: {
      position: "relative",
    },

    chevIocn: {
      position: "absolute",
      zIndex: "999",
      right: "10px",
      top: "10px",
    },
  };

  const submit = async () => {
    if (!data?.logo || !data?.category_title || !data?.Brand_name) {
      toast.error("All fields are mandatory.");
      return;
    }

 
        let inputFields = carModels?.filter((val)=> val.brandName).map((val)=> val.brandName)

    const payload = {
      logo: data?.logo,
      category_title: data?.category_title,
      main_title: data?.Brand_name,
      carmodel: inputFields,
    };
    try {
      const res = await axios.patch(
        `https://betazone1.promaticstechnologies.com:5011/admin/editcarmodel/${id}`,
        payload
      );
      if (res.status === 200) {
        toast.success("Brand has been updated successfully.");
        navigate(-1)
      }
    } catch (error) {

    }
  };

 const  submit1 = async ()=>{
  if (!data?.logo || !data?.category_title || !data?.Brand_name) {
    toast.error("All fields are mandatory.");
    return;
  }

  let inputFields = carModels?.filter((val)=> val.brandName).map((val)=> val.brandName)

  const payload = {
    logo: data?.logo,
    category_title: data?.category_title,
    main_title: data?.Brand_name,
    carmodel: inputFields,
  };
  try {
    const res = await axios.post(
      `https://betazone1.promaticstechnologies.com:5011/admin/addCategory`,
      payload
    );
    if (res.status === 200) {
      toast.success("Brand has been Added");
      navigate(-1)
    }
  } catch (error) {

  }
 }

  // const addCardModalFields = ()=>{
  //   setCarModels([...carModels, <Col md={4}>
  //     <Typography sx={{ mt: 2 }}>
  //       <SoftBox mb={2} display="flex" flexDirection="column">
  //         <div className="d-flex">
  //           <SoftInput
  //             type="text"
  //             className="TextField"
  //             name="question"
  //             placeholder="enter brand name"
  //             value={brandName}
  //             disabled={modalName === "view"}
  //             required
  //           />
  //           <IconButton
  //             className="delete-models"
  //             aria-label="delete" // Define a function to handle the delete action
  //           >
  //             <DeleteIcon />
  //           </IconButton>
  //         </div>
  //       </SoftBox>
  //     </Typography>
  //   </Col>])
  // }
  const addCardModalFields = () => {
    setCarModels([...carModels, { id: uuid(), brandName: "" }]);
  };

  const deleteHandler = (index) => {
    const newCarModels = [...carModels];
    newCarModels.splice(index, 1); // Remove one element at the specified index
    setCarModels(newCarModels);
  };

  const handleBrandNameChange = (id, value) => {
    setCarModels((prevModels) => {
      const updatedModels = prevModels.map((model) =>
        model.id === id ? { ...model, brandName: value } : model
      );
      return updatedModels;
    });
  };

  const getData = async (brand) => {
    try {
      const res = await axios.get(
        `https://betazone1.promaticstechnologies.com:5011/admin/findallcarlogos?id=${brand}`
      );
      console.log(res, res?.data?.subcategory[0]?.carmodel);
      setData({
        category_title: res?.data?.subcategory[0]?.category_title || "",
        Brand_name: res?.data?.subcategory[0]?.main_title || "",
        logo: res?.data?.subcategory[0]?.logo || "",
      });

      let modals = res?.data?.subcategory[0]?.carmodel?.map((val) => {
        return {
          id: uuid(),
          brandName: val,
        };
      });
      console.log(modals);

      setCarModels(modals);

      // const data = res.data.subcategory[0];
      // setBrandName(data?.main_title);
      // setMakeName(data?.category_title);
      // setInputFields(data?.carmodel);
      // setImage(data?.logo);
    } catch (error) {}
  };

  console.log(carModels);

  const changeHandler = async (e, type) => {
    if (type === "text") {
      setData({
        ...data,
        [e.target.name]: e.target.value,
      });
    } else {
      let file = e.target.files[0];
      const payload = {
        media: file,
        path: "carlogos",
      };
      const formData = new FormData();

      for (const key in payload) {
        formData.append(key, payload[key]);
      }
      // try {
      const res = await axios.post(
        `https://betazone1.promaticstechnologies.com:5011/admin/uploadAdminMedia`,
        formData
      );
      console.log(res);
      let url = res.data.media;
      setData({
        ...data,
        [e.target.name]: url,
      });
    }
  };

  useEffect(() => {
    // Your logic here using the query parameters
    const queryParams = new URLSearchParams(location.search);
    const queryId = queryParams.get("id");
    const modalParam = queryParams.get("param1");
    setModalName(modalParam);
    if (queryId !== null && modalParam !== 'add') {
      setId(queryId);
      getData(queryId);
      // console.log("Your Query Param:", queryId);
    }
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <form className="edit-car">
        <Box sx={style} className="m-2">
          <Row>
            <Col md={12}>
              {/* <Typography>
                <div className="image-wrapper">
                  <div className="image-upload">
                    {uploadedImage ? (
                      <img
                        src={uploadedImage}
                        alt="Uploaded "
                        style={{ width: "120px", height: "120px", maxWidth: "100%" }}

                      />
                    ) : (
                      <img
                        src={image ? process.env.REACT_APP_IMAGE_URL + "carlogos/" + image : placeholderUser} // Placeholder image URL
                        alt="logo"
                        style={{ width: "120px", height: "120px", maxWidth: "100%" }}

                      />
                    )}
                  </div>
                  <label className="input-file" htmlFor="file-input">
                    <EditRoundedIcon />
                    <input type="file" onChange={handleImageUpload} accept="image/*" />
                  </label>
                  <div className="upload-image mt-2 pr-2" style={{ paddingRight: "10px" }}>
                    <h3>Brand Image</h3>
                  </div>
                </div>
              </Typography> */}
              <Typography
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                p={3}
              >
                <div className="image-wrapper" style={{ position: "relative" }}>
                  <div className="image-upload">
                    {modalName !== "view" && modalName !== "" && (
                      <>
                        <label htmlFor="file-input">
                          <EditRoundedIcon className="iconButton" />
                        </label>
                        <input
                          id="file-input"
                          type="file"
                          accept="image/*"
                          name="logo"
                          onChange={(e) => changeHandler(e, "img")}
                        />
                      </>
                    )}

                    <img
                      src={
                        data?.logo
                          ? `${process.env.REACT_APP_IMAGE_URL}carlogos/${data?.logo}`
                          : placeholderUser
                      }
                      alt="Selected"
                    />
                  </div>
                </div>
                <SoftTypography className="text-center" variant="h5">
                  Brand Name
                </SoftTypography>
              </Typography>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Typography sx={{ mt: 2 }}>
                <SoftBox mb={2} display="flex" flexDirection="column">
                  <SoftTypography
                    component="label"
                    fontSize="13px"
                    mb={1}
                    variant="caption"
                    fontWeight="bold"
                  >
                    Category Type
                  </SoftTypography>
                  <SoftInput
                    type="text"
                    className="TextField"
                    name="category_title"
                    onChange={(e) => changeHandler(e, "text")}
                    placeholder="Enter Category"
                    value={data?.category_title}
                    disabled={modalName === "view" || modalName === ""}
                    required
                  />
                </SoftBox>
              </Typography>
            </Col>
            <Col md={6}>
              <Typography sx={{ mt: 2 }}>
                <SoftBox mb={2} display="flex" flexDirection="column">
                  <SoftTypography
                    component="label"
                    fontSize="13px"
                    mb={1}
                    variant="caption"
                    fontWeight="bold"
                  >
                    Brand Name
                  </SoftTypography>
                  <SoftInput
                    type="text"
                    className="TextField"
                    name="Brand_name"
                    placeholder="Enter Brand Name"
                    onChange={(e) => changeHandler(e, "text")}
                    value={data?.Brand_name}
                    disabled={modalName === "view" || modalName === ""}
                    required
                  />
                </SoftBox>
              </Typography>
            </Col>
            <Col md={12}>
              <SoftBox
                className="model-name"
                display="flex"
                justifyContent="space-between"
              >
                <SoftTypography
                  component="label"
                  fontSize="13px"
                  mb={1}
                  variant="caption"
                  fontWeight="bold"
                >
                  Create Car Models
                </SoftTypography>
                {modalName !== "view" && (
                  <SoftTypography
                    className="add-icon"
                    onClick={addCardModalFields}
                    style={{ cursor: "pointer" }}
                  >
                    <AddIcon />
                  </SoftTypography>
                )}
              </SoftBox>
            </Col>
            {carModels.map((model, index) => (
              <Col md={4} key={model.id}>
                <Typography sx={{ mt: 2 }}>
                  <SoftBox mb={2} display="flex" flexDirection="column">
                    <div className="d-flex">
                      <SoftInput
                        type="text"
                        className="TextField"
                        name="question"
                        placeholder="enter brand name"
                        value={model?.brandName}
                        onChange={(e) =>
                          handleBrandNameChange(model.id, e.target.value)
                        }
                        disabled={modalName === "view" || modalName === ""}
                        required
                      />
                      {modalName !== "view" && (
                        <IconButton
                          className="delete-models"
                          aria-label="delete"
                          // Define a function to handle the delete action
                          onClick={() => deleteHandler(index)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </div>
                  </SoftBox>
                </Typography>
              </Col>
            ))}
            {/* <Col md={4}>
              <Typography sx={{ mt: 2 }}>
                <SoftBox mb={2} display="flex" flexDirection="column">
              
                  <div className="d-flex">
                    <SoftInput
                      type="text"
                      className="TextField"
                      name="question"
                      placeholder="enter brand name"
                      value={brandName}
                      disabled={modalName === "view"}
                      required
                    />
                    <IconButton
                      className="delete-models"
                      aria-label="delete" // Define a function to handle the delete action
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </SoftBox>
              </Typography>
            </Col>
            <Col md={4}>
              <Typography sx={{ mt: 2 }}>
                <SoftBox mb={2} display="flex" flexDirection="column">
                  
                  <div className="d-flex">
                    <SoftInput
                      type="text"
                      className="TextField"
                      name="question"
                      placeholder="enter brand name"
                      value={brandName}
                      disabled={modalName === "view"}
                      required
                    />
                    <IconButton
                      className="delete-models"
                      aria-label="delete" // Define a function to handle the delete action
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </SoftBox>
              </Typography>
            </Col>
            <Col md={4}>
              <Typography sx={{ mt: 2 }}>
                <SoftBox mb={2} display="flex" flexDirection="column">
                  
                  <div className="d-flex">
                    <SoftInput
                      type="text"
                      className="TextField"
                      name="question"
                      placeholder="enter brand name"
                      value={brandName}
                      disabled={modalName === "view"}
                      required
                    />
                    <IconButton
                      className="delete-models"
                      aria-label="delete" // Define a function to handle the delete action
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </SoftBox>
              </Typography>
            </Col> */}
          </Row>
        </Box>
      </form>
      <SoftBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        p={3}
        style={{ margin: "auto", width: "fit-content" }}
      >
        <Grid container spacing={2} pt={3}>
          <Grid
            item
            xs={12}
            display={"flex"}
            justifyContent={"center"}
            alignContent={"start"}
            className="gap-3"
          >
            <Button
              onClick={() => navigate(-1)}
              variant="contained"
              color="primary"
              style={{ color: "white" }}
              fontWeight="medium"
            >
              Back
            </Button>
            {modalName !== "view" && modalName !== "" && <Button
              variant="contained"
              color="primary"
              style={{ color: "white" }}
              onClick={modalName === 'edit' ? submit: submit1}
            >
              Submit
            </Button>}
          </Grid>
        </Grid>
      </SoftBox>
    </DashboardLayout>
  );
};
