import { Grid } from "@mui/material";
import React from "react";
import ContactInfo from "./components/ContactInfo";
import PaymentStatus from "./components/PaymentStatus";
import UnpaidInvoices from "./components/UnpaidInvoices";
import SavedCards from "./components/SavedCards";

const OverviewTab = () => {
  return (
    <>
      <section className="overview grid-wrapper">
        <div className="left">
          <ContactInfo />
        </div>
        <div className="right flex flex-col gap-4">
          <PaymentStatus />
          <UnpaidInvoices />
          <SavedCards />
        </div>
      </section>
    </>
  );
};

export default OverviewTab;
