import Dashboard from "layouts/dashboard";
import StoreManagement from "layouts/garage-managment/store-management";
import CarManagement from "layouts/car-management";
import SubscriptionManagement from "layouts/subscription-management";
import RequestManagement from "layouts/request-management";
// import RTL from "layouts/rtl";
import Profile from "layouts/profile";

// Soft UI Dashboard React icons
import Shop from "examples/Icons/Shop";
import CustomerSupport from "examples/Icons/CustomerSupport";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import RequestPageIcon from "@mui/icons-material/RequestPage";
import PeopleIcon from "@mui/icons-material/People";
import Billing from "layouts/billing";
import GarageDetails from "layouts/billing/components/GarageDetails";

import HomePage from "layouts/general-management/Home-Page";
import AboutUs from "layouts/general-management/About-us";
import TermsCondition from "layouts/general-management/termsConditions";
import PaymentsIcon from "@mui/icons-material/Payments";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import HomeIcon from "@mui/icons-material/Home";
import PrivacyPolicy from "layouts/general-management/PrivacyPolicy";
import LockIcon from "@mui/icons-material/Lock";
import QuizIcon from "@mui/icons-material/Quiz";
import StoreMallDirectoryIcon from "@mui/icons-material/StoreMallDirectory";
import FAQ from "layouts/general-management/FAQ";
import UserRolesManagement from "layouts/user-roles-management";
import ContactUs from "layouts/general-management/Contact-Us";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import Subscription from "layouts/general-management/subscription";
import ReportManagement from "layouts/reportManagement";
import ReportIcon from "@mui/icons-material/Report";
import Notification from "layouts/notification";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import VehicleManagement from "layouts/vehicleMangement";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import InsuranceManagement from "layouts/insurance-management";
import { EditCar } from "layouts/car-management/EditCar";
import VatManagement from "layouts/Vat Management";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    icon: <Shop size="12px" />,
    component: <Dashboard />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Garage Management",
    key: "Store-Management",
    route: "/GarageManagement",
    icon: <StoreMallDirectoryIcon size="12px" />,
    component: <StoreManagement />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Car Management",
    key: "Car-Management",
    route: "/CarManagement",
    icon: <StoreMallDirectoryIcon size="12px" />,
    component: <CarManagement />,
    noCollapse: true,
  },
  {
    // type: "collapse",
    name: "Car Management",
    key: "Car-Management",
    route: "/editcar",
    icon: <StoreMallDirectoryIcon size="12px" />,
    component: <EditCar />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Insurance Management",
    key: "insurance-management",
    route: "/insuranceManagement",
    icon: <StoreMallDirectoryIcon size="12px" />,
    component: <InsuranceManagement />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Vat Management",
    key: "vat-management",
    route: "/vatManagement",
    icon: <StoreMallDirectoryIcon size="12px" />,
    component: <VatManagement />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Subscription",
    key: "Subscription",
    route: "/Subscription",
    icon: <SubscriptionsIcon size="12px" />,
    component: <Subscription />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Request Management",
    key: "request-management",
    route: "/request-management",
    icon: <RequestPageIcon size="12px" />,
    component: <RequestManagement />,
    noCollapse: true,
  },
  // {
  //   type: "collapse",
  //   name: "Garage Details",
  //   key: "garage-details",
  //   route: "/garage-details/id",
  //   icon: <PaymentsIcon size="12px" />,
  //   component: <Billing />,
  //   noCollapse: true,
  // },
  // {
  //   type: "collapse",
  //   name: "Garage Details",
  //   key: "garage-details",
  //   route: "/garage-details/id",
  //   icon: <PaymentsIcon size="12px" />,
  //   component: <GarageDetails/>,
  //   noCollapse: true,
  // },

  // {
  //   type: "collapse",
  //   name: "General Management",
  //   key: "generalManagement",
  //   route: "/General-Management",
  //   icon: <PeopleIcon size="12px" />,
  //   component: <GeneralManagement />,
  //   noCollapse: true,
  //   children: [
  //     {
  //       name: "HomePage",
  //       key: "HomePage",
  //       route: "/dashboards/HomePage",
  //       component: <HomePage />,
  //     },
  //     {
  //       name: "AboutUs",
  //       key: "AboutUs",
  //       route: "/dashboards/AboutUs",
  //       component: <AboutUs />,
  //     },
  //   ],
  // },

  // },
  {
    type: "collapse",
    name: "User roles Management",
    key: "User-Roles-Management",
    route: "/User-Roles-Management",
    icon: <AdminPanelSettingsIcon size="12px" />,
    component: <UserRolesManagement />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Contact Us",
    key: "Contact-Us",
    route: "/Contact-Us",
    icon: <ContactPhoneIcon size="12px" />,
    component: <ContactUs />,
    noCollapse: true,
  },

  {
    type: "collapse",
    name: "Notification",
    key: "Notification",
    route: "/Notification",
    icon: <NotificationsActiveIcon size="12px" />,
    component: <Notification />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Report Management",
    key: "report-management",
    route: "/Report-Management",
    icon: <ReportIcon size="12px" />,
    component: <ReportManagement />,
    noCollapse: true,
  },
  // {
  //   type: "collapse",
  //   name: "Vehicle Management",
  //   key: "Vehicle-Management",
  //   route: "/Vehicle-Management",
  //   icon: <DriveEtaIcon size="12px" />,
  //   component: <VehicleManagement />,
  //   noCollapse: true,
  // },

  // {
  //   type: "collapse",
  //   name: "Billing",
  //   key: "billing",
  //   route: "/billing",
  //   icon: <CreditCard size="12px" />,
  //   component: <Billing />,
  //   noCollapse: true,
  // },
  // {
  //   type: "collapse",
  //   name: "Virtual Reality",
  //   key: "virtual-reality",
  //   route: "/virtual-reality",
  //   icon: <Cube size="12px" />,
  //   component: <VirtualReality />,
  //   noCollapse: true,
  // },
  // {
  //   type: "collapse",
  //   name: "RTL",
  //   key: "rtl",
  //   route: "/rtl",
  //   icon: <Settings size="12px" />,
  //   component: <RTL />,
  //   noCollapse: true,
  // },
  // { type: "title", title: "Account Pages", key: "account-pages" },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    route: "/profile",
    icon: <CustomerSupport size="12px" />,
    component: <Profile />,
    noCollapse: true,
  },

  {
    type: "collapse",
    name: "Terms & Conditions",
    key: "Terms-Conditions",
    route: "/Terms-Conditions",
    icon: <AdminPanelSettingsIcon size="12px" />,
    component: <TermsCondition />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Privacy Policy",
    key: "Privacy-Policy",
    route: "/Privacy-Policy",
    icon: <LockIcon size="12px" />,
    component: <PrivacyPolicy />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "FAQ",
    key: "FAQ",
    route: "/FAQ",
    icon: <QuizIcon size="12px" />,
    component: <FAQ />,
    noCollapse: true,
  },
  // {
  //   type: "collapse",
  //   name: "Home Page",
  //   key: "Home-Page",
  //   route: "/Home-Page",
  //   icon: <HomeIcon size="12px" />,
  //   component: <HomePage />,
  //   noCollapse: true,
  // },

  {
    type: "collapse",
    name: "About Us",
    key: "About-Us",
    route: "/About-Us",
    icon: <PeopleIcon size="12px" />,
    component: <AboutUs />,
    noCollapse: true,
  },

  // {
  //   type: "collapse",
  //   name: "Sign In",
  //   key: "sign-in",
  //   route: "/authentication/sign-in",
  //   icon: <Document size="12px" />,
  //   component: <SignIn />,
  //   noCollapse: true,
  // },
];

export default routes;
