/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";


// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";

// Soft UI Dashboard React base styles
import typography from "assets/theme/base/typography";

// Dashboard layout components
import BuildByDevelopers from "layouts/dashboard/components/BuildByDevelopers";
import WorkWithTheRockets from "layouts/dashboard/components/WorkWithTheRockets";
import Users from "layouts/dashboard/components/Users";
import OrderOverview from "layouts/dashboard/components/OrderOverview";
import VerticalBarChart from "../../examples/Charts/BarCharts/HorizontalBarChart";
import HorizontalBarChart from "../../examples/Charts/BarCharts/HorizontalBarChart";
import horizontalBarChartData from "../../layouts/dashboard/data/horizontalBarChartData";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import gradientLineChartData from "layouts/dashboard/data/gradientLineChartData";
import TodoCard from "layouts/virtual-reality/components/TodoCard";
import Totalinvoices from "./components/Totalinvoices/index";
import Queries from "./components/Contact-us/Queries";
import { useEffect, useMemo, useState } from "react";
import { universalFun } from "util/httpService";
import { getReportBarChart } from "./data/reportsBarChartData";
import { getYearArray } from "util/timeConverter";
import { Button } from "react-bootstrap";
function Dashboard() {
  const { size } = typography;
  // const { chart  } = reportsBarChartData;


  const [ usersData , setUsersData] = useState({})
  const [ totalSales , setTotalSales] = useState({})
  console.log("🚀 ~ Dashboard ~ totalSales:", totalSales)
  const [ InvoiceCounts , setInvoiceCount] = useState({})

  //year filtering  **************************
  const yearArray = getYearArray();

  const [lineChartYear, setLineChartYear] = useState(yearArray?.[0]);
  const [barChartYear, setBarChartYear] = useState(yearArray?.[0]);

  const chart = useMemo(()=>{

 return getReportBarChart(InvoiceCounts)
  },[InvoiceCounts])

  const lineChart = useMemo(()=>{
   console.log("runnnn")
    return gradientLineChartData(totalSales)
     },[totalSales])
  
  //*****************geting users data ********************
  const getUsersData = async ()=>{
      try {
        const resp = await universalFun("get", "users-data")
        setUsersData(resp?.data?.data)
      } catch (error) {
        console.log(error)
      }
  }

  // *************************getting total sales *************
  const getTotalSales =async ()=>{
    const params ={
      year : lineChartYear,
    }
   try {
    const resp = await universalFun("get", "total-sales",null , params)
    setTotalSales(resp?.data?.data)
   } catch (error) {
    console.log(error)
   }
  }

    // *************************getting Invoice count *************
  const getInvoiceCount =async ()=>{
    const params ={
      year : barChartYear,
    }
   try {
    const resp = await universalFun("get", "number-of-invoices",null , params)
    setInvoiceCount(resp?.data?.data)
   } catch (error) {
    console.log(error)
   }
  }
 
  useEffect(()=>{
    getUsersData()
  },[])

  useEffect(()=>{
    
    getInvoiceCount()

  },[barChartYear])

  useEffect(()=>{
    getTotalSales()

  },[lineChartYear]);


  // creating iteam array for report cart
 const  items = useMemo(()=>{
     const array =   [
      {
        icon: { color: "primary", component: "library_books" },
        label: "users",
        progress: { content: usersData?.total_users , percentage: 60 },
      },
      {
        icon: { color: "info", component: "touch_app" },
        label: "clicks",
        progress: { content: usersData?.total_clicks , percentage: 90 },
      },
      {
        icon: { color: "warning", component: "payment" },
        label: "sales",
        progress: { content: usersData?.sales  , percentage: 30 },
        
      },
      {
        icon: { color: "error", component: "extension" },
        label: "New Joined",
        progress: { content: usersData?.activeUsers, percentage: 50 },
      }
    ]
    return array;
 },[usersData])
  




  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3} >

        {/* <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "today's money" }}
                // count="$53,000"
                count=""
                // percentage={{ color: "success", text: "+55%" }}
                percentage={{ color: "success", text: "" }}
                icon={{ color: "info", component: "paid" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "today's users" }}
                // count="2,300"
                count=""
                // percentage={{ color: "success", text: "+3%" }}
                percentage={{ color: "success", text: "" }}
                icon={{ color: "info", component: "public" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "new clients" }}
                // count="+3,462"
                count=""
                percentage={{ color: "error", text: "" }}
                // percentage={{ color: "error", text: "-2%" }}
                
                icon={{ color: "info", component: "emoji_events" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "sales" }}
                // count="$103,430"
                count=""
                // percentage={{ color: "success", text: "+5%" }}
                percentage={{ color: "success", text: "" }}
                icon={{
                  color: "info",
                  component: "shopping_cart",
                }}
              />
            </Grid>
          </Grid>
        </SoftBox> */}
        <SoftBox mb={3}>
          {/* <Grid container spacing={3}>
            <Grid item xs={12} lg={7}>
              <BuildByDevelopers />
            </Grid>
            <Grid item xs={12} lg={5}>
              <WorkWithTheRockets />
            </Grid>
          </Grid> */}
        </SoftBox>
        <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={5}>
              <ReportsBarChart
                title="Users"
                description={
                  <>
                    (<strong>+{usersData?.activeUsersInPercentage}%</strong>) than last month
                  </>
                }
                chart={chart}
                items={items}
                yearArray ={yearArray}
                year={barChartYear}
                setYear ={setBarChartYear}
              />
            </Grid>



            <Grid item xs={12} lg={7}>
              <GradientLineChart
                title="Sales Overview"
                description={
                  <SoftBox display="flex" alignItems="center">
                    {/* <SoftBox fontSize={size.lg} color="success" mb={0.3} mr={0.5} lineHeight={0}>
                      <Icon className="font-bold">arrow_upward</Icon>
                    </SoftBox>
                    <SoftTypography variant="button" color="text" fontWeight="medium">
                      4% more{" "}
                      <SoftTypography variant="button" color="text" fontWeight="regular">
                        in 2021
                      </SoftTypography>
                    </SoftTypography> */}
                  </SoftBox>
                }
                height="20.25rem"
                chart={lineChart}
                yearArray ={yearArray}
                year={lineChartYear}
                setYear ={setLineChartYear}
              />
            </Grid> 



            {/* <Grid item xs={12} lg={7}>
              <HorizontalBarChart
                title="Average Invoices"
                description={
                  <SoftBox display="flex" alignItems="center">
                    <SoftBox fontSize={size.lg} color="success" mb={0.3} mr={0.5} lineHeight={0}>
                      <Icon className="font-bold">arrow_upward</Icon>
                    </SoftBox>
                    <SoftTypography variant="button" color="text" fontWeight="medium">
                      4% more{" "}
                      <SoftTypography variant="button" color="text" fontWeight="regular">
                        in 2021
                      </SoftTypography>
                    </SoftTypography>
                  </SoftBox>
                }
                height="20.25rem"
                chart={horizontalBarChartData}
              />
            </Grid> */}
            
          </Grid>
        </SoftBox>
        {/* average chart start */}
        <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={7}>
              {/* <Users /> */}
            </Grid>
            {/* <Grid item xs={12} lg={5} className="h-100">
              <Totalinvoices
                title="active users"
                description={
                  <SoftBox display="flex" alignItems="center">
                    <SoftBox fontSize={size.lg} color="success" mb={0.3} mr={0.5} lineHeight={0}>
                      <Icon className="font-bold">arrow_upward</Icon>
                    </SoftBox>
                    <SoftTypography variant="button" color="text" fontWeight="medium">
                      4% more{" "}
                      <SoftTypography variant="button" color="text" fontWeight="regular">
                        in 2021
                      </SoftTypography>
                    </SoftTypography>
                  </SoftBox>
                }
                chart={chart}
                items={items}
              />
            </Grid> */}
          </Grid>
        </SoftBox>
        {/* Average chart end */}
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            {/* <Users /> */}
            <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftTypography variant="h6">Latest Queries</SoftTypography>
              
            </SoftBox>
            <Queries />
            </Card>
          </Grid>
          {/* <Grid item xs={12} md={6} lg={4}>
            <OrderOverview />
          </Grid> */}
        </Grid>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
