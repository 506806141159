import React from "react";

const UnpaidInvoices = () => {
  return (
    <div className="unpaid-invoices shadow">
      <h3>Unpaid Invoices</h3>
      <div>
        <h4>Invoice W2205-1008</h4>
        <p>Due in 11 days</p>
        <button>Send Reminder</button>
      </div>
    </div>
  );
};

export default UnpaidInvoices;
