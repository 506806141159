import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Button from "@mui/material/Button";
import { useEffect, useState } from "react";
import logo from "assets/images/logo.jpg";
import fallBackImage from "assets/images/FallbackImage.jpg";

import Grid from "@mui/material/Grid";

import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { addStoreService, checkMailExists, universalFun } from "../../../util/httpService";
import { uploadMedia } from "../../../util/httpService";
import { useNavigate } from "react-router-dom";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { toast } from "react-toastify";

function AddStore() {
  const navigate = useNavigate();

  const emailRegx = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const passRegx = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/;
  const SiretRegx = /^[A-Z]{5}\d{9}$/;

  const [addStoreData, setAddStoreData] = useState({
    company_logo: "",
    first_name: "",
    last_name: "",
    company_name: "",
    email: "",
    phone_number: "",
    siret_number: "",
  });
  console.log("🚀 ~ AddStore ~ addStoreData:", addStoreData)

  const [addStoreValidation, setAddStoreValidation] = useState({
    first_name: true,
    last_name: true,
    company_name: true,
    email: true,
    phone_number: true,
    siret_number: true,
  });

  const [isFormValid , setIsFormValid] = useState(true)

  useEffect(()=>{
    const isValid = Object.values(addStoreValidation).every((value) => value);
    setIsFormValid(isValid)
  },[addStoreValidation])

  const handleChange = (event) => {
    setAddStoreData((addStoreData) => ({
      ...addStoreData,
      [event.target.name]: event.target.value,
    }));
    const value = event.target.value;
    const name = event.target.name
    console.log("check for data", addStoreData);
    // ValidationCheck(event.target.name ,);
    ValidateFields(event.target.name ,value)
    if(name ==="email"){
      setisEmailAvail({ isValid : true, message: "" });

    }
  };

  function ValidationCheck(name ) {
    switch (name) {
      case "first_name":
        setAddStoreValidation((addStoreValidation) => ({
          ...addStoreValidation,
          first_name: addStoreData[name].length === 0 ? false : true,
        }));
        break;
      case "last_name":
        setAddStoreValidation((addStoreValidation) => ({
          ...addStoreValidation,
          last_name: addStoreData[name].length === 0 ? false : true,
        }));
        break;
      case "email":
        setAddStoreValidation((addStoreValidation) => ({
          ...addStoreValidation,
          email:
            addStoreData[name].length === 0 ||
            !emailRegx.test(addStoreData.email)
              ? false
              : true,
        }));
        break;
      case "siret_number":
        setAddStoreValidation((addStoreValidation) => ({
          ...addStoreValidation,
          siret_number:
            // addStoreData[name].length === 0 ||
            // !SiretRegx.test(addStoreData.siret_number)
            //   ? false
            //   : true,
            addStoreData[name].length < 14 ? false : true,
        }));
        break;
      case "company_name":
        setAddStoreValidation((addStoreValidation) => ({
          ...addStoreValidation,
          company_name: addStoreData[name].length === 0 ? false : true,
        }));
        break;
      case "phone_number":
        setAddStoreValidation((addStoreValidation) => ({
          ...addStoreValidation,
          phone_number: addStoreData[name].length === 0 ? false : true,
        }));
        break;
      default:
    }
  }

  //checking field on change
  function ValidateFields(name ,value) {
    switch (name) {
      case "first_name":
        setAddStoreValidation((addStoreValidation) => ({
          ...addStoreValidation,
          first_name: value.length === 0 ? false : true,
        }));
        break;
      case "last_name":
        setAddStoreValidation((addStoreValidation) => ({
          ...addStoreValidation,
          last_name: value.length === 0 ? false : true,
        }));
        break;
      case "email":
        setAddStoreValidation((addStoreValidation) => ({
          ...addStoreValidation,
          email:
          value.length === 0 ||
            !emailRegx.test(value)
              ? false
              : true,
        }));
        break;
      case "siret_number":
        setAddStoreValidation((addStoreValidation) => ({
          ...addStoreValidation,
          siret_number:
            // addStoreData[name].length === 0 ||
            // !SiretRegx.test(addStoreData.siret_number)
            //   ? false
            //   : true,
            value.length < 14 ? false : true,
        }));
        break;
      case "company_name":
        setAddStoreValidation((addStoreValidation) => ({
          ...addStoreValidation,
          company_name: value.length === 0 ? false : true,
        }));
        break;
      case "phone_number":
        setAddStoreValidation((addStoreValidation) => ({
          ...addStoreValidation,
          phone_number: value.length === 0 ? false : true,
        }));
        break;
      default:
    }
  }

  

  function checkFuncForValues(obj){
    const keys = Object.keys(obj);
        if(keys.find((ele) => addStoreData[ele].length === 0) === undefined){
          return true;
      }
      else{
        for(let i = 0; i <= keys.length; i++){
          ValidationCheck(keys[i]);
        }
       return false;
      }
   }
  function checkFuncForValidation(obj) {
    const keys = Object.keys(obj);
    if (keys.find((ele) => obj[ele] === false) === undefined) {
      return true;
    } else {
      for (let i = 0; i <= keys.length; i++) {
        ValidationCheck(keys[i]);
      }
      return false;
    }
  }

  const ImageUploaded = async (event) => {
    let file = event.target.files[0];
    const type = file?.type;
    console.log("file name", file);
    if (type?.includes("image")) {
      try {
        let formData = new FormData();
        formData.append("path", "profile_img");
        formData.append("media", file);
        let resp = await uploadMedia(formData);
        if (resp?.status === 200) {
          console.log(" login response data=> ", resp);
          setAddStoreData({ ...addStoreData, company_logo: resp?.data?.media });
        }
      } catch (error) {}
    } else {
      toast.error("Only Images are supported");
    }
  };
  console.log("addStoreValidation",addStoreValidation)

  const submit = async () => {
    if (checkFuncForValues(addStoreValidation) === true) {
      const data = addStoreData;
      try {
        const resp = await addStoreService(data);
        console.log(" login response data=> ", resp);
        if (resp?.status === 201) {
          toast.success(
            "Credentials have been sent successfully to user"
          );
        }
        navigate("/GarageManagement");
      } catch (err) {
        console.log(err);
      }
    } else {
      console.log("validation failed");
    }
    // else{
    //   setAddStoreValidation({'emailValidation': (addStoreData.email.length === 0 || !emailRegx.test(addStoreData.email)) ? false : true,'passwordValidation': (addStoreData.password.length === 0 || !passRegx.test(addStoreData.password)) ? false : true})
    // }
  };

  const styles = {
    icon: {
      width: "1.3rem",
    },
    labelforinput: {
      position: "absolute",
      right: "0px",
      bottom: "12px",
      background: "lightblue",
      width: "30px",
      height: "30px",
      borderRadius: "50%",
      lineHeight: "-1",
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    previewImage: {
      width: "120px",
      height: "120px",
      borderRadius: "50%",
    },

    selectContainer: {
      position: "relative",
    },

    chevIocn: {
      position: "absolute",
      zIndex: "999",
      right: "10px",
      top: "10px",
    },
  };

     //email existance test 
     const [isEmailAvail , setisEmailAvail] = useState({ isValid : true, message: "" })
     console.log("🚀 ~ AddStore ~ isEmailAvail:", isEmailAvail)

     const handleBlur = async(value)=>{
      // if email is valid, start exitance testing
      if(addStoreValidation?.email){
        const body = {
          email : value
        }
  
        try{
          const resp = await checkMailExists(body)
          console.log(resp,"--------")
          if(resp?.data?.status === false){

            setisEmailAvail({ isValid : resp?.data?.status, message: "Email already exist" });
          }else if(resp?.data?.status === true){
            setisEmailAvail({ isValid : resp?.data?.status, message: "" });
          }
        }catch(error){
         console.log(error)
        }
      }
  
    }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox display="flex" flexDirection="column" p={3}>
              <SoftTypography variant="h4">Add Garage</SoftTypography>
            </SoftBox>

            <SoftBox
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              p={3}
            >
              <div className="image-wrapper" style={{ position: "relative" }}>
                <div className="image-upload">
                  <label htmlFor="file-input" style={styles.labelforinput}>
                    <EditRoundedIcon className="iconButton" />
                  </label>
                  <input id="file-input" type="file" 
                  accept="image/*"
                  onChange={ImageUploaded}  />
                  {addStoreData && (
                    <img
                      src={
                        addStoreData.company_logo === ""
                          ? fallBackImage
                          : process.env.REACT_APP_IMAGE_URL +
                            "profile_img/" +
                            addStoreData.company_logo
                      }
                      alt="Selected"
                      style={styles.previewImage}
                    />
                  )}
                </div>
              </div>
              <SoftTypography className="text-center" variant="h5">
                Upload or Add Logo
              </SoftTypography>
            </SoftBox>

            <SoftBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              p={3}
            >
              <Grid container spacing={2} mb={3}>
                <Grid item xs={12} sm={6}>
                  <SoftTypography variant="h4">
                    Garage Information
                  </SoftTypography>
                </Grid>
                <Grid item xs={12} sm={6}></Grid>
                <Grid item xs={12} sm={6}>
                  <SoftBox mb={1} ml={0.5}>
                    <SoftTypography
                      component="label"
                      fontSize="16px"
                      variant="caption"
                      fontWeight="bold"
                    >
                      First Name
                    </SoftTypography>
                  </SoftBox>
                  <SoftInput
                    type="text"
                    placeholder="First Name"
                    name="first_name"
                    value={addStoreData.first_name}
                    onChange={handleChange}
                    onBlur={handleChange}
                  />
                  {addStoreValidation.first_name === false ? (
                    <div className="validation_message">
                      First Name is required
                    </div>
                  ) : (
                    <></>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SoftBox mb={1} ml={0.5}>
                    <SoftTypography
                      component="label"
                      fontSize="16px"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Last Name
                    </SoftTypography>
                  </SoftBox>
                  <SoftInput
                    type="text"
                    placeholder="Last Name"
                    name="last_name"
                    value={addStoreData.last_name}
                    onChange={handleChange}
                    onBlur={handleChange}
                  />
                  {addStoreValidation.last_name === false ? (
                    <div className="validation_message">
                      Last Name is required
                    </div>
                  ) : (
                    <></>
                  )}
                </Grid>

                <Grid item xs={12} sm={6}>
                  <SoftBox mb={1} ml={0.5}>
                    <SoftTypography
                      component="label"
                      fontSize="16px"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Business Email address
                    </SoftTypography>
                  </SoftBox>
                  <SoftInput
                    type="text"
                    name="email"
                    placeholder="email@address.com"
                    value={addStoreData.email}
                    onChange={handleChange}
                    onBlur={(e)=> {handleChange(e); handleBlur(e.target.value)} }
                    
                  />
                  {addStoreValidation.email === false ? (
                    <div className="validation_message">
                      Business Email is not valid
                    </div>
                  ) : 
                    
                    isEmailAvail?.isValid === false ? (
                        <div className="validation_message">
                          {isEmailAvail?.message}
                        </div>
                      ) : (
                        <></>
                    
                   
                  )}
                </Grid>

                <Grid item xs={12} sm={6}>
                  <SoftBox mb={1} ml={0.5}>
                    <SoftTypography
                      component="label"
                      fontSize="16px"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Phone Number
                    </SoftTypography>
                  </SoftBox>
                  <SoftInput
                    type="number"
                    placeholder="phone number"
                    name="phone_number"
                    value={addStoreData.phone_number}
                    onChange={handleChange}
                    onBlur={handleChange}
                  />
                  {addStoreValidation.phone_number === false ? (
                    <div className="validation_message">
                      Phone Number is required
                    </div>
                  ) : (
                    <></>
                  )}
                </Grid>

                <Grid item xs={12} sm={6}>
                  <SoftBox mb={1} ml={0.5}>
                    <SoftTypography
                      component="label"
                      fontSize="16px"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Company Name
                    </SoftTypography>
                  </SoftBox>
                  <SoftInput
                    type="text"
                    name="company_name"
                    placeholder="company name"
                    value={addStoreData.company_name}
                    onChange={handleChange}
                    onBlur={handleChange}
                  />
                  {addStoreValidation.company_name === false ? (
                    <div className="validation_message">
                      Company name is required
                    </div>
                  ) : (
                    <></>
                  )}
                </Grid>
                {/* <Grid item xs={12} sm={6}>

                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography component='label' fontSize="16px" variant='caption' fontWeight='bold'>
                        Company Logo
                      </SoftTypography>
                    </SoftBox>
                    <SoftInput type='file' placeholder='choosoe file' />

                  </Grid> */}

                <Grid item xs={12} sm={6} md={6}>
                  <SoftBox mb={1} ml={0.5}>
                    <SoftTypography
                      component="label"
                      fontSize="16px"
                      variant="caption"
                      fontWeight="bold"
                      pattern="^\d{14}$"
                    >
                      SIRET Number
                    </SoftTypography>
                  </SoftBox>
                  <SoftInput
                    type="number"
                    name="siret_number"
                    placeholder="SIRET Number"
                    value={addStoreData.siret_number}
                    onChange={handleChange}
                    onBlur={handleChange}
                  />
                  {addStoreValidation.siret_number === false ? (
                    <div className="validation_message">
                      Enter a Valid Siret Number
                    </div>
                  ) : (
                    <></>
                  )}
                </Grid>

                {/* 
                  <Grid item xs={12} sm={6}>

                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography component='label' fontSize="16px" variant='caption' fontWeight='bold'>
                        User Type
                      </SoftTypography>
                    </SoftBox>

                    <FormControl style={styles.selectContainer} fullWidth>
                      <ArrowDropDownIcon style={styles.chevIocn} />
                      <Select
                        value={selectedOptionUser}
                        onChange={handleChangeUserType}
                      >
                        <MenuItem value="Select an option"></MenuItem>
                        <MenuItem value="DSPsubcontractor">DSP subcontractor</MenuItem>
                        <MenuItem selected value="SuperUser">Super User</MenuItem>
                        <MenuItem value="Technician">Technician </MenuItem>

                      </Select>
                    </FormControl>

                  </Grid>

                  <Grid item xs={12} sm={6}>

                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography component='label' fontSize="16px" variant='caption' fontWeight='bold'>
                        Country
                      </SoftTypography>
                    </SoftBox>
                    <FormControl style={styles.selectContainer} fullWidth>
                      <ArrowDropDownIcon style={styles.chevIocn} />
                      <Select value={selectedOptionCountry} onChange={handleChangeCountry} >
                        <MenuItem value="Select an option"></MenuItem>
                        <MenuItem selected value="option1">Option 1</MenuItem>
                        <MenuItem value="option2">Option 2</MenuItem>
                        <MenuItem value="option3">Option 3</MenuItem>
                      </Select>
                    </FormControl>

                  </Grid>

                  <Grid item xs={12} sm={6}>

                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography component='label' fontSize="16px" variant='caption' fontWeight='bold'>
                        Province/State
                      </SoftTypography>
                    </SoftBox>

                    <FormControl style={styles.selectContainer} fullWidth>
                      <ArrowDropDownIcon style={styles.chevIocn} />

                      <Select value={selectedOptionState} onChange={handleChangestate} >
                        <MenuItem value="Select an option">Select an option</MenuItem>
                        <MenuItem selected value="option1">Option 1</MenuItem>
                        <MenuItem value="option2">Option 2</MenuItem>
                        <MenuItem value="option3">Option 3</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>

                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography component='label' fontSize="16px" variant='caption' fontWeight='bold'>
                        City
                      </SoftTypography>
                    </SoftBox>
                    <FormControl style={styles.selectContainer} fullWidth>
                      <ArrowDropDownIcon style={styles.chevIocn} />

                      <Select value={selectedOptionState} onChange={handleChangestate} >
                        <MenuItem value="">Select an option</MenuItem>
                        <MenuItem selected value="option1">Option 1</MenuItem>
                        <MenuItem value="option2">Option 2</MenuItem>
                        <MenuItem value="option3">Option 3</MenuItem>
                      </Select>
                    </FormControl>

                  </Grid>

                  <Grid item xs={12} sm={6}>

                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography component='label' fontSize="16px" variant='caption' fontWeight='bold'>
                        Postal/Zip Code
                      </SoftTypography>
                    </SoftBox>
                    <SoftInput type='text' placeholder='145518' />
                  </Grid> */}
              </Grid>

              <Grid container spacing={2} pt={3}>
                {/* <Grid item xs={12} md={6} xl={5}>
                    <SoftBox class="platformContainer">
                      <PlatformSettings />
                    </SoftBox>
                  </Grid> */}
                {/* <Grid item xs={12} md={6} xl={12}>
                    <Card>
                      <SoftBox p={3}>
                        <Grid className="dataContainer" container spacing={2}>
                          <SoftTypography variant="h6" pt={1} px={2} >Login information</SoftTypography>

                          <Grid item xs={12} sm={12} pt={0}>

                            <SoftBox mb={1} ml={0.5} pt={0}>
                              <SoftTypography className="subdetails" component='label' fontSize="16px" variant='caption' fontWeight='normal'>
                                Password
                              </SoftTypography>
                            </SoftBox>
                            <SoftInput type='password' placeholder='Enter Password' />

                          </Grid>

                          <Grid item xs={12} sm={12}>

                            <SoftBox mb={1} ml={0.5}>
                              <SoftTypography component='label' fontSize="16px" variant='caption' fontWeight='normal'>
                                Confirm Password
                              </SoftTypography>
                            </SoftBox>
                            <SoftInput type='password' placeholder='Enter Confirm Password' />

                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <SoftBox display="flex" py={1} mb={0.25}>
                              <SoftBox mt={0.25}>
                                <Switch checked={GeneratePassword} onChange={() => setGeneratePassword(!GeneratePassword)} />
                              </SoftBox>
                              <SoftBox ml={1}>
                                <SoftTypography variant="button" fontWeight="regular" color="text">
                                  Generate password
                                </SoftTypography>
                              </SoftBox>
                            </SoftBox>
                          </Grid>

                          <Grid className="" item xs={12} sm={6}>
                            <SoftBox display="flex" py={1} mb={0.25}>
                              <SoftBox mt={0.25}>
                                <Switch checked={credential} onChange={() => setcredential(!credential)} />
                              </SoftBox>
                              <SoftBox ml={1}>
                                <SoftTypography variant="button" fontWeight="regular" color="text">
                                  Send credential to new user
                                </SoftTypography>
                              </SoftBox>
                            </SoftBox>
                          </Grid>
                        </Grid>

                        <Grid className="spce_top" container spacing={2}>
                          <SoftBox display="flex" justifyContent="start" alignItems="start" flexDirection="column">
                            <SoftTypography variant="h6" pt={4} px={2}>Others</SoftTypography>
                            <SoftTypography component='label' fontSize="14px" variant='caption'>Initials are used to name documents</SoftTypography>
                          </SoftBox>

                          <Grid item xs={12} sm={12}>

                            <SoftBox mb={1} ml={0.5}>
                              <SoftTypography component='label' fontSize="16px" variant='caption' fontWeight='normal'>
                                Initials
                              </SoftTypography>
                            </SoftBox>
                            <SoftInput type='password' placeholder='Enter Password' />
                          </Grid>
                        </Grid>
                      </SoftBox>
                    </Card>
                  </Grid> */}
                <Grid
                  item
                  xs={12}
                  display={"flex"}
                  justifyContent={"center"}
                  alignContent={"start"}
                  className="gap-3"
                >
                  <Button onClick={()=>navigate(-1)} variant="contained" color="primary" style={{ color: "white" }} fontWeight="medium">
            Back
          </Button>
                  <Button
                    variant="contained"
                    className ="disabled"
                    color="primary"
                    style={{ color: "white" }}
                    onClick={submit}
                    disabled={!isFormValid || !isEmailAvail}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </SoftBox>
          </Card>
        </SoftBox>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}


export default AddStore;
