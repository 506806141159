/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import * as React from "react";
import { useState } from "react";
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import { Button, TextareaAutosize } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { universalFun } from "util/httpService";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


function ViewSubscription() {

  const navigate = useNavigate()
  const { id } = useParams();
  const [data, setData] = useState({
    description: "",
    features: "",
    plan_name: "",
    price: "",
  });

  useEffect(() => {
    singleSubscription();
  }, []);

  // Details Of Single Subscription-
  const singleSubscription = async () => {
    try {
      const result = await universalFun("POST", `subscription`, {
        type: "get",
        id,
      });
      setData(result?.data?.response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  console.log("data 63", data);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox mt={5}>
        <Card className="card crd_new">
          <SoftBox mb={2}>
            <SoftBox className="prce_subsptns" mb={2}>
              <SoftBox className="flex_basis" mr={3} mb={2}>
                <SoftBox mb={0} ml={0.5}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Plan Name
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  type="text"
                  placeholder="Basic"
                  value={data?.plan_name}
                />
              </SoftBox>
              <SoftBox className="flex_basis" mb={2}>
                <SoftBox mb={0} ml={0.5}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Price
                  </SoftTypography>
                </SoftBox>
                <SoftInput type="text" placeholder="€29" value={data?.price} />
              </SoftBox>
            </SoftBox>
            <SoftBox mb={2} display="flex" flexDirection="column">
              <SoftTypography
                component="label"
                fontSize="13px"
                mb={1}
                variant="caption"
                fontWeight="bold"
              >
                Description
              </SoftTypography>
              <TextareaAutosize
                type="text"
                className="Textarea"
                value={data?.description}
                placeholder="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the"
                minRows={4}
                p={2}
              />
            </SoftBox>
            <SoftBox>
           
              {/* <SoftBox
                className="border_list"
                mt={1}
                dangerouslySetInnerHTML={{ __html: data?.features }}
              /> */}
              <h4>Features</h4>
              <CKEditor
                editor={ClassicEditor}
                placeholder="Features"
                disabled={true}
                data={data?.features}
                onReady={editor => {
                  // You can store the "editor" and use when it is needed.
                  console.log('Editor is ready to use!', editor);
                }}
                // onChange={(e, editor) => handleChange("editor", e, editor)}
                // onBlur={ (e, editor) => handleChange("editor", e, editor) }
               
                // onFocus={(event, editor) => {
                //   console.log('Focus.', editor);
                // }}
              />
            </SoftBox>
          </SoftBox>
        </Card>
        <SoftBox display="flex" className="gap-3 pb-4" justifyContent="center">
          <Button
            onClick={() => navigate(-1)}
            variant="contained"
            color="primary"
            style={{ color: "white" }}
            fontWeight="medium"
          >
            Back
          </Button>
        </SoftBox>
      </SoftBox>

      <Footer />
    </DashboardLayout>
  );
}

export default ViewSubscription;
