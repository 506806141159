/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";

// Data
// import authorsTableData from "layouts/tables/data/authorsTableData";
// import projectsTableData from "layouts/tables/data/projectsTableData";
import Store from "./data/store";
import StoreData from "./data/storeData";
//Button
import Button from '@mui/material/Button';

//Links

import { Link } from "react-router-dom";
import { Modal } from "@mui/material";
import SoftButton from "components/SoftButton";
import { useEffect, useState } from "react";
import SoftInput from "components/SoftInput";
// import SoftTexrarea from "components/SoftTextarea";
import CloseIcon from "@mui/icons-material/Close";
// import SoftBox from "components/SoftBox";
// import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import SoftBadge from "components/SoftBadge";
import Switch from "@mui/material/Switch";
// import { useState } from "react";

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";

//logo
import BMWImage from 'assets/images/BMW.png';

//icons
import IconButton from '@mui/material/IconButton';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DeleteIcon from '@mui/icons-material/Delete'
import EditRoundedIcon from '@mui/icons-material/EditRounded';
// import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import { getFaqsFunction } from "util/httpService";
import { addFaqFunction } from "util/httpService";
import { deleteFaqFunction } from "util/httpService";
import { updateFaqFunction } from "util/httpService";
import { getSingleFaqFunction } from "util/httpService";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

function StoreManagement() {

  // const { columns, rows } = StoreData;
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // ---------secondmodel------
  const [open1, setOpen1] = useState(false)
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);

  // ---------secondmodel------
  const [open2, setOpen2] = useState(false)
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);
  
  // FAQ-
  const [faq, setFaq] = useState({ data: [], success: false });
  const [addFaqQA, setAddFaqQA] = useState({question: "", answer: ""});
  const [updateFaqQA, setUpdateFaqQA] = useState({id: "", question: "", answer: ""});

  const [dataValidation, setDataValidation] = useState({
    question: true,
    answer: true,
  })
  
  // UseEffect-
  useEffect(() => {
    getFaqs();
  }, []);

  // Get Function For Getting FAQs List-
  const getFaqs = async () => {
    try {
      const result = await getFaqsFunction();
      const { data, success } = result?.data?.response;
  
      // Update the faq state correctly
      setFaq({ data, success });
    } catch (error) {
      console.log(error);
    }
  };

  // Post Function For Adding FAQs-
  const addFaqHandler = async() => {
    const payload = {
      question: addFaqQA.question,
      answer: addFaqQA.answer
    }

    if(!addFaqQA.question && !addFaqQA.answer){
      const keys = Object.keys(addFaqQA);
       for(let i = 0; i <= keys.length; i++){
          ValidationCheck(keys[i]);
    }
      return;
    }

    try{
      const result = await addFaqFunction(payload);
      if(result){
        getFaqs();
        handleClose2();
        setAddFaqQA({question: "", answer: ""})
      }
    }
    catch(error){
      console.log(error)
    }
  }

  // Update Function For Updating FAQ-
  const updateFaqHandler = async ({id, question, answer}) => {
    const payload = {
      question,
      answer,
    }

    if(!question && !answer){
      return;
    }

    try{
      const result = await updateFaqFunction(id, payload);
      if(result){
        getFaqs();
        handleClose1();
      } 
    }
    catch(error){
      console.log(error);
    }
  }

  // Delete Function For Delete FAQs-
  const deleteFaqHandler = async (id) => {
    if (window.confirm("Are you sure you want to delete!") == true) {
      try {
        const result = await deleteFaqFunction(id);
        if (result) {
          getFaqs();
        }
      } catch (error) {
        console.log(error);
      }
    }
    
  }
  
  // Columns-
  const columns = [
    { name: "s.no.", align: "left" },
    { name: "question", align: "left" },
    { name: "answer", align: "left" },
    { name: "action", align: "center" },

  ]

  // Rows-
  const rows = faq?.data?.map((el, index) => {
    return {
      "s.no.": <SoftTypography variant="caption" color="secondary" fontWeight="medium" className="rspnv_tbls">
        {index+1}
      </SoftTypography>,

      "question": <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        {el.question}
      </SoftTypography>,

      "answer": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          {el.answer}
        </SoftTypography>
      ),
      "Status": (
        <SoftBox>
          <Switch />
        </SoftBox>
      ),

      "action": (
        <IconButton aria-label="action">
          <Link to="" className="actionIcon">
            <RemoveRedEyeIcon onClick={() => {setOpen(true); setUpdateFaqQA({id: el._id, question: el.question, answer: el.answer})}} />
          </Link>
          <Link to="" className="actionIcon" onClick={() => {setOpen1(true); setUpdateFaqQA({id: el._id, question: el.question, answer: el.answer})}}>
            <EditRoundedIcon />
          </Link>
          <Link to={""} className="actionIcon" onClick={() => deleteFaqHandler(el._id)}>
            <DeleteIcon />
          </Link>
        </IconButton>
      ),
    }
  })

  function ValidationCheck(name){
    switch(name){
      case 'question' :
        setDataValidation((dataValidation)=>({...dataValidation,'question':(
          addFaqQA[name].length === 0) ? false : true})) 
      break;
      case 'answer' :
        setDataValidation((dataValidation)=>({...dataValidation,'answer':(addFaqQA[name].length === 0) ? false : true}))  
      break;  
      default :   
    }
   }

  function checkFuncForValues(obj){
    const keys = Object.keys(obj);
        if(keys.find((ele) => obj[ele].length === 0) === undefined){
          return true;
      }
      else{
        for(let i = 0; i <= keys.length; i++){
          ValidationCheck(keys[i]);
        }
       return false;
      }
   }
  
   function checkFuncForValidation(obj){
    const keys = Object.keys(obj);
         if(keys.find((ele) => obj[ele] === false) === undefined){
             return true;
         }
         else{
            for(let i = 0; i <= keys.length; i++){
              ValidationCheck(keys[i]);
            }
          return false;
         }
  
   }

  //  const handleBlur =(e)=>{

  //  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftTypography variant="h6">Frequently Asked Questions</SoftTypography>
              <Link to="">
                <Button variant="contained" onClick={() => setOpen2(true)} color="primary" style={{ color: "white" }} fontWeight="medium" >
                  Add FAQ
                </Button>
              </Link>
            </SoftBox>
            <SoftBox className="TableContainer"
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <SoftBox className="table-responsive">
                <Table columns={columns} rows={rows} />
              </SoftBox>
            </SoftBox>
          </Card>
        </SoftBox>

      </SoftBox>
      <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={open}>
            <SoftBox sx={style}>
              <SoftTypography component='label' fontSize="18px" variant='caption' fontWeight='bold' mb={3}>
                View FAQ
              </SoftTypography>
              <SoftBox >

                <SoftBox display="flex" flexDirection="column">
                  <SoftBox mb={2} >
                    <SoftTypography component='label' fontSize="13px" variant='caption' fontWeight='bold' >
                      FAQ Question
                    </SoftTypography>

                    <SoftInput type='text' className="TextField" placeholder='' value={updateFaqQA.question} readonly />
                  </SoftBox>
                  <SoftBox mb={2} display="flex" flexDirection="column">
                    <SoftTypography component='label' fontSize="13px" mb={1} variant='caption' fontWeight='bold'>
                      FAQ Answer
                    </SoftTypography>
                    <TextareaAutosize type="text" className="Textarea" value={updateFaqQA.answer} minRows={8} p={2} />
                  </SoftBox>
                  <Button variant="contained" onClick={handleClose} color="primary" style={{ color: "white" }} fontWeight="medium">
                    Back
                  </Button>
                </SoftBox>
              </SoftBox>
              <SoftBox className='cross-icn'>
                <CloseIcon onClick={handleClose} />
              </SoftBox>
            </SoftBox>
          </Fade>

        </Modal>
      </SoftBox>
      <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open1}
          onClose={handleClose1}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={open1}>
            <SoftBox sx={style}>
              <SoftTypography component='label' fontSize="18px" variant='caption' fontWeight='bold' mb={3}>
                Edit FAQ
              </SoftTypography>
              <SoftBox className="d-flex">
                <SoftBox className='cross-icn'>
                  <CloseIcon onClick = {handleClose1} />
                </SoftBox>
              </SoftBox>
              <SoftBox >
                <SoftBox display="flex" flexDirection="column">
                  <SoftBox mb={2} >
                    <SoftTypography component='label' fontSize="13px" variant='caption' fontWeight='bold' >
                      FAQ Question
                    </SoftTypography>

                    <SoftInput 
                      type='text' 
                      className="TextField" 
                      placeholder='' 
                      value={updateFaqQA.question}
                      onChange={(e) => {setUpdateFaqQA({...updateFaqQA, question: e.target.value})}}
                
                    />
                  </SoftBox>
                  <SoftBox mb={2} display="flex" flexDirection="column">
                    <SoftTypography component='label' fontSize="13px" mb={1} variant='caption' fontWeight='bold'>
                      FAQ Answer
                    </SoftTypography>
                    <TextareaAutosize 
                      type="text" 
                      className="Textarea" 
                      placeholder="" 
                      minRows={8} 
                      p={2}
                      value={updateFaqQA.answer}
                      onChange={(e) => {setUpdateFaqQA({...updateFaqQA, answer: e.target.value})}}
                    />
                  </SoftBox>
                  <Button variant="contained" onClick={() => {handleClose1(); updateFaqHandler(updateFaqQA)}} color="primary" style={{ color: "white" }} fontWeight="medium">
                    Save
                  </Button>
                </SoftBox>
              </SoftBox>
            </SoftBox>
          </Fade>

        </Modal>
      </SoftBox>
      <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open2}
          onClose={handleClose2}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={open2}>
            <SoftBox sx={style}>
              <SoftTypography component='label' fontSize="18px" variant='caption' fontWeight='bold' mb={3}>
                Add FAQ
              </SoftTypography>
              <SoftBox className="d-flex">
                <SoftBox className='cross-icn'>
                  <CloseIcon onClick={handleClose2} />
                </SoftBox>
              </SoftBox>
              <SoftBox >
                <SoftBox display="flex" flexDirection="column">
                  <SoftBox mb={2} >
                    <SoftTypography component='label' fontSize="13px" variant='caption' fontWeight='bold' >
                      FAQ Question
                    </SoftTypography>

                    <SoftInput 
                      type='text' 
                      className="TextField" 
                      name = "question"
                      placeholder=''
                      value={addFaqQA.question}
                      onChange={(e) => setAddFaqQA({...addFaqQA, question: e.target.value})}
                      onBlur = {(e)=> ValidationCheck(e.target.name)}
                    />
                     {dataValidation.question === false ? (<div className="validation_message">Question is required</div>) : <></>}
                  </SoftBox>
                  <SoftBox mb={2} display="flex" flexDirection="column">
                    <SoftTypography component='label' fontSize="13px" mb={1} variant='caption' fontWeight='bold'>
                      FAQ Answer
                    </SoftTypography>
                    <TextareaAutosize 
                      type="text" 
                      className="Textarea" 
                      placeholder="" 
                      name = "answer"
                      minRows={8} 
                      p={2} 
                      value={addFaqQA.answer}
                      onChange={(e) => setAddFaqQA({...addFaqQA, answer: e.target.value})}
                      onBlur = {(e)=> ValidationCheck(e.target.name)}
                    />
                     {dataValidation.answer === false ? (<div className="validation_message">Answer is required</div>) : <></>}
                  </SoftBox>
                  <Button variant="contained" onClick={(e) => {handleClose(); addFaqHandler()}} color="primary" style={{ color: "white" }} fontWeight="medium">
                    Save
                  </Button>
                </SoftBox>
              </SoftBox>
            </SoftBox>
          </Fade>

        </Modal>
      </SoftBox>
      <Footer />

    </DashboardLayout>
  );
}

export default StoreManagement;


