/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Checkbox from "@mui/material/Checkbox";
// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import PlaceholderCard from "examples/Cards/PlaceholderCard";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Overview page components

import PlatformSettings from "layouts/add-user/components/PlatformSettings";

// Data
import profilesListData from "layouts/profile/data/profilesListData";

//Tabs
import { Tabs, Tab } from "@mui/material";
import React, { useEffect, useState } from "react";

// Images
import homeDecor1 from "assets/images/home-decor-1.jpg";
import homeDecor2 from "assets/images/home-decor-2.jpg";
import homeDecor3 from "assets/images/home-decor-3.jpg";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import logo from "assets/images/small-logos/logo-apple.svg";
import Button from "@mui/material/Button";
import { universalFun } from "util/httpService";
import { toast } from "react-toastify";

function UserRolesManagement() {
  //tabs

  const [selectedTab, setSelectedTab] = useState(0);
  const [toggles, setToggles] = useState({
    quote: false,
    access_the_calendar: false,
    access_the_workshop: false,
    make_invoices: false,
    create_customers: false,
    create_repair_order: false,
  });

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const [followsMe, setFollowsMe] = useState(true);
  // const [answersPost, setAnswersPost] = useState(false);
  // const [mentionsMe, setMentionsMe] = useState(true);
  // const [newLaunches, setNewLaunches] = useState(false);
  // const [productUpdate, setProductUpdate] = useState(true);
  // const [customers, setCustomers] = useState(true);
  // const [createrepair, setCreaterepair] = useState(true);

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const [GeneratePassword, setGeneratePassword] = useState(true);
  const [Credential, setCredential] = useState(true);

  const handleTogle = (e, property) => {
    setToggles((prev) => ({ ...prev, [property]: e.target.checked }));
  };

  const sendData = async () => {
    const obj = { ...toggles, type: "update" };
    try {
      const result = await universalFun("POST", "user/features", obj);
      // toast.info(result?.data?.response?.message);
      toast.info("User features updated");
    } catch (err) {}
  };

  const handleSave = () => {
    sendData();
  };

  const getData = async () => {
    try {
      const result = await universalFun("POST", "user/features", {
        type: "get",
      });
      if (result?.data?.response?.data) {
        console.log(result.data.response.data);
        const data = result.data.response.data;
        setToggles(data);
      }
    } catch (err) {}
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox mt={5} mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} xl={12}>
            <SoftBox class="tabContainer">
              <Grid item xs={12} md={12} xl={12}>
                <Card>
                  <SoftBox pt={2} px={2}>
                    <SoftTypography
                      variant="h6"
                      fontWeight="medium"
                      textTransform="capitalize"
                    >
                      User Features
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox
                    className="roles_wrap"
                    pt={1.5}
                    pb={2}
                    px={2}
                    lineHeight={1.25}
                  >
                    <SoftBox
                      className="access_users"
                      display="flex"
                      flexDirection="column"
                    >
                      <SoftBox
                        className="fllw_tes"
                        display="flex"
                        py={2}
                        mb={0.25}
                      >
                        <SoftBox mt={0.25}>
                          <Switch
                            checked={toggles.quote}
                            onChange={(e) => handleTogle(e, "quote")}
                          />
                        </SoftBox>
                        <SoftBox ml={2}>
                          <SoftTypography
                            variant="button"
                            fontWeight="regular"
                            color="text"
                          >
                            Quote
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>

                      <SoftBox
                        className="iconwrapper"
                        display="flex"
                        justifyContent="center"
                        alignItems="start"
                      >
                        <SoftBox className="icheck-wrap">
                          <Checkbox className="checkbox" {...label} />
                          <SoftTypography
                            variant="button"
                            fontWeight="regular"
                            color="text"
                          >
                            View
                          </SoftTypography>
                        </SoftBox>

                        <SoftBox className="icheck-wrap">
                          <Checkbox className="checkbox" {...label} />
                          <SoftTypography
                            variant="button"
                            fontWeight="regular"
                            color="text"
                          >
                            Edit
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                    <SoftBox
                      className="access_users"
                      display="flex"
                      flexDirection="column"
                    >
                      <SoftBox
                        className="fllw_tes"
                        display="flex"
                        py={2}
                        mb={0.25}
                      >
                        <SoftBox mt={0.25}>
                          <Switch
                            checked={toggles.access_the_calendar}
                            onChange={(e) =>
                              handleTogle(e, "access_the_calendar")
                            }
                          />
                        </SoftBox>
                        <SoftBox ml={2}>
                          <SoftTypography
                            variant="button"
                            fontWeight="regular"
                            color="text"
                          >
                            Access the calendar
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>

                      <SoftBox
                        className="iconwrapper"
                        display="flex"
                        justifyContent="center"
                        alignItems="start"
                      >
                        <SoftBox className="icheck-wrap">
                          <Checkbox className="checkbox" {...label} />
                          <SoftTypography
                            variant="button"
                            fontWeight="regular"
                            color="text"
                          >
                            View
                          </SoftTypography>
                        </SoftBox>

                        <SoftBox className="icheck-wrap">
                          <Checkbox className="checkbox" {...label} />
                          <SoftTypography
                            variant="button"
                            fontWeight="regular"
                            color="text"
                          >
                            Edit
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                    <SoftBox
                      className="access_users"
                      display="flex"
                      flexDirection="column"
                    >
                      <SoftBox
                        className="fllw_tes"
                        display="flex"
                        py={2}
                        mb={0.25}
                      >
                        <SoftBox mt={0.25}>
                          <Switch
                            checked={toggles.access_the_workshop}
                            onChange={(e) =>
                              handleTogle(e, "access_the_workshop")
                            }
                          />
                        </SoftBox>
                        <SoftBox ml={2}>
                          <SoftTypography
                            variant="button"
                            fontWeight="regular"
                            color="text"
                          >
                            Access the workshop schedule
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>

                      <SoftBox
                        className="iconwrapper"
                        display="flex"
                        justifyContent="center"
                        alignItems="start"
                      >
                        <SoftBox className="icheck-wrap">
                          <Checkbox className="checkbox" {...label} />
                          <SoftTypography
                            variant="button"
                            fontWeight="regular"
                            color="text"
                          >
                            View
                          </SoftTypography>
                        </SoftBox>

                        <SoftBox className="icheck-wrap">
                          <Checkbox className="checkbox" {...label} />
                          <SoftTypography
                            variant="button"
                            fontWeight="regular"
                            color="text"
                          >
                            Edit
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                    <SoftBox
                      className="access_users"
                      display="flex"
                      flexDirection="column"
                    >
                      <SoftBox
                        className="fllw_tes"
                        display="flex"
                        py={2}
                        mb={0.25}
                      >
                        <SoftBox mt={0.25}>
                          <Switch
                            checked={toggles.make_invoices}
                            onChange={(e) => handleTogle(e, "make_invoices")}
                          />
                        </SoftBox>
                        <SoftBox ml={2}>
                          <SoftTypography
                            variant="button"
                            fontWeight="regular"
                            color="text"
                          >
                            Make invoices
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>

                      <SoftBox
                        className="iconwrapper"
                        display="flex"
                        justifyContent="center"
                        alignItems="start"
                      >
                        <SoftBox className="icheck-wrap">
                          <Checkbox className="checkbox" {...label} />
                          <SoftTypography
                            variant="button"
                            fontWeight="regular"
                            color="text"
                          >
                            View
                          </SoftTypography>
                        </SoftBox>

                        <SoftBox className="icheck-wrap">
                          <Checkbox className="checkbox" {...label} />
                          <SoftTypography
                            variant="button"
                            fontWeight="regular"
                            color="text"
                          >
                            Edit
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                    <SoftBox
                      className="access_users"
                      display="flex"
                      flexDirection="column"
                    >
                      <SoftBox
                        className="fllw_tes"
                        display="flex"
                        py={2}
                        mb={0.25}
                      >
                        <SoftBox mt={0.25}>
                          <Switch
                            checked={toggles.create_customers}
                            onChange={(e) => handleTogle(e, "create_customers")}
                          />
                        </SoftBox>
                        <SoftBox ml={2}>
                          <SoftTypography
                            variant="button"
                            fontWeight="regular"
                            color="text"
                          >
                            Create customers
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>

                      <SoftBox
                        className="iconwrapper"
                        display="flex"
                        justifyContent="center"
                        alignItems="start"
                      >
                        <SoftBox className="icheck-wrap">
                          <Checkbox className="checkbox" {...label} />
                          <SoftTypography
                            variant="button"
                            fontWeight="regular"
                            color="text"
                          >
                            View
                          </SoftTypography>
                        </SoftBox>

                        <SoftBox className="icheck-wrap">
                          <Checkbox className="checkbox" {...label} />
                          <SoftTypography
                            variant="button"
                            fontWeight="regular"
                            color="text"
                          >
                            Edit
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                    <SoftBox
                      className="access_users"
                      display="flex"
                      flexDirection="column"
                    >
                      <SoftBox
                        className="fllw_tes"
                        display="flex"
                        py={2}
                        mb={0.25}
                      >
                        <SoftBox mt={0.25}>
                          <Switch
                            checked={toggles.create_repair_order}
                            onChange={(e) =>
                              handleTogle(e, "create_repair_order")
                            }
                          />
                        </SoftBox>
                        <SoftBox ml={2}>
                          <SoftTypography
                            variant="button"
                            fontWeight="regular"
                            color="text"
                          >
                            Create repair order
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>

                      <SoftBox
                        className="iconwrapper"
                        display="flex"
                        justifyContent="center"
                        alignItems="start"
                      >
                        <SoftBox className="icheck-wrap">
                          <Checkbox className="checkbox" {...label} />
                          <SoftTypography
                            variant="button"
                            fontWeight="regular"
                            color="text"
                          >
                            View
                          </SoftTypography>
                        </SoftBox>

                        <SoftBox className="icheck-wrap">
                          <Checkbox className="checkbox" {...label} />
                          <SoftTypography
                            variant="button"
                            fontWeight="regular"
                            color="text"
                          >
                            Edit
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>
                </Card>
              </Grid>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
      <SoftBox display="flex" justifyContent="center">
        <Button
          onClick={handleSave}
          variant="contained"
          color="primary"
          style={{ color: "white" }}
          fontWeight="medium"
        >
          Save
        </Button>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default UserRolesManagement;
