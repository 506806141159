/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import * as React from "react";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";

// @mui icons
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";

import team1 from "../../../assets/images/team-1.jpg";
import teams2 from "../../../assets/images/team-2.jpg";
import imagePlaceholder from "../../../assets/images/icons/icon-placeholder.svg";
import usePlaceholder from "../../../assets/images/FallbackImage.jpg";
import serviceImg from "../../../assets/images/icons/service-1.png";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";

// Overview page components
import Header from "../About-us/Header";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

//Images
import highSkilled from "assets/images/highSkilled.jpg";

//input
import SoftInput from "components/SoftInput";
import TextareaAutosize from "@mui/base/TextareaAutosize";

//Button
import Button from "@mui/material/Button";

//Links
import { Link } from "react-router-dom";

import Table from "examples/Tables/Table";

//icons
import IconButton from "@mui/material/IconButton";
import EditRoundedIcon from "@mui/icons-material/EditRounded";

import { useState, useEffect } from "react";
import { getAboutUsFunction } from "util/httpService";
import SoftAvatar from "components/SoftAvatar";
import { Author } from "./data/whatClientsay";
import { universalFun } from "util/httpService";
import { uploadMedia } from "util/httpService";
import axios from "axios";
import { toast } from "react-toastify";
import DeleteModal from "components/DeleteModal";
import { Handyman } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #202090",
  boxShadow: 24,
  overflow: "auto",
  p: 4,
  height: 554.8,
  minHeight: 554.8,
};

function AboutUs() {


  //delete modal states
const [showDeleteModal , setShowDeleteModal] = useState(false)

const handleDeleteOpen = ()=> setShowDeleteModal(true)
const handleDeleteClose = ()=> setShowDeleteModal(false)

const [deleteId , setDeleteId ] = useState("") 

  //modal for whychooseus
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
    setModal("why_choose_us");
  };
  const handleClose = () => {
    setOpen(false);
    resetModalData()
    resetError();
  };

  //view for whychooseus
  const [openView, setOpenView] = useState(false);
  const handleOpenView = () => setOpenView(true);
  const handleCloseView = () => {
    setOpenView(false);
    setAboutUsData({
      icon: "",
      title: "",
      description: "",
      designation: "",
      type: "",
    });
  };

  //edit for whychooseus
  const [openEdit, setOpenEdit] = useState(false);
  const handleOpenEdit = () => {
    setOpenEdit(true);
    setModal("why_choose_us");
  };
  const handleCloseEdit = () => {
    setOpenEdit(false);

    resetError();
    resetModalData()

  };

  //modal for client
  const [open2, setOpen2] = useState(false);
  const handleOpen2 = () => {
    setOpen2(true);
    setModal("what_our_client_say");
  };
  const handleClose2 = () => {
    resetError();
    resetModalData()

    setOpen2(false);
  };

  //View modal for client
  const [open2View, setOpen2View] = useState(false);
  const handleOpen2View = () => setOpenView(true);

  const handleClose2View = () => {
    setOpen2View(false);
    setAboutUsData({
      icon: "",
      title: "",
      description: "",
      designation: "",
      type: "",
    });
  };

  //Edit modal for client
  const [open2Edit, setOpen2Edit] = useState(false);

  const handleOpen2Edit = () => {

    setOpen2Edit(true);
    setModal("what_our_client_say");
    

  };

  const handleClose2Edit = () => {
    setOpen2Edit(false);
    resetError();

    resetModalData()
  };

  //modal for ourServices
  const [open1, setOpen1] = useState(false);
  const handleOpen1 = () => {
    setOpen1(true);
    setModal("our_services");
  };
  const handleClose1 = () => {setOpen1(false)
    resetError();
    resetModalData()
    ;}

  const [open1View, setOpen1View] = useState(false);
  const handleOpen1View = () => setOpen1View(true);
  const handleClose1View = () => {
    setOpen1View(false);
    setAboutUsData({
      icon: "",
      title: "",
      description: "",
      designation: "",
      type: "",
    });
  };

  const [open1Edit, setOpen1Edit] = useState(false);
  const handleOpen1Edit = () => {
    setOpen1Edit(true);
    setModal("our_services");
  };
  const handleClose1Edit = () => {
    setOpen1Edit(false);
    resetError();
    setAboutUsData({
      icon: "",
      title: "",
      description: "",
      designation: "",
      type: "",
    });
  };

  // About Us Functions And States-----------------------------------
  const [data, setData] = useState({
    whyChooseUs: [],
    whatOurClientSays: [],
    ourServices: [],
  });
  const [aboutUsData, setAboutUsData] = useState({
    icon: "",
    title: "",
    description: "",
    designation: "",
    type: "",
    loader: false,
    aboutUs: {
      about_img_1: "",
      about_img_2: "",
      first_para: "",
      title_first: "",
      type: "about",
    },
  });

  //validation
  const [validationErrors, setValidationErrors] = useState({
    title: false,
    description: false,
  });

  const [modal, setModal] = useState("");

  useEffect(() => {
    getOtherAboutUs();
    getAboutUsFun();
  }, []);

  // Handle Change-
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (e.target.name !== "first_para" && e.target.name !== "title_first") {
      setAboutUsData({ ...aboutUsData, [e.target.name]: e.target.value });
      validateField(name, value);
    } else {
      setAboutUsData((prev) => ({
        ...prev,
        aboutUs: {
          ...prev.aboutUs,
          [e.target.name]: e.target.value,
        },
      }));
    }
  };

  //   -------------------------validaiton ----------------------------
  const validateField = (fieldName, value) => {
    const errors = { ...validationErrors };
    switch (fieldName) {
      case "title":
        errors.title = !value.trim();
        break;
      case "description":
        errors.description = !value.trim();
        break;
        case "designation":
        errors.designation = !value.trim();
        break;
      // case "icon":
      //   errors.icon = !value;
      //   break;
      default:
        break;
    }
    setValidationErrors(errors);
  };

  const validateForm = () => {
    const errors = {
      title: !aboutUsData.title.trim(),
      description: !aboutUsData.description.trim(),
      // icon: !aboutUsData.icon,
    };

    if (modal === "what_our_client_say") {
      errors.designation = !aboutUsData.designation.trim();
    }

    setValidationErrors(errors);

    return !Object.values(errors).some(Boolean);
  };

  const resetError = () => {
    setValidationErrors({
      title: false,
      description: false,
    });
  };

  const resetModalData = ()=>{
    setAboutUsData({
      icon: "",
      title: "",
      description: "",
      designation: "",
      type: "",
    });
  }

  // About Us-
  const getAboutUsFun = async () => {
    try {
      const result = await universalFun("GET", `get/cms/about`);
      if (result?.data) {
        setAboutUsData((prev) => ({
          ...prev,
          aboutUs: {
            ...prev.aboutUs,
            ...result?.data?.item?.data,
          },
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Other About Us List-
  const getOtherAboutUs = async () => {
    try {
      // setAboutUsData({...aboutUsData, loader: true})
      const result = await getAboutUsFunction();
      const [a, b, c] = result;
      if (result) {
        setData({ whyChooseUs: a, whatOurClientSays: b, ourServices: c });
        // setAboutUsData({...aboutUsData, loader: false})
      }
    } catch (error) {
      console.log(error);
      // setAboutUsData({...aboutUsData, loader: false})
    }
  };

  // Other Add About Us-
  const addOtherAboutUs = async (type) => {
    try {
      if (type !== "about_us") {
        if (validateForm()) {
          try {
            const result = await universalFun("POST", `create/about/us/other`, {
              ...aboutUsData,
              type,
            });
            if (result?.data) {
              getOtherAboutUs();
              setAboutUsData({
                icon: "",
                title: "",
                description: "",
                designation: "",
                type: "",
              });
              handleClose();
              handleClose1();
              handleClose2();
              toast.success("Successfully added");
            }
          } catch (error) {
            console.log(error);
          }
        }
      } else {
        const result = await universalFun(
          "PATCH",
          `update/cms`,
          aboutUsData?.aboutUs
        );
        if (result?.data?.code === 200) {
          toast.success("Successfully updated");
        }
      }
    } catch (error) {
      console.log(error);
      setAboutUsData({ ...aboutUsData, loader: false });
    }
  };

  // Other Delete About Us-
  const deleteOtherAboutUs = async () => {
    if(deleteId){
      try {
        setAboutUsData({ ...aboutUsData, loader: true });
        const result = await universalFun("POST", `delete/about/us/${deleteId}`);
        if (result?.data) {
          getOtherAboutUs();
          handleDeleteClose()
          handleClose();
          handleClose1();
          handleClose2();
          toast.success("Successfully deleted");
          setAboutUsData({ ...aboutUsData, loader: false });
        }
      } catch (error) {
        setAboutUsData({ ...aboutUsData, loader: false });
        console.log(error);
      }

    }
  };

  // handle edit function

  const handleEdit = async (data, type) => {
    const newObj = {};
    for (const [key, value] of Object.entries(data)) {
      if (value !== null && value !== undefined && value !== "") {
        newObj[key] = value;
      }
    }
    const body = { ...newObj, type };
    console.log("b----", body);
    if (validateForm()) {
      try {
        console.log(data);
        const result = await universalFun(
          "PATCH",
          `edit/about/us/${data?.id}`,
          body
        );
        console.log(result);
        // alert(result?.data?.response?.message)
        handleCloseEdit();
        handleClose1Edit();
        handleClose2Edit();

        getOtherAboutUs();
      } catch (err) {
        // alert(err)
        console.log(err);
      }
    }
  };

  // We have Rows and Columns to show listing------------
  // Column1 and Row1-
  const column1 = [
    { name: "icon", align: "left" },
    { name: "Title", align: "left" },
    { name: "Description", align: "left" },
    { name: "action", align: "center" },
  ];

  const row1 = data?.whyChooseUs?.map((el) => ({
    icon: (
      <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
        <SoftBox mr={2}>
          <SoftAvatar
            src={
              el?.icon
                ? process.env.REACT_APP_IMAGE_URL + "why_choose_us/" + el?.icon
                : imagePlaceholder
            }
            alt={"name"}
            size="sm"
            variant="rounded"
          />
        </SoftBox>
        {/* <SoftBox display="flex" flexDirection="column">
      <SoftTypography variant="button" fontWeight="medium">
        {"name"}
      </SoftTypography>
      <SoftTypography variant="caption" color="secondary">
        {"email"}
      </SoftTypography>
    </SoftBox> */}
      </SoftBox>
    ),

    Title: (
      <SoftTypography className="textarea textarea-title">
        {el?.title}
      </SoftTypography>
    ),

    Description: (
      <SoftTypography className="textarea textarea-para">
        {el?.description}
      </SoftTypography>
    ),

    action: (
      <IconButton aria-label="action">
        <Link
          to=""
          className="actionIcon"
          onClick={() => {
            setOpenView(true);
            setAboutUsData({
              ...aboutUsData,
              icon: el?.icon,
              title: el.title,
              description: el.description,
            });
          }}
        >
          <RemoveRedEyeIcon />
        </Link>
        <Link
          to=""
          className="actionIcon"
          onClick={() => {
            setOpenEdit(true);
            setAboutUsData({
              ...aboutUsData,
              id: el._id,
              icon: el?.icon,
              title: el.title,
              description: el.description,
            });
          }}
        >
          <EditRoundedIcon />
        </Link>
        <Link
          to={""}
          // onClick={() => deleteOtherAboutUs(el?._id, el?.type)}
          onClick={() => {handleDeleteOpen();  setDeleteId(el?._id)}}
          className="actionIcon"
        >
          <DeleteIcon />
        </Link>
      </IconButton>
    ),
  }));

  // Column2 and Row2-
  const column2 = [
    { name: "client", align: "left" },
    { name: "Description", align: "left" },
    { name: "action", align: "center" },
  ];

  const row2 = data?.whatOurClientSays?.map((el) => ({
    client: (
      <Author
        image={
          el?.icon
            ? process.env.REACT_APP_IMAGE_URL +
              "what_our_client_say/" +
              el?.icon
            : usePlaceholder
        }
        name={el?.title}
        Designation={el?.designation}
      />
    ),

    Description: (
      <SoftTypography className="textarea client-desc">
        {el?.description}
      </SoftTypography>
    ),

    action: (
      <IconButton aria-label="action">
        <Link
          to=""
          className="actionIcon"
          onClick={() => {
            setOpen2View(true);
            setAboutUsData({
              ...aboutUsData,
              id: el._id,
              designation: el?.designation,
              icon: el?.icon,
              title: el.title,
              description: el.description,
            });
          }}
        >
          <RemoveRedEyeIcon />
        </Link>
        <Link
          to=""
          className="actionIcon"
          onClick={() => {
            setOpen2Edit(true);
            setAboutUsData({
              ...aboutUsData,
              id: el._id,
              designation: el?.designation,
              icon: el?.icon,
              title: el.title,
              description: el.description,
            });
          }}
        >
          <EditRoundedIcon />
        </Link>
        <Link
          to=""
          // onClick={() => deleteOtherAboutUs(el?._id, el?.type)}
          onClick={() => {handleDeleteOpen();  setDeleteId(el?._id)}}

          className="actionIcon"
        >
          <DeleteIcon />
        </Link>
      </IconButton>
    ),
  }));

  // Column3 and Row3-
  const column3 = [
    { name: "icon", align: "left" },
    { name: "Title", align: "left" },
    { name: "Description", align: "left" },
    { name: "action", align: "center" },
  ];

  const row3 = data?.ourServices?.map((el) => ({
    icon: (
      <Author
        image={
          el?.icon
            ? process.env.REACT_APP_IMAGE_URL + "our_services/" + el?.icon
            : imagePlaceholder
        }
      />
    ),

    Title: (
      <SoftTypography className="textarea textarea-title">
        {el?.title}
      </SoftTypography>
    ),

    Description: (
      <SoftTypography className="textarea textarea-para">
        {el?.description}
      </SoftTypography>
    ),

    action: (
      <IconButton aria-label="action">
        <Link
          to=""
          className="actionIcon"
          onClick={() => {
            setOpen1View(true);
            setAboutUsData({
              ...aboutUsData,
              icon: el?.icon,
              title: el.title,
              description: el.description,
            });
          }}
        >
          <RemoveRedEyeIcon />
        </Link>
        <Link
          to=""
          className="actionIcon"
          onClick={() => {
            setOpen1Edit(true);
            setAboutUsData({
              ...aboutUsData,
              icon: el?.icon,
              id: el?._id,
              title: el.title,
              description: el.description,
            });
          }}
        >
          <EditRoundedIcon />
        </Link>
        <Link
          to=""
          // onClick={() => deleteOtherAboutUs(el?._id, el?.type)}
          onClick={() => {handleDeleteOpen();  setDeleteId(el?._id)}}

          className="actionIcon"
        >
          <DeleteIcon />
        </Link>
      </IconButton>
    ),
  }));

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      //checking for type
      if (!file.type.startsWith("image/")) {
        toast.error("Please upload an image file");

        
        return;
      }

    let formData = new FormData();
    formData.append("path", modal);
    formData.append("media", e.target.files[0]);

    try {
      let resp = await uploadMedia(formData);
      if (resp) {
        setAboutUsData((prev) => ({
          ...prev,
          icon: resp?.data?.media,
        }));
      }
    } catch (error) {
      console.error("Error uploading media:", error);
    }
  }
}

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox mt={5}>
        <Card className="card cardimage" position="relative">
          <Header banner={aboutUsData?.aboutUs?.about_img_1} />
          <SoftBox className="upload">
            <label for="file-upload-banner">
              <CloudUploadIcon className="upload-btn" />
            </label>
            <input
              w
              id="file-upload-banner"
              style={{ display: "none" }}
              type="file"
              onChange={async (e) => {
                let formData = new FormData();
                formData.append("path", "about_us");
                formData.append("media", e.target.files[0]);

                try {
                  let resp = await uploadMedia(formData);
                  if (resp) {
                    setAboutUsData((prev) => ({
                      ...prev,
                      aboutUs: {
                        ...prev.aboutUs,
                        about_img_1: resp?.data?.media,
                      },
                    }));
                  }
                } catch (error) {
                  console.error("Error uploading media:", error);
                }
              }}
            ></input>
          </SoftBox>
        </Card>
        <Card className="card">
          <Grid container spacing={2}>
            <SoftBox display="flex" p={3}>
              <Grid className="cardimage" sm={6} p={2}>
                <img
                  className="img-fluid border-radius"
                  src={
                    aboutUsData?.aboutUs?.about_img_2
                      ? process.env.REACT_APP_IMAGE_URL +
                        "about_us/" +
                        aboutUsData?.aboutUs?.about_img_2
                      : highSkilled
                  }
                  alt="technician"
                  layout="fill"
                  onError={(e) => {
                    e.target.src = highSkilled;
                  }}
                />
                <SoftBox className="upload">
                  <label for="file-upload-content">
                    <CloudUploadIcon className="upload-btn" />
                  </label>
                  <input
                    id="file-upload-content"
                    style={{ display: "none" }}
                    type="file"
                    onChange={async (e) => {
                      let formData = new FormData();
                      formData.append("path", "about_us");
                      formData.append("media", e.target.files[0]);

                      try {
                        let resp = await uploadMedia(formData);
                        if (resp) {
                          setAboutUsData((prev) => ({
                            ...prev,
                            aboutUs: {
                              ...prev.aboutUs,
                              about_img_2: resp?.data?.media,
                            },
                          }));
                        }
                      } catch (error) {
                        console.error("Error uploading media:", error);
                      }
                    }}
                  ></input>
                </SoftBox>
              </Grid>
              <Grid sm={6} p={2}>
                <SoftBox display="flex" flexDirection="column">
                  <SoftBox mb={2}>
                    <SoftTypography
                      component="label"
                      fontSize="16px"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Title
                    </SoftTypography>
                    <SoftInput
                      type="text"
                      className="TextField"
                      placeholder="We are highly skilled mechanics for your car repair"
                      name="title_first"
                      value={aboutUsData?.aboutUs?.title_first}
                      onChange={(e) => handleChange(e)}
                    />
                  </SoftBox>
                  <SoftBox mb={2} display="flex" flexDirection="column">
                    <SoftTypography
                      component="label"
                      fontSize="16px"
                      mb={1}
                      variant="caption"
                      fontWeight="bold"
                    >
                      Description
                    </SoftTypography>
                    <TextareaAutosize
                      type="text"
                      className="Textarea"
                      placeholder=""
                      minRows={13}
                      p={2}
                      name="first_para"
                      value={aboutUsData?.aboutUs?.first_para}
                      onChange={(e) => handleChange(e)}
                    />
                  </SoftBox>
                </SoftBox>
              </Grid>
            </SoftBox>
          </Grid>
        </Card>

        <SoftBox py={3}>
          <SoftBox mb={3}>
            <Card>
              <SoftBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={3}
              >
                <SoftTypography variant="h6">Why Choose Us</SoftTypography>
                <Button
                  variant="contained"
                  onClick={() => {
                    handleOpen();
                  }}
                  color="primary"
                  style={{ color: "white" }}
                  fontWeight="medium"
                >
                  Add New List
                </Button>
                {/* ------------------------add why choose us-------------------- */}
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  open={open}
                  onClose={handleClose}
                  closeAfterTransition
                  slots={{ backdrop: Backdrop }}
                  slotProps={{
                    backdrop: {
                      timeout: 500,
                    },
                  }}
                >
                  <Fade in={open}>
                    <SoftBox sx={style}>
                      <SoftBox>
                        <SoftBox className="icons-contained">
                          <input
                            className="icon-upload"
                            accept="image/*"
                            id="image-upload"
                            type="file"
                            onChange={handleImageUpload}
                          />
                          <label htmlFor="image-upload">
                            <IconButton color="white" component="span">
                              <EditRoundedIcon />
                            </IconButton>
                          </label>
                          <div className="previewContainer">
                            <img
                              className="previewImage"
                              src={
                                aboutUsData?.icon
                                  ? process.env.REACT_APP_IMAGE_URL +
                                    "why_choose_us/" +
                                    aboutUsData?.icon
                                  : imagePlaceholder
                              }
                              alt="Uploaded"
                            />
                          </div>
                        </SoftBox>
                        <SoftBox display="flex" flexDirection="column">
                          <SoftBox mb={2}>
                            <SoftTypography
                              component="label"
                              fontSize="16px"
                              variant="caption"
                              fontWeight="bold"
                            >
                              Title
                            </SoftTypography>
                            <SoftInput
                              type="text"
                              className="TextField"
                              placeholder=""
                              name="title"
                              value={aboutUsData.title}
                              onChange={(e) => handleChange(e)}
                            />
                            {validationErrors.title && (
                              <SoftTypography color="error" variant="caption">
                                Title is required
                              </SoftTypography>
                            )}
                          </SoftBox>
                          <SoftBox mb={2} display="flex" flexDirection="column">
                            <SoftTypography
                              component="label"
                              fontSize="16px"
                              mb={1}
                              variant="caption"
                              fontWeight="bold"
                            >
                              Description
                            </SoftTypography>
                            <TextareaAutosize
                              className="Textarea"
                              minRows={6}
                              p={2}
                              name="description"
                              value={aboutUsData.description}
                              onChange={(e) => handleChange(e)}
                            />
                            {validationErrors.description && (
                              <SoftTypography color="error" variant="caption">
                                Description is required
                              </SoftTypography>
                            )}
                          </SoftBox>
                          <Button
                            variant="contained"
                            onClick={() => {
                              addOtherAboutUs("why_choose_us");
                            }}
                            color="primary"
                            style={{ color: "white" }}
                            fontWeight="medium"
                          >
                            Save
                          </Button>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                  </Fade>
                </Modal>

                {/* ------------------------view why choose us-------------------- */}
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  open={openView}
                  onClose={handleCloseView}
                  closeAfterTransition
                  slots={{ backdrop: Backdrop }}
                  slotprops={{
                    backdrop: {
                      timeout: 500,
                    },
                  }}
                >
                  <Fade in={openView}>
                    <SoftBox sx={style}>
                      <SoftBox>
                        <SoftBox className="icons-contained">
                          <input
                            className="icon-upload"
                            accept="image/*"
                            id="image-upload"
                            // type="file"
                          />
                          <label htmlFor="image-upload">
                            <IconButton color="white" component="span">
                              <EditRoundedIcon />
                            </IconButton>
                          </label>
                          <div className="previewContainer">
                            <img
                              className="previewImage"
                              src={
                                aboutUsData?.icon
                                  ? process.env.REACT_APP_IMAGE_URL +
                                    "why_choose_us/" +
                                    aboutUsData?.icon
                                  : imagePlaceholder
                              }
                              alt="Uploaded"
                            />
                          </div>
                        </SoftBox>
                        <SoftBox display="flex" flexDirection="column">
                          <SoftBox mb={2}>
                            <SoftTypography
                              component="label"
                              fontSize="16px"
                              variant="caption"
                              fontWeight="bold"
                            >
                              Title
                            </SoftTypography>
                            <SoftInput
                              type="text"
                              className="TextField"
                              placeholder=""
                              name="title"
                              value={aboutUsData.title}
                            />
                          </SoftBox>
                          <SoftBox mb={2} display="flex" flexDirection="column">
                            <SoftTypography
                              component="label"
                              fontSize="16px"
                              mb={1}
                              variant="caption"
                              fontWeight="bold"
                            >
                              Description
                            </SoftTypography>
                            <TextareaAutosize
                              className="Textarea"
                              minRows={6}
                              p={2}
                              name="description"
                              value={aboutUsData.description}
                            />
                          </SoftBox>
                          {/* <Button variant="contained" onClick={() => {addOtherAboutUs("why_choose_us")}} color="primary" style={{ color: "white" }} fontWeight="medium">
                            Save
                          </Button> */}
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                  </Fade>
                </Modal>

                {/* ------------------------edit why choose us-------------------- */}
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  open={openEdit}
                  onClose={handleCloseEdit}
                  closeAfterTransition
                  slots={{ backdrop: Backdrop }}
                  slotprops={{
                    backdrop: {
                      timeout: 500,
                    },
                  }}
                >
                  <Fade in={openEdit}>
                    <SoftBox sx={style}>
                      <SoftBox>
                        <SoftBox className="icons-contained">
                          <input
                            className="icon-upload"
                            accept="image/*"
                            id="image-upload"
                            type="file"
                            onChange={handleImageUpload}
                          />
                          <label htmlFor="image-upload">
                            <IconButton color="white" component="span">
                              <EditRoundedIcon />
                            </IconButton>
                          </label>
                          <div className="previewContainer">
                            <img
                              className="previewImage"
                              src={
                                aboutUsData?.icon
                                  ? process.env.REACT_APP_IMAGE_URL +
                                    "why_choose_us/" +
                                    aboutUsData?.icon
                                  : imagePlaceholder
                              }
                              alt="Uploaded"
                            />
                          </div>
                        </SoftBox>
                        <SoftBox display="flex" flexDirection="column">
                          <SoftBox mb={2}>
                            <SoftTypography
                              component="label"
                              fontSize="16px"
                              variant="caption"
                              fontWeight="bold"
                            >
                              Title
                            </SoftTypography>
                            <SoftInput
                              type="text"
                              className="TextField"
                              placeholder=""
                              name="title"
                              value={aboutUsData.title}
                              onChange={(e) => handleChange(e)}
                            />
                            {validationErrors.title && (
                              <SoftTypography color="error" variant="caption">
                                Title is required
                              </SoftTypography>
                            )}
                          </SoftBox>
                          <SoftBox mb={2} display="flex" flexDirection="column">
                            <SoftTypography
                              component="label"
                              fontSize="16px"
                              mb={1}
                              variant="caption"
                              fontWeight="bold"
                            >
                              Description
                            </SoftTypography>
                            <TextareaAutosize
                              className="Textarea"
                              minRows={6}
                              p={2}
                              name="description"
                              value={aboutUsData.description}
                              onChange={(e) => handleChange(e)}
                            />
                            {validationErrors.description && (
                              <SoftTypography color="error" variant="caption">
                                Description is required
                              </SoftTypography>
                            )}
                          </SoftBox>
                          <Button
                            variant="contained"
                            onClick={() => {
                              handleEdit(aboutUsData, "why_choose_us");
                            }}
                            color="primary"
                            style={{ color: "white" }}
                            fontWeight="medium"
                          >
                            Save
                          </Button>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                  </Fade>
                </Modal>

              </SoftBox>

              <SoftBox
                className="TableContainer"
                sx={{
                  "& .MuiTableRow-root:not(:last-child)": {
                    "& td": {
                      borderBottom: ({
                        borders: { borderWidth, borderColor },
                      }) => `${borderWidth[1]} solid ${borderColor}`,
                    },
                  },
                }}
              >
                <Table
                  columns={column1}
                  rows={row1}
                  className="subtableContainer"
                />
              </SoftBox>
            </Card>
          </SoftBox>
          <SoftBox mb={3}>
            <Card>
              <SoftBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={3}
              >
                <SoftTypography variant="h6">
                  What our Clients say
                </SoftTypography>
                <Button
                  variant="contained"
                  onClick={() => {
                    handleOpen2();
                  }}
                  color="primary"
                  style={{ color: "white" }}
                  fontWeight="medium"
                >
                  Add New Client
                </Button>

                {/* ---------------add   What our Clients say------------------  */}
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  open={open2}
                  onClose={handleClose2}
                  closeAfterTransition
                  slots={{ backdrop: Backdrop }}
                  slotProps={{
                    backdrop: {
                      timeout: 500,
                    },
                  }}
                >
                  {/* <Fade in={open}> */}
                  <SoftBox sx={style}>
                    <SoftBox>
                      <SoftBox className="icons-contained">
                        <input
                          className="icon-upload"
                          accept="image/*"
                          id="image-upload"
                          type="file"
                          onChange={handleImageUpload}
                        />
                        <label htmlFor="image-upload">
                          <IconButton color="white" component="span">
                            <EditRoundedIcon />
                          </IconButton>
                        </label>
                        <div className="previewContainer">
                          <img
                            className="previewImage"
                            src={
                              aboutUsData?.icon
                                ? process.env.REACT_APP_IMAGE_URL +
                                  "what_our_client_say/" +
                                  aboutUsData?.icon
                                : usePlaceholder
                            }
                            alt="Uploaded"
                          />
                        </div>
                      </SoftBox>
                      <SoftBox display="flex" flexDirection="column">
                        <SoftBox mb={2}>
                          <SoftTypography
                            component="label"
                            fontSize="16px"
                            variant="caption"
                            fontWeight="bold"
                          >
                             Client Name
                          </SoftTypography>
                          <SoftInput
                            type="text"
                            className="TextField"
                            placeholder=""
                            name="title"
                            value={aboutUsData.title}
                            onChange={(e) => handleChange(e)}
                          />
                          {validationErrors.title && (
                            <SoftTypography color="error" variant="caption">
                              Client name is required
                            </SoftTypography>
                          )}
                        </SoftBox>
                        <SoftBox mb={2}>
                          <SoftTypography
                            component="label"
                            fontSize="16px"
                            variant="caption"
                            fontWeight="bold"
                          >
                            Designation
                          </SoftTypography>
                          <SoftInput
                            type="text"
                            className="TextField"
                            placeholder=""
                            name="designation"
                            value={aboutUsData.designation}
                            onChange={(e) => handleChange(e)}
                          />
                          {validationErrors.designation && (
                            <SoftTypography color="error" variant="caption">
                              Designation is required
                            </SoftTypography>
                          )}
                        </SoftBox>
                        <SoftBox mb={2} display="flex" flexDirection="column">
                          <SoftTypography
                            component="label"
                            fontSize="16px"
                            mb={1}
                            variant="caption"
                            fontWeight="bold"
                          >
                            Description
                          </SoftTypography>
                          <TextareaAutosize
                            className="Textarea"
                            minRows={6}
                            p={2}
                            name="description"
                            value={aboutUsData.description}
                            onChange={(e) => handleChange(e)}
                          />
                          {validationErrors.description && (
                            <SoftTypography color="error" variant="caption">
                              Description is required
                            </SoftTypography>
                          )}
                        </SoftBox>
                        <Button
                          variant="contained"
                          onClick={() => {
                            addOtherAboutUs("what_our_client_say");
                          }}
                          color="primary"
                          style={{ color: "white" }}
                          fontWeight="medium"
                        >
                          Save
                        </Button>
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>

                  {/* </Fade> */}
                </Modal>

                {/* ---------------view   What our Clients say------------------  */}

                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  open={open2View}
                  onClose={handleClose2View}
                  closeAfterTransition
                  slots={{ backdrop: Backdrop }}
                  slotProps={{
                    backdrop: {
                      timeout: 500,
                    },
                  }}
                >
                  {/* <Fade in={open}> */}
                  <SoftBox sx={style}>
                    <SoftBox>
                      <SoftBox className="icons-contained">
                        <input
                          className="icon-upload"
                          accept="image/*"
                          id="image-upload"
                        />
                        <label htmlFor="image-upload">
                          <IconButton color="white" component="span">
                            <EditRoundedIcon />
                          </IconButton>
                        </label>
                        <div className="previewContainer">
                          <img
                            className="previewImage"
                            src={
                              aboutUsData?.icon
                                ? process.env.REACT_APP_IMAGE_URL +
                                  "what_our_client_say/" +
                                  aboutUsData?.icon
                                : usePlaceholder
                            }
                            alt="Uploaded"
                          />
                        </div>
                      </SoftBox>
                      <SoftBox display="flex" flexDirection="column">
                        <SoftBox mb={2}>
                          <SoftTypography
                            component="label"
                            fontSize="16px"
                            variant="caption"
                            fontWeight="bold"
                          >
                           Client Name
                          </SoftTypography>
                          <SoftInput
                            type="text"
                            className="TextField"
                            placeholder=""
                            name="title"
                            value={aboutUsData.title}
                          />
                        </SoftBox>
                        <SoftBox mb={2}>
                          <SoftTypography
                            component="label"
                            fontSize="16px"
                            variant="caption"
                            fontWeight="bold"
                          >
                            Designation
                          </SoftTypography>
                          <SoftInput
                            type="text"
                            className="TextField"
                            placeholder=""
                            name="designation"
                            value={aboutUsData.designation}
                          />
                        </SoftBox>
                        <SoftBox mb={2} display="flex" flexDirection="column">
                          <SoftTypography
                            component="label"
                            fontSize="16px"
                            mb={1}
                            variant="caption"
                            fontWeight="bold"
                          >
                            Description
                          </SoftTypography>
                          <TextareaAutosize
                            className="Textarea"
                            minRows={6}
                            p={2}
                            name="description"
                            value={aboutUsData.description}
                          />
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>

                  {/* </Fade> */}
                </Modal>

                {/* ---------------edit   What our Clients say------------------  */}

                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  open={open2Edit}
                  onClose={handleClose2Edit}
                  closeAfterTransition
                  slots={{ backdrop: Backdrop }}
                  slotProps={{
                    backdrop: {
                      timeout: 500,
                    },
                  }}
                >
                  {/* <Fade in={open}> */}
                  <SoftBox sx={style}>
                    <SoftBox>
                      <SoftBox className="icons-contained">
                        <input
                          className="icon-upload"
                          accept="image/*"
                          id="image-upload"
                          type="file"
                          onChange={handleImageUpload}
                        />
                        <label htmlFor="image-upload">
                          <IconButton color="white" component="span">
                            <EditRoundedIcon />
                          </IconButton>
                        </label>
                        <div className="previewContainer">
                          <img
                            className="previewImage"
                            src={
                              aboutUsData?.icon
                                ? process.env.REACT_APP_IMAGE_URL +
                                  "what_our_client_say/" +
                                  aboutUsData?.icon
                                : usePlaceholder
                            }
                            alt="Uploaded"
                          />
                        </div>
                      </SoftBox>
                      <SoftBox display="flex" flexDirection="column">
                        <SoftBox mb={2}>
                          <SoftTypography
                            component="label"
                            fontSize="16px"
                            variant="caption"
                            fontWeight="bold"
                          >
                       Client Name
                          </SoftTypography>
                          <SoftInput
                            type="text"
                            className="TextField"
                            placeholder=""
                            name="title"
                            value={aboutUsData.title}
                            onChange={(e) => handleChange(e)}
                          />
                          {validationErrors.title && (
                            <SoftTypography color="error" variant="caption">
                              Client Name is required
                            </SoftTypography>
                          )}
                        </SoftBox>
                        <SoftBox mb={2}>
                          <SoftTypography
                            component="label"
                            fontSize="16px"
                            variant="caption"
                            fontWeight="bold"
                          >
                            Designation
                          </SoftTypography>
                          <SoftInput
                            type="text"
                            className="TextField"
                            placeholder=""
                            name="designation"
                            value={aboutUsData.designation}
                            onChange={(e) => handleChange(e)}
                          />
                          {validationErrors.designation && (
                            <SoftTypography color="error" variant="caption">
                              Designation is required
                            </SoftTypography>
                          )}
                        </SoftBox>
                        <SoftBox mb={2} display="flex" flexDirection="column">
                          <SoftTypography
                            component="label"
                            fontSize="16px"
                            mb={1}
                            variant="caption"
                            fontWeight="bold"
                          >
                            Description
                          </SoftTypography>
                          <TextareaAutosize
                            className="Textarea"
                            minRows={6}
                            p={2}
                            name="description"
                            value={aboutUsData.description}
                            onChange={(e) => handleChange(e)}
                          />
                          {validationErrors.description && (
                            <SoftTypography color="error" variant="caption">
                              Description is required
                            </SoftTypography>
                          )}
                        </SoftBox>
                        <Button
                          variant="contained"
                          onClick={() => {
                            handleEdit(aboutUsData, "what_our_client_say");
                          }}
                          color="primary"
                          style={{ color: "white" }}
                          fontWeight="medium"
                        >
                          Save
                        </Button>
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>

                  {/* </Fade> */}
                </Modal>
              </SoftBox>
              <SoftBox
                className="TableContainer"
                sx={{
                  "& .MuiTableRow-root:not(:last-child)": {
                    "& td": {
                      borderBottom: ({
                        borders: { borderWidth, borderColor },
                      }) => `${borderWidth[1]} solid ${borderColor}`,
                    },
                  },
                }}
              >
                <Table
                  columns={column2}
                  rows={row2}
                  className="subtableContainer"
                />
              </SoftBox>
            </Card>
          </SoftBox>
          <SoftBox mb={3}>
            <Card>
              <SoftBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={3}
              >
                <SoftTypography variant="h6">Our services</SoftTypography>
                <Button
                  variant="contained"
                  onClick={() => {
                    handleOpen1();
                  }}
                  color="primary"
                  style={{ color: "white" }}
                  fontWeight="medium"
                >
                  Add New List
                </Button>

                {/* -------------------add    Our services----------------- */}
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  open={open1}
                  onClose={handleClose1}
                  closeAfterTransition
                  slots={{ backdrop: Backdrop }}
                  slotProps={{
                    backdrop: {
                      timeout: 500,
                    },
                  }}
                >
                  <SoftBox sx={style}>
                    <SoftBox>
                      <SoftBox className="icons-contained">
                        <input
                          className="icon-upload"
                          accept="image/*"
                          id="image-upload"
                          type="file"
                          onChange={handleImageUpload}
                        />
                        <label htmlFor="image-upload">
                          <IconButton color="white" component="span">
                            <EditRoundedIcon />
                          </IconButton>
                        </label>
                        <div className="previewContainer">
                          <img
                            className="previewImage"
                            src={
                              aboutUsData?.icon
                                ? process.env.REACT_APP_IMAGE_URL +
                                  "our_services/" +
                                  aboutUsData?.icon
                                : imagePlaceholder
                            }
                            alt="Uploaded"
                          />
                        </div>
                      </SoftBox>
                      <SoftBox display="flex" flexDirection="column">
                        <SoftBox mb={2}>
                          <SoftTypography
                            component="label"
                            fontSize="16px"
                            variant="caption"
                            fontWeight="bold"
                          >
                            Title
                          </SoftTypography>
                          <SoftInput
                            type="text"
                            className="TextField"
                            placeholder=""
                            name="title"
                            value={aboutUsData.title}
                            onChange={(e) => handleChange(e)}
                          />
                          {validationErrors.title && (
                            <SoftTypography color="error" variant="caption">
                              Title is required
                            </SoftTypography>
                          )}
                        </SoftBox>
                        <SoftBox mb={2} display="flex" flexDirection="column">
                          <SoftTypography
                            component="label"
                            fontSize="16px"
                            mb={1}
                            variant="caption"
                            fontWeight="bold"
                          >
                            Description
                          </SoftTypography>
                          <TextareaAutosize
                            className="Textarea"
                            minRows={6}
                            p={2}
                            name="description"
                            value={aboutUsData.description}
                            onChange={(e) => handleChange(e)}
                          />
                          {validationErrors.description && (
                            <SoftTypography color="error" variant="caption">
                              Description is required
                            </SoftTypography>
                          )}
                        </SoftBox>
                        <Button
                          variant="contained"
                          onClick={() => {
                            addOtherAboutUs("our_services");
                          }}
                          color="primary"
                          style={{ color: "white" }}
                          fontWeight="medium"
                        >
                          Save
                        </Button>
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>
                </Modal>

                {/* -------------------view    Our services----------------- */}

                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  open={open1View}
                  onClose={handleClose1View}
                  closeAfterTransition
                  slots={{ backdrop: Backdrop }}
                  slotProps={{
                    backdrop: {
                      timeout: 500,
                    },
                  }}
                >
                  <SoftBox sx={style}>
                    <SoftBox>
                      <SoftBox className="icons-contained">
                        <input
                          className="icon-upload"
                          accept="image/*"
                          id="image-upload"
                        />
                        <label htmlFor="image-upload">
                          <IconButton color="white" component="span">
                            <EditRoundedIcon />
                          </IconButton>
                        </label>
                        <div className="previewContainer">
                          <img
                            className="previewImage"
                            src={
                              aboutUsData?.icon
                                ? process.env.REACT_APP_IMAGE_URL +
                                  "our_services/" +
                                  aboutUsData?.icon
                                : imagePlaceholder
                            }
                            alt="Uploaded"
                          />
                        </div>
                      </SoftBox>
                      <SoftBox display="flex" flexDirection="column">
                        <SoftBox mb={2}>
                          <SoftTypography
                            component="label"
                            fontSize="16px"
                            variant="caption"
                            fontWeight="bold"
                          >
                            First Title
                          </SoftTypography>
                          <SoftInput
                            type="text"
                            className="TextField"
                            placeholder=""
                            name="title"
                            value={aboutUsData.title}
                          />
                        </SoftBox>
                        <SoftBox mb={2} display="flex" flexDirection="column">
                          <SoftTypography
                            component="label"
                            fontSize="16px"
                            mb={1}
                            variant="caption"
                            fontWeight="bold"
                          >
                            First Paragraph
                          </SoftTypography>
                          <TextareaAutosize
                            className="Textarea"
                            minRows={6}
                            p={2}
                            name="description"
                            value={aboutUsData.description}
                          />
                        </SoftBox>
                        {/* <Button variant="contained" onClick={() => {handleEdit(aboutUsData,"our_services")}} color="primary" style={{ color: "white" }} fontWeight="medium">
                          Save
                        </Button> */}
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>
                </Modal>

                {/* -------------------edit    Our services----------------- */}
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  open={open1Edit}
                  onClose={handleClose1Edit}
                  closeAfterTransition
                  slots={{ backdrop: Backdrop }}
                  slotProps={{
                    backdrop: {
                      timeout: 500,
                    },
                  }}
                >
                  <SoftBox sx={style}>
                    <SoftBox>
                      <SoftBox className="icons-contained">
                        <input
                          className="icon-upload"
                          accept="image/*"
                          id="image-upload"
                          type="file"
                          onChange={handleImageUpload}
                        />
                        <label htmlFor="image-upload">
                          <IconButton color="white" component="span">
                            <EditRoundedIcon />
                          </IconButton>
                        </label>
                        <div className="previewContainer">
                          <img
                            className="previewImage"
                            src={
                              aboutUsData?.icon
                                ? process.env.REACT_APP_IMAGE_URL +
                                  "our_services/" +
                                  aboutUsData?.icon
                                : imagePlaceholder
                            }
                            alt="Uploaded"
                          />
                        </div>
                      </SoftBox>
                      <SoftBox display="flex" flexDirection="column">
                        <SoftBox mb={2}>
                          <SoftTypography
                            component="label"
                            fontSize="16px"
                            variant="caption"
                            fontWeight="bold"
                          >
                            Title
                          </SoftTypography>
                          <SoftInput
                            type="text"
                            className="TextField"
                            placeholder=""
                            name="title"
                            value={aboutUsData.title}
                            onChange={(e) => handleChange(e)}
                          />
                          {validationErrors.title && (
                            <SoftTypography color="error" variant="caption">
                              Title is required
                            </SoftTypography>
                          )}
                        </SoftBox>
                        <SoftBox mb={2} display="flex" flexDirection="column">
                          <SoftTypography
                            component="label"
                            fontSize="16px"
                            mb={1}
                            variant="caption"
                            fontWeight="bold"
                          >
                            Description
                          </SoftTypography>
                          <TextareaAutosize
                            className="Textarea"
                            minRows={6}
                            p={2}
                            name="description"
                            value={aboutUsData.description}
                            onChange={(e) => handleChange(e)}
                          />
                          {validationErrors.description && (
                            <SoftTypography color="error" variant="caption">
                              Description is required
                            </SoftTypography>
                          )}
                        </SoftBox>
                        <Button
                          variant="contained"
                          onClick={() => {
                            handleEdit(aboutUsData, "our_services");
                          }}
                          color="primary"
                          style={{ color: "white" }}
                          fontWeight="medium"
                        >
                          Save
                        </Button>
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>
                </Modal>
              </SoftBox>
              <SoftBox
                className="TableContainer"
                sx={{
                  "& .MuiTableRow-root:not(:last-child)": {
                    "& td": {
                      borderBottom: ({
                        borders: { borderWidth, borderColor },
                      }) => `${borderWidth[1]} solid ${borderColor}`,
                    },
                  },
                }}
              >
                <Table
                  columns={column3}
                  rows={row3}
                  className="subtableContainer"
                />
              </SoftBox>
            </Card>
          </SoftBox>
        </SoftBox>

        <SoftBox display="flex" justifyContent="center">
          <Button
            onClick={() => {
              addOtherAboutUs("about_us");
            }}
            variant="contained"
            color="primary"
            style={{ color: "white" }}
            fontWeight="medium"
          >
            Save
          </Button>
        </SoftBox>
      </SoftBox>

      <DeleteModal 
        show  ={showDeleteModal}
        handleConfirm = {deleteOtherAboutUs}
        handleClose ={handleDeleteClose}
        
      />

      <Footer />
    </DashboardLayout>
  );
}

export default AboutUs;
