/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Switch from "@mui/material/Switch";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
// Images
import curved9 from "assets/images/curved-images/curved-6.jpg";
import mainlogo from "assets/images/main-logo.png";

import { addFCMToken, loginservice } from "../../../util/httpService";
import { forgotPasswordservice } from "../../../util/httpService";

import { useNavigate } from "react-router-dom";
import SoftAlert from "components/SoftAlert/index.js";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

function SignIn() {
  const [rememberMe, setRememberMe] = useState(true);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);

  const emailRegx = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
  const passRegx = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/;

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [formValidation, setValidation] = useState({
    emailValidation: true,
    passwordValidation: true,
  });

  const [forgotPassword, setForgotPassword] = useState({
    email: "",
    forgotEmailValidator: true,
  });

  const [passShow1, setPassShow1] = useState(false);

  const navigate = useNavigate();

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
    if (event.target.name === "email") {
      setValidation({
        ...formValidation,
        emailValidation: event.target.value.length === 0 ? false : true,
      });
    }

    if (event.target.name === "password") {
      setValidation({
        ...formValidation,
        passwordValidation: event.target.value.length === 0 ? false : true,
      });
      console.log("password 2", formValidation);
    }
  };

  const forgotPasswordFunc = async () => {
    console.log(forgotPassword);
    if (
      forgotPassword.forgotEmailValidator === true &&
      forgotPassword.email.length !== 0
    ) {
      const data = { email: forgotPassword.email };
      console.log("data", data, forgotPassword);
      try {
        const resp = await forgotPasswordservice(data);
        console.log("resp", resp);
      } catch (err) {}
    } else {
      setValidation({ ...forgotPassword, forgotEmailValidator: false });
    }
  };

  const submit = async () => {
    console.log(formData);
    if (formData.password.length !== 0 && formData.email.length !== 0) {
      const data = formData;
      try {
        const resp = await loginservice(data);

        localStorage.setItem("user_details", JSON.stringify(resp.data.user));
        localStorage.setItem("token", resp.data.token);

        localStorage.setItem("remember_me", rememberMe === true ? "yes" : "no");
        const timestamp = new Date().getTime();
        const randomValue = Math.random().toString(36).substring(7);
        const deviceId = `${timestamp}_${randomValue}`;
        if (localStorage.getItem("DeviceToken")) {
          console.log("true");
          let payload = {
            device_id: deviceId,
            token: localStorage.getItem("DeviceToken"),
            device_type: "web",
            email: formData.email,
          };
          addFCMToken(payload);
        }
        navigate("/dashboard");
      } catch (err) {}
    } else {
      setValidation({
        emailValidation:
          formData.email.length === 0 || !emailRegx.test(formData.email)
            ? false
            : true,
        passwordValidation:
          formData.password.length === 0 || !passRegx.test(formData.password)
            ? false
            : true,
      });
    }
  };

  return (
    <>
      <CoverLayout
        // title='Welcome back'
        // description='Enter your email and password to sign in'
        image={curved9}
      >
        <SoftBox className="mainlogo">
          <img src={mainlogo} alt="mainlogo" style={{ width: "176px" }} />
        </SoftBox>
        <SoftBox>
          <SoftTypography variant="h2" pt={1} style={{ color: "#202090" }}>
            Welcome back
          </SoftTypography>
          <SoftTypography
            fontSize="14px"
            variant="caption"
            fontWeight="bold"
            pt={1}
          >
            Enter your email and password to sign in
          </SoftTypography>
        </SoftBox>
        <SoftBox component="form" role="form" onSubmit={submit}>
          <SoftBox mb={2}>
            <SoftBox mb={1} ml={0.5}>
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
              >
                Email
              </SoftTypography>
            </SoftBox>
            <SoftInput
              type="email"
              placeholder="Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
            {formValidation.emailValidation === false ? (
              <div className="validation_message">Email is required</div>
            ) : (
              <></>
            )}
          </SoftBox>
          <SoftBox mb={2}>
            <SoftBox mb={1} ml={0.5}>
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
              >
                Password
              </SoftTypography>
            </SoftBox>
            <SoftBox className="passEye">
              {passShow1 ? (
                <VisibilityIcon onClick={() => setPassShow1((prev) => !prev)} />
              ) : (
                <VisibilityOffIcon
                  onClick={() => setPassShow1((prev) => !prev)}
                />
              )}

              <SoftInput
                type={passShow1 ? "text" : "password"}
                placeholder="Password"
                name="password"
                value={formData.password}
                onChange={handleChange}
              />
              {formValidation.passwordValidation === false ? (
                <div className="validation_message">Password is required</div>
              ) : (
                <></>
              )}
            </SoftBox>
          </SoftBox>
          <SoftBox display="flex" alignItems="center" className="pass-flex">
            <SoftBox display="flex" alignItems="center">
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <SoftTypography
                variant="button"
                fontWeight="regular"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none" }}
              >
                &nbsp;&nbsp;Remember me
              </SoftTypography>
            </SoftBox>
            <SoftTypography variant="button" fontWeight="regular">
              <button
                className="link-col"
                onClick={() => {
                  setOpen(true);
                }}
              >
                Forgot Password?
              </button>
            </SoftTypography>
          </SoftBox>
          <SoftBox mt={4} mb={1}>
            {/* <Link to="/dashboard"> */}
            <SoftButton
              variant="gradient"
              className="btn-theme"
              fullWidth
              onClick={submit}
            >
              sign in
            </SoftButton>
            {/* </Link> */}
          </SoftBox>
        </SoftBox>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <SoftBox sx={style} className="main-modal">
            <SoftTypography
              className="sign-head for-pass"
              variant="h3"
              fontWeight="bold"
            >
              Forgot Password?
            </SoftTypography>
            <SoftTypography
              variant="body2"
              fontWeight="regular"
              color="text"
              className="for-pass"
            >
              Enter email to get OTP
            </SoftTypography>
            <SoftBox component="form" role="form">
              <SoftBox mb={2}>
                <SoftBox mb={1} ml={0.5}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Email
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  type="email"
                  placeholder="Email"
                  onChange={(e) =>
                    setForgotPassword({
                      email: e.target.value,
                      forgotEmailValidator: emailRegx.test(e.target.value),
                    })
                  }
                />
                {forgotPassword.forgotEmailValidator === false ? (
                  <div className="validation_message">
                    Please Enter a Valid Email Address
                  </div>
                ) : (
                  ""
                )}
              </SoftBox>
              <SoftBox mt={4} mb={1}>
                {/* <Link to="/reset-password"> */}
                <SoftButton
                  variant="gradient"
                  className="btn-theme"
                  fullWidth
                  onClick={forgotPasswordFunc}
                >
                  Forgot Password
                </SoftButton>
                {/* </Link> */}
              </SoftBox>
            </SoftBox>
            <SoftBox
              className="cross-icn"
              onClick={() => {
                setOpen(false);
                setForgotPassword({
                  email: "",
                  forgotEmailValidator: true,
                });
              }}
            >
              <CloseIcon />
            </SoftBox>
          </SoftBox>
        </Modal>
      </CoverLayout>
      {/* <div  style={{
      position:"absolute",
      top:0,
      width:"100%"
    }}>
  <SoftAlert dismissible={true}>asdasdasd</SoftAlert>
    </div> */}
    </>
  );
}

export default SignIn;
