/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

function ReportManagement() {

  const baseUrl = process.env.REACT_APP_SERVER_URL

  const dawnloadCustomerList = ()=>{
 
    window.location.href = `${baseUrl}admin/customers-list`
  
  }

  const dawnloadInvoiceList = ()=>{
    window.location.href = `${baseUrl}admin/invoices-list`
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftTypography variant="h6">General Reports</SoftTypography>
              {/* <Link to="">
                <Button variant="contained" color="primary" style={{ color: "white" }} fontWeight="medium" >
                  Add FAQ
                </Button>
              </Link> */}
            </SoftBox>
            <SoftBox className="TableContainer"
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <SoftBox className="" p={3}>
                <SoftBox className="report_wrap">
                  <SoftTypography variant="p">Customer List</SoftTypography>
                  <CloudDownloadIcon  className="cursor-pointer" onClick ={dawnloadCustomerList}/>
                   
                </SoftBox>
                {/* <SoftBox className="report_wrap">
                  <SoftTypography variant="p">List of My Repairs</SoftTypography>
                  <CloudDownloadIcon />
                   
                </SoftBox> */}
                <SoftBox className="report_wrap">
                  <SoftTypography variant="p">List of Invoices</SoftTypography>
                  <CloudDownloadIcon className="cursor-pointer" onClick ={dawnloadInvoiceList} />
                   
                </SoftBox>
                {/* <SoftBox className="report_wrap">
                  <SoftTypography variant="p">List Of Invoices In Accounting Format</SoftTypography>
                  <CloudDownloadIcon />
                   
                </SoftBox>
                <SoftBox className="report_wrap">
                  <SoftTypography variant="p">List of Invoices Pending Payment</SoftTypography>
                  <CloudDownloadIcon />
                   
                </SoftBox>
                <SoftBox className="report_wrap">
                  <SoftTypography variant="p">List Of My Holdings</SoftTypography>
                  <CloudDownloadIcon />
                   
                </SoftBox> */}
              </SoftBox>


            </SoftBox>
          </Card>
        </SoftBox>

      </SoftBox>
      <Footer />

    </DashboardLayout>
  );
}

export default ReportManagement;


