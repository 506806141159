/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import * as React from 'react';
import { useState, Component } from "react";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import SoftBox from "components/SoftBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import Button from '@mui/material/Button';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import { TextareaAutosize } from '@mui/material';
import { universalFun } from 'util/httpService';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';





function AddSubscription() {
  const navigate = useNavigate();
  const [data, setData] = useState({
    plan_name: "",
    price: "",
    description: "",
    features: "",
    type: "create"
  })

  const [dataValidation, setDataValidation] = useState({
    plan_name: true,
    price: true,
    description: true,
    features: true,
  
  })

  // Handle Change-
  const handleChange = (field, e, editor) => {
    if(field !== "editor"){
      setData({...data, [e.target.name]: e.target.value})
      ValidationCheck(e.target.name)
    }
    else{
      setData({...data, "features": editor.getData()})
      ValidationCheck("features")
    }
   
  }

  function ValidationCheck(name){
    switch(name){
      case 'plan_name' :
        setDataValidation((dataValidation)=>({...dataValidation,'plan_name':(
          data[name].length === 0) ? false : true})) 
      break;
      case 'price' :
        setDataValidation((dataValidation)=>({...dataValidation,'price':(data[name].length === 0) ? false : true}))  
      break;  
      case 'description' : 
      setDataValidation((dataValidation)=>({...dataValidation,'description':(
        data[name].length === 0 )  ? false : true}))
      break;
      case 'features' :
        setDataValidation((dataValidation)=>({...dataValidation,'features':(data[name].length === 0 ) ? false : true}))
      break;
      default :   
    }
   }

   function checkFuncForValues(obj){
    const keys = Object.keys(obj);
        if(keys.find((ele) => obj[ele].length === 0) === undefined){
          return true;
      }
      else{
        for(let i = 0; i <= keys.length; i++){
          ValidationCheck(keys[i]);
        }
       return false;
      }
   }
  
   function checkFuncForValidation(obj){
    const keys = Object.keys(obj);
         if(keys.find((ele) => obj[ele] === false) === undefined){
             return true;
         }
         else{
            for(let i = 0; i <= keys.length; i++){
              ValidationCheck(keys[i]);
            }
          return false;
         }
  
   }

  // Handle Submit-
  const handleSubmit = async () => {
    if(checkFuncForValues(data) === true){
      if(checkFuncForValidation(dataValidation) === true){
        try{
          const result = await universalFun("POST", "subscription", data);
          if(result?.data?.response?.success){
            toast.success("Added Successfully")
            navigate("/Subscription")
          }
        }
        catch(error){
          console.log(error)
        }
      }
    }
  }

  const editorConfig = {
    toolbar: {
      items: [
        'document',
        'clipboard',
        'editing',
        'forms',
        '/',
        'basicstyles',
        'paragraph',
        'links',
        'insert',
        '/',
        'styles',
        'colors',
        'tools',
        'others',
        'about',
      ],
    },

  };

  // CKEDITOR.editorConfig = function( config ) {
  //   config.toolbarGroups = [
  //     { name: 'document', groups: [ 'mode', 'document', 'doctools' ] },
  //     { name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
  //     { name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },
  //     { name: 'forms', groups: [ 'forms' ] },
  //     '/',
  //     { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
  //     { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi', 'paragraph' ] },
  //     { name: 'links', groups: [ 'links' ] },
  //     { name: 'insert', groups: [ 'insert' ] },
  //     '/',
  //     { name: 'styles', groups: [ 'styles' ] },
  //     { name: 'colors', groups: [ 'colors' ] },
  //     { name: 'tools', groups: [ 'tools' ] },
  //     { name: 'others', groups: [ 'others' ] },
  //     { name: 'about', groups: [ 'about' ] }
  //   ];
  // };


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox mt={5}>
        <Card className="card crd_new">
          <SoftBox mb={2}>
            <SoftBox className="prce_subsptns" mb={2}>
              <SoftBox className="flex_basis" mr={3} mb={2}>
                <SoftBox mb={0} ml={0.5}>
                  <SoftTypography component='label' variant='caption' fontWeight='bold'>
                    Plan Name
                  </SoftTypography>
                </SoftBox>
                <SoftInput 
                  type='text' 
                  placeholder="Basic" 
                  name="plan_name"
                  value={data.plan_name}
                  onChange={(e) => handleChange("plan_name", e)}
                  onBlur={(e) => handleChange("plan_name", e)}
                />
                {dataValidation.plan_name === false ? (<div className="validation_message">Plan Name is required</div>) : <></>}
              </SoftBox>
              <SoftBox className="flex_basis" mb={2}>
                <SoftBox mb={0} ml={0.5}>
                  <SoftTypography component='label' variant='caption' fontWeight='bold'>
                    Price
                  </SoftTypography>
                </SoftBox>
                <SoftInput 
                  type='number' 
                  placeholder="€29" 
                  name="price"
                  value={data.price}
                  onChange={(e) => handleChange("price", e)}
                  onBlur={(e) => handleChange("price", e)}
                />
                {dataValidation.price === false ? (<div className="validation_message">Price is required</div>) : <></>}
              </SoftBox>

            </SoftBox>
            <SoftBox mb={2} display="flex" flexDirection="column">
              <SoftTypography component='label' fontSize="13px" mb={1} variant='caption' fontWeight='bold'>
                Description
              </SoftTypography>
              <TextareaAutosize 
                type="text" 
                className="Textarea" 
                placeholder="Enter a description..." minRows={4} p={2} 
                name="description"
                value={data.description}
                onChange={(e) => handleChange("description", e)}
                onBlur={(e) => handleChange("description", e)}
              />
               {dataValidation.description === false ? (<div className="validation_message">Description is required</div>) : <></>}
            </SoftBox>
            <div className="App">
              <h4>Features</h4>
              <CKEditor
                editor={ClassicEditor}
                placeholder="Features"
                // config={editorConfig}
                
                
                name = "features"
                data=""
                onReady={editor => {
                  // You can store the "editor" and use when it is needed.
                  console.log('Editor is ready to use!', editor);
                }}
                onChange={(e, editor) => handleChange("editor", e, editor)}
                onBlur={ (e, editor) => handleChange("editor", e, editor) }
               
                // onFocus={(event, editor) => {
                //   console.log('Focus.', editor);
                // }}
                link
              />
              {dataValidation.features === false ? (<div className="validation_message">Features is required</div>) : <></>}
            </div>
          </SoftBox>
        </Card>



        <SoftBox display="flex" className="gap-3 pb-4" justifyContent="center" >

        <Button onClick={()=>navigate(-1)} variant="contained" color="primary" style={{ color: "white" }} fontWeight="medium">
            Back
          </Button>

          <Button onClick={handleSubmit} variant="contained" color="primary" style={{ color: "white" }} fontWeight="medium">
            Save
          </Button>
        </SoftBox>
      </SoftBox>


      <Footer />
    </DashboardLayout>
  );
}

export default AddSubscription;
