import { formatDistanceToNow } from "date-fns";
import { enUS, id } from "date-fns/locale";
export function UsTimeConverter(date) {
    let dateData = new Date(date).toLocaleString(
        "en-US",
          {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
          }
      );
    return dateData  
  }

 export const getDate = (createdAt) => {
    const originalDate = new Date(createdAt);

    // Options for formatting the date
    const options = {  day: "2-digit" , month: "2-digit", year: "2-digit" };

    // Format the date
    return originalDate.toLocaleDateString("en-US", options);
  };

// *********************get date format 'March 1, 2020' ***************
  export const getDatefullmonthformat = (createdAt) => {
    const originalDate = new Date(createdAt);

    // Options for formatting the date
    const options = {  month: "long", day: "numeric", year: "numeric"  };

    // Format the date
    return originalDate.toLocaleDateString("en-US", options);
  };

  export const getNofifyTime = (createdAt) => {
    const distance = formatDistanceToNow(new Date(createdAt), { addSuffix: true, locale: enUS });
    return distance;
  };

  // ***************send year array*****************************
  export const getYearArray =()=>{
    const currentYear = new Date().getFullYear();
    let yearArray = [];
    for(let i=0; i<5; i++){
      yearArray.push(currentYear - i)
    }
    return yearArray;
  }

  export const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    // boxShadow: 24,
    p: 4,
    borderRadius: "10px",
  };
  