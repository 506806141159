/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMemo, useState } from "react";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-chartjs-2 components
import { Bar } from "react-chartjs-2";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import BarReportsChartItem from "examples/Charts/BarCharts/ReportsBarChart/ReportsBarChartItem";

// ReportsBarChart configurations
import configs from "examples/Charts/BarCharts/ReportsBarChart/configs";

//select
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SoftButton from "components/SoftButton";
import { getYearArray } from "util/timeConverter";

function ReportsBarChart({ color ,title, description, chart, items, yearArray , year ,setYear}) {
  const { data, options } = configs(chart.labels || [], chart.datasets || {});

  // const yearArray = getYearArray();

  // const [year, setYear] = useState(yearArray?.[0]);

  const renderItems = items.map(({ icon, label, progress }) => (
    <Grid item xs={6} sm={3} key={label}>
      <BarReportsChartItem
        color={color}
        icon={{ color: icon.color, component: icon.component }}
        label={label}
        progress={{
          content: progress.content,
          percentage: progress.percentage,
        }}
      />
    </Grid>
  ));

  // custome style
  const styles = {
    icon: {
      width: "1.3rem",
    },
    labelforinput: {
      position: "absolute",
      right: "0px",
      bottom: "12px",
      background: "lightblue",
      width: "30px",
      height: "30px",
      borderRadius: "50%",
      lineHeight: "-1",
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    previewImage: {
      width: "120px",
      height: "120px",
      borderRadius: "50%",
    },

    selectContainer: {
      position: "relative",
      width: "110px",
      // marginLeft:" 320px",
    },

    chevIocn: {
      position: "absolute",
      zIndex: "999",
      right: "10px",
      top: "10px",
    },
    select:{
      backgroundColor: "#222640",
      color: "white" ,
      height:"36px"
    }
  };

  return (
    <Card sx={{ height: "100%" }}>
      <SoftBox padding="1rem">
        {useMemo(
          () => (
            <SoftBox
              variant="gradient"
              bgColor={color}
              borderRadius="lg"
              py={2}
              pr={0.5}
              mb={3}
              height="12.5rem"
            >
          <SoftBox className="d-flex flex-row-reverse me-2"> 
             <FormControl style={styles.selectContainer} className="bar-chart-container" fullWidth>
              <ArrowDropDownIcon style={styles.chevIocn} />
              <Select
                value={year}
                onChange={(e) => setYear(e.target.value)}
                name="chart"
                // style={styles.select}
                className="select-bar-chart"
              >
                {yearArray?.map((year, i) => (
                  <MenuItem value={year} key={i}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            </SoftBox>
              <Bar data={data} options={options} />
            </SoftBox>
          ),
          [chart, color]
        )}
        <SoftBox px={1}>
          <SoftBox className="d-flex justify-content-between">
            <SoftBox mb={2}>
              <SoftTypography
                variant="h6"
                fontWeight="medium"
                textTransform="capitalize"
              >
                {title}
              </SoftTypography>
              <SoftTypography
                component="div"
                variant="button"
                color="text"
                fontWeight="regular"
              >
                {description}
              </SoftTypography>
            </SoftBox>

            {/* <FormControl style={styles.selectContainer} fullWidth>
              <ArrowDropDownIcon style={styles.chevIocn} />
              <Select
                value={year}
                onChange={(e) => setYear(e.target.value)}
                name="chart"
              >
                {yearArray?.map((year, i) => (
                  <MenuItem value={year} key={i}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
          </SoftBox>
          <SoftBox py={1} px={0.5}>
            <Grid container spacing={2}>
              {renderItems}
            </Grid>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

// Setting default values for the props of ReportsBarChart
ReportsBarChart.defaultProps = {
  color: "dark",
  description: "",
  items: [],
};

// Typechecking props for the ReportsBarChart
ReportsBarChart.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  chart: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.array, PropTypes.object])
  ).isRequired,
  items: PropTypes.arrayOf(PropTypes.object),
};

export default ReportsBarChart;
