import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';

const ConfirmPopover = ({ buttonText, confirmText , reqId , onConfirm , style, }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleConfirm = () => {
    onConfirm(reqId);
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <Button variant="contained" 
      style = {style}
      color="primary" size="small"
      autoFocus={false}
       onClick={handleClick}>
        {buttonText}
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        
      >
        <Typography sx={{ p: 2 ,fontSize: '0.8rem', fontWeight: 'bold'  }}>
          {confirmText}
          <Box sx={{textAlign: "center"}}>
          <Button color="primary" size="small" onClick={handleConfirm}>
            Confirm
          </Button>
          <Button color="secondary" size="small" onClick={handleClose}>
            Cancel
          </Button>

          </Box>
          
        </Typography>
      </Popover>
    </>
  );
};

export default ConfirmPopover


