import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import SoftInput from "components/SoftInput";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import * as React from "react";
import {useState} from "react";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { Link } from "react-router-dom";
import carImage from "assets/images/car.png";
import SoftAvatar from "components/SoftAvatar";

import Modal from "@mui/material/Modal";
import { Dropdown } from "react-bootstrap";

import { useDropzone } from "react-dropzone";
import { InsuranceModal } from "components/InsuranceManagement";
import { toast } from "react-toastify";
import axios from "axios";

//mui material
import { TablePagination } from "@mui/material";
import Switch from "@mui/material/Switch";

//customise funcs
import { universalFun } from "util/httpService";
import { getDate } from "util/timeConverter";

//icons
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import DeleteModal from "components/DeleteModal";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

function InsuranceManagement() {

  const timerRef = React.useRef()

  const [modalName, setModalName] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setInsuranceDetails({company_name:""})
    setOpen(true);
  };
  const handleClose = () => setOpen(false);


  const [open_2, setOpen_2] = React.useState(false);
  const handleOpen_2 = () => {
    setOpen_2(true);
    setModalName("view");
  };
  const handleClose_2 = () => setOpen_2(false);

  const [open_3, setOpen_3] = React.useState(false);
  const handleOpen_3 = () => {
    setOpen_3(true);
    setModalName("edit");
  };
  const handleClose_3 = () => setOpen_3(false);

  const [open_4, setOpen_4] = React.useState(false);
  const handleOpen_4 = () => setOpen_4(true);
  const handleClose_4 = () => setOpen_4(false);


  const [insuranceList , setInsuranceList] = useState([])
  const [insuranceDetails ,setInsuranceDetails ] = useState({})
  const [deleteId ,setDeleteId] = useState("")

    //status states
    const [userStatusObj, setStatusObj] = React.useState([])
    const [previousState, setPreviousState] = React.useState([])

  //pagination
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [listCount, setListCount] = useState(100);

  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("");
  const [status, setStatus] = useState("");



  const handlePageChange = (params, value) => {
    // 'value' represents the selected page
    setCurrentPage(value);
  
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };
  

  //---------------------------------------------------------


  //editing insurance
  const handleInsuranceEdit = async()=>{
    
    const body={
      company_name : insuranceDetails.company_name,
      id : insuranceDetails._id
    }
    try{
      const resp = await universalFun("patch","editinsurence",body)
      // console.log(resp)
      toast.success("Insurance Updated")
      getInsuranceListing()
      handleClose_3()
    }
      catch(error){
        console.log(error)
      }
    
  }

  function Author({ image, name, email }) {
    return (
      <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
        <SoftBox mr={2}>
          <SoftAvatar src={image} alt={name} size="sm" variant="rounded" />
        </SoftBox>
      </SoftBox>
    );
  }

  const columns = [
    { field: "space", headerName: "", width: 20 },
    { field: "index", headerName: "#", width: 100 },
 
    { field: "company_name", headerName: "Insurance Name", width: 400}, 
  //   { field: "status", headerName: "Status", width: 150 ,
  //   renderCell : (params)=> (
  //    <SoftBox>
  //    <Switch
  //     checked={userStatusObj[params.row?._id]} 
  //     onChange={(e) => handleStatusChange(e, params.row?._id)}
         
  //    />
  //    </SoftBox>
  //   )
  //  },
    
    { field: "createdAt", headerName: "Created At", width: 200,
    renderCell : (params)=> getDate(params?.row?.createdAt)
  },
    {
      field: "Action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => (
        <>
          <Link
            to=""
            onClick={(e) => {
              e.stopPropagation();
              setOpen_2(true);
            
              setInsuranceDetails(params?.row)
              // getBrand(params?.row?._id);
            }}
            className="actionIcon"
            style={{ color: "#202090", fontSize: "20px", paddingLeft: "5px" }}
          >
            <IconButton aria-label="view">
              <RemoveRedEyeIcon />
            </IconButton>
          </Link>
          <Link
            to=""
            className="actionIcon"
            onClick={(e) => {
              e.stopPropagation();
              setOpen_3(true);
              setInsuranceDetails(params?.row)
              
            }}
            style={{ color: "#202090", fontSize: "20px", paddingLeft: "5px" }}
          >
            <IconButton aria-label="edit">
              <EditRoundedIcon />
            </IconButton>
          </Link>
          <Link
            to="" // Provide the correct route or path
            className="actionIcon"
            style={{ color: "#202090", fontSize: "20px", paddingLeft: "5px" }}
            onClick={(e)=>{
              e.stopPropagation();
              setOpen_4(true);
              setDeleteId(params?.row?._id)
            }}
          >
            <IconButton aria-label="delete">
              <DeleteIcon />
            </IconButton>
          </Link>
        </>     
      ),
    },
  ];

  const dataWithIndex = insuranceList?.map((row, index) => ({
    ...row,
    index: index + 1,
  }));

  const getInsuranceListing = async (debouncSearch) => {
    const params ={
      limit : rowsPerPage,
      page : currentPage + 1,
      sort  : sort,
      status : status,
      search : debouncSearch || ""
    }
    try {
      const res = await universalFun("get", "insurance",null,params);  
     console.log(res)
     setInsuranceList(res?.data?.data?.items)
     makeStatusList(res.data?.data?.items)
      // setCarsData(res.data.subcategory);
      setListCount(res?.data?.data?.totalItems)
    } catch (err) {
      console.log(err);
    }
  };

  React.useEffect(() => {
    getInsuranceListing();
  }, [currentPage ,rowsPerPage ,sort ,status]);

  // -----------------adding insuramce 
  const handleInsuranceAdd = async()=>{
    try {
      const resp = await universalFun("post","addinsurence",insuranceDetails)
      toast.success("Insurance Added")
      getInsuranceListing()
      handleClose();
    } catch (error) {
      console.log(error)
    }

  }

  // -----------------Deleting Insurance 
  const handleInsuranceDelete = async()=>{
  try {
    const resp = await universalFun("delete",`deleteinsurence/${deleteId}`)
      toast.success("Deleted Successfully")
      getInsuranceListing();
      handleClose_4()
  } catch (error) {
    console.log(error)
  }
  }

  // ----------------- debounce searching 
    //search deboucing

    const debounce = (func ,delay)=>{
      return (...args)=>{
       clearTimeout(timerRef.current)
       timerRef.current  = setTimeout(()=>{
        func(...args)
       },delay)
      }
    }
  
    const debounceSearch = debounce(getInsuranceListing ,500)
  
    const handleSearch = (e)=>{
      const value = e.target.value
      setSearch(value)
  
      debounceSearch(value)
    }

    //make status array 
    const makeStatusList = (userList) => {
      const newObj = {}
  
      userList.forEach((obj) => {
        newObj[obj._id] = obj.status === "active" ? true : false
      })
      setStatusObj(newObj)
      setPreviousState(newObj)
    }

      // --------------status change
  const handleStatusChange = async (e, id) => {
    console.log("ID-------",id)
        setStatusObj(prev => {
          setPreviousState(prev)
          return ({ ...prev, [id]: e.target.checked })
        })
    
        try {
          const resp = await universalFun("patch", `update-insurance-status/${id}`)
          console.log("Status Updated", resp)
          getInsuranceListing()
        } catch (error) {
          console.log(error)
          setStatusObj(previousState)
          getInsuranceListing()
        }
      }


      // data grid footer
      const [ columnVisible ,  setColoumsVisible] = useState({})
      const [hideDatagridFooter , setHideDatagridFooter] = useState(false)

      const handleColumnModelChange = (param)=>{
        //  console.log(param,"======")
          const hideFooter = columns.every((obj)=> {
            const field = obj?.field
           return  param?.[field] === false} )
           setHideDatagridFooter(hideFooter)
          setColoumsVisible(param)
        }

  return (
    
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card className="insurancePage">
            <SoftBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={3}
              className="car-management"
            >
              <SoftTypography variant="h6">Insurance List</SoftTypography>
              <SoftBox display="flex" alignItems="center" gap="1rem">
              <Dropdown className="fltrDrop" onSelect={(value) => setStatus(value)}>
                  <Dropdown.Toggle id="dropdown-button-dark-example1" variant="primary">
                    <FilterAltIcon />
                    {/* <FilterListIcon /> */}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item  className = {status === "" ? "active" :""} eventKey="">ALL..</Dropdown.Item>
                    <Dropdown.Item className = {status === "active" ? "active" :""} eventKey="active">Active</Dropdown.Item>
                    <Dropdown.Item className = {status === "inactive" ? "active" :""} eventKey="inactive">InActive</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

                <Dropdown className="fltrDrop" onSelect={(value) => setSort(value)}>
                  <Dropdown.Toggle id="dropdown-button-dark-example1" variant="primary">
                    {!sort ? <FilterListIcon /> :
                      <FilterListIcon style={{ transform: "rotate(180deg)" }} />}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item  className = {!sort ? "active" :""} eventKey="">Descending</Dropdown.Item>
                    <Dropdown.Item  className = {sort  ? "active" :""} eventKey="asc">Ascending</Dropdown.Item>

                  </Dropdown.Menu>
                </Dropdown>

                <SoftBox pr={1}>
                  <SoftInput
                    placeholder="Type here..."
                    icon={{ component: "search", direction: "left" }}
                    value={search}
                    onChange={handleSearch}
                  />
                </SoftBox>
                <div className="top-btn">
                <Button
                  variant="contained"
                  color="primary"
                  style={{ color: "white" }}
                  fontWeight="medium"
                  className="add-btn"
                  onClick={handleOpen}
                >
                  Add
                </Button>
              </div>
              </SoftBox>
             
            </SoftBox>

            <div style={{ height: 400, width: "100%" }}>
              <DataGrid
                rows={dataWithIndex?.length > 0 && dataWithIndex}
                columns={columns}
                getRowId={(row, index) => row._id}
                columnVisibilityModel={columnVisible}
                onColumnVisibilityModelChange={handleColumnModelChange}
              />
             
          
            </div>
            <div className="d-flex justify-content-between">
            <div className="pt-2">
            {
              hideDatagridFooter &&
            <Button  onClick={()=>{setColoumsVisible({}); setHideDatagridFooter(false) }}>Show All</Button>
            }
            </div>
            <TablePagination
                  component="div"
                  count={listCount}
                  page={currentPage}
                  onPageChange={handlePageChange}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  rowsPerPageOptions={[5,10, 15, 20, 25]}
                />
           </div>
          </Card>
          {/* <TablePagination
            component="div"
            count={userListCount}
            page={currentPage}
            onPageChange={handlePageChange}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10, 15, 20, 25]}
          /> */}
        </SoftBox>
      </SoftBox>

      {/* ------------------- Add Modal --------------- */}
      <InsuranceModal
        open={open}
        handleClose={handleClose}
        style={style}
        insuranceDetails ={insuranceDetails}
        setInsuranceDetails ={setInsuranceDetails}
        handleSave={handleInsuranceAdd}
        modalName= "add"
      />

      {/*---------------------- View Modal ------------------- */}
      <InsuranceModal
        open={open_2}
        handleClose={handleClose_2}
        style={style}
        insuranceDetails ={insuranceDetails}
        setInsuranceDetails ={setInsuranceDetails}
        modalName= "view"
      />

      {/* --------------------- Edit Modal ------------------------ */}
      <InsuranceModal
        open={open_3}
        handleClose={handleClose_3}
        style={style}
        insuranceDetails ={insuranceDetails}
        setInsuranceDetails ={setInsuranceDetails}
        handleSave={handleInsuranceEdit}
        modalName= "edit"
      />
     
      <Modal
        open={open_4}
        onClose={handleClose_4}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="delete-modal"
      >
        <Box sx={style}>
          <Typography className="modal-image">
            <div className="delete-message">
              Are you sure you want to delete ?
            </div>
          </Typography>
          <Typography
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              onClick={handleInsuranceDelete}
              color="primary"
              style={{ color: "white" }}
              fontWeight="medium"
            >
              Delete
            </Button>
          </Typography>
        </Box>
      </Modal>

      

      <Footer />
    </DashboardLayout>
  );
}

export default InsuranceManagement;
