/* eslint-disable react/prop-types */
// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import SoftBadge from "components/SoftBadge";
import Switch from "@mui/material/Switch";
import { useState } from "react";

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import team1 from "assets/images/team-1.jpg";

//logo
import logo from "assets/images/small-logos/logo-atlassian.svg"

//icons
import IconButton from '@mui/material/IconButton';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DeleteIcon from '@mui/icons-material/Delete'
import EditRoundedIcon from '@mui/icons-material/EditRounded';

import Modal from '@mui/material/Modal';

//input 

import TextareaAutosize from '@mui/base/TextareaAutosize';


//Links

import { Link } from "react-router-dom";



export function Author({ image, name, Designation }) {


  return (
    <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
      <SoftBox mr={2}>
        <SoftAvatar src={image} alt={name} size="sm" variant="rounded" />
      </SoftBox>
      <SoftBox display="flex" flexDirection="column">
        <SoftTypography variant="button" fontWeight="medium">
          {name}
        </SoftTypography>
        <SoftTypography variant="caption" color="secondary">
          {Designation}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
}




const whatClientsay = {


  columns: [
    { name: "client", align: "left" },
    { name: "Description", align: "left" },
    { name: "action", align: "center" },

  ],

  rows: [
    {
      "client": <Author image={team2} name="John Michael" Designation="Designation" />,

      "Description": (
        <SoftTypography className="textarea client-desc">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the </SoftTypography>

      ),

      "action": (
        <IconButton aria-label="action">
          <Link to="/" className="actionIcon">
            <RemoveRedEyeIcon />
          </Link>
          <Link to="/addUser" className="actionIcon" >
            <EditRoundedIcon />
          </Link>
          <Link to="/" className="actionIcon">
            <DeleteIcon />
          </Link>
        </IconButton>
      ),
    },
    {
      "client": <Author image={team3} name="Greek Michael" Designation="Designation"  />,


      "Description": (
        <SoftTypography className="textarea client-desc">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the </SoftTypography>

      ),

      "action": (
        <IconButton aria-label="action">
          <Link to="/" className="actionIcon">
            <RemoveRedEyeIcon />
          </Link>
          <Link to="/addUser" className="actionIcon" >
            <EditRoundedIcon />
          </Link>
          <Link to="/" className="actionIcon">
            <DeleteIcon />
          </Link>
        </IconButton>
      ),
    },
    {
      "client": <Author image={team4} name="Laurent Perrier " Designation="Designation"  />,
      "Description": (
        <SoftTypography className="textarea client-desc">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the </SoftTypography>

      ),

      "action": (
        <IconButton aria-label="action">
          <Link to="/" className="actionIcon">
            <RemoveRedEyeIcon />
          </Link>
          <Link to="/addUser" className="actionIcon" >
            <EditRoundedIcon />
          </Link>
          <Link to="/" className="actionIcon">
            <DeleteIcon />
          </Link>
        </IconButton>
      ),
    },
    {
      "client": <Author image={team1}  name="Alexa Liras " Designation="Designation"  />,

     
      "Description": (
        <SoftTypography className="textarea client-desc">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the </SoftTypography>

      ),

      "action": (
        <IconButton aria-label="action">
          <Link to="/" className="actionIcon">
            <RemoveRedEyeIcon />
          </Link>
          <Link to="/addUser" className="actionIcon" >
            <EditRoundedIcon />
          </Link>
          <Link to="/" className="actionIcon">
            <DeleteIcon />
          </Link>
        </IconButton>
      ),
    },
  ],
};

export default whatClientsay;
