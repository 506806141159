import React, { useEffect, useState } from 'react'

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

//mui imports
import { TablePagination } from "@mui/material";
import Table from "examples/Tables/Table";
import { getContactUsFunction } from 'util/httpService';
import { getDate } from 'util/timeConverter';
import Fade from '@mui/material/Fade';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import Button from '@mui/material/Button';


//icons
import IconButton from '@mui/material/IconButton';
// import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DeleteIcon from '@mui/icons-material/Delete'
import ReplyIcon from '@mui/icons-material/Reply';
import CloseIcon from "@mui/icons-material/Close";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";


import { Link } from 'react-router-dom';
import { universalFun } from 'util/httpService';
import { toast } from 'react-toastify';


const Queries = () => {

  const [ Queries , setQueries ] = useState()

  const [replayMsg , setReplayMsg] = useState('')
  const [replayId , setReplayId] = useState('')

  // *****************replay modal **************
  const [open, setOpen] = useState(false)
  const handleOpen1 = () => setOpen(true);
  const handleClose1 = () => {setOpen(false); setReplayMsg("")} 

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    // boxShadow: 24,
    p: 4,
    borderRadius: "10px",
  };

  const getContact = async () => {
  

    try{
      const result = await getContactUsFunction();
      console.log("result", result?.data?.data);
      setQueries(result?.data?.data?.item)
    }
    catch(error){
      console.log(error);
    }
  }

  useEffect(()=>{
    getContact()
  },[])

  const columns = [
    { name: "s.no.", align: "left" },
    { name: "name", align: "left" },
    { name: "email", align: "left" },
    { name: "phone no.", align: "left" },
    { name: "subject", align: "left" },
    { name: "status", align: "left" },
    { name: "created At", align: "left" },
    { name: "action", align: "center" },
  ]

  const rows = Queries?.map((el, index) => {
    return {
      "s.no.": <SoftTypography variant="caption" color="secondary" fontWeight="medium" className="rspnv_tbls">
        {index+1}
      </SoftTypography>,

      "name": <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        {el.first_name} {el.last_name}
      </SoftTypography>,

      "email": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          {el.email}
        </SoftTypography>
      ),
      "phone no.": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          {el.email}
        </SoftTypography>
      ),
      "subject": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          {el.subject}
        </SoftTypography>
      ),
      "status": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          {el.status}
        </SoftTypography>
      ),
      "created At": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          { getDate(el.createdAt) }
        </SoftTypography>
      ),
    
      "action": (
        <IconButton aria-label="action">
          <Link to={`/View-Contact-Us/${el._id}`} className="actionIcon" style={{fontSize:"20px"}}>
            <RemoveRedEyeIcon  />
          </Link>
          {/* <Link to="" className="actionIcon" onClick={() => {setOpen(true); setReplayId(el._id) }}>
            <ReplyIcon />
          </Link> */}
          {/* <Link to="/" className="actionIcon" >
            <EmailIcon />
          </Link> */}
          
        </IconButton>
      ),
    }
  })


  const replyHandler = async (e) => {
    e.preventDefault()
    const body = {
      message : replayMsg,
    }
   try {
    const resp = await universalFun("post",`send-reply/${replayId}` ,body)
    console.log(resp)
    toast.success("Email Sent")
    getContact()
    handleClose1()
   } catch (error) {
    
   }
    
  }


  return (
    <SoftBox
    className="TableContainer"
    sx={{
      "& .MuiTableRow-root:not(:last-child)": {
        "& td": {
          borderBottom: ({ borders: { borderWidth, borderColor } }) =>
            `${borderWidth[1]} solid ${borderColor}`,
        },
      },
    }}
  >
    <SoftBox className="table-responsive">

    <Table columns={columns} rows={rows} />

    <Fade in={open}>
            <SoftBox sx={style}>
              <SoftTypography component='label' fontSize="18px" variant='caption' fontWeight='bold' mb={2}>
                Reply
              </SoftTypography>
              <form  onSubmit={replyHandler}>
              <SoftBox >
          
                <SoftBox display="flex" flexDirection="column">
                  <SoftBox mb={2} display="flex" flexDirection="column">
                    <TextareaAutosize 
                      type="text" 
                      className="Textarea" 
                      placeholder="" 
                      minRows={8} 
                      p={2} 
                      value = {replayMsg}
                      onChange={(e)=> setReplayMsg(e.target.value)}
                      required
                    />
                  </SoftBox>
                  <Button variant="contained" type = "submit" color="primary" style={{ color: "white" }} fontWeight="medium">
                    Reply
                  </Button>
                </SoftBox>
              </SoftBox>
              </form>
              <SoftBox className='cross-icn cursor-pointer'>
                <CloseIcon onClick={handleClose1}/>
              </SoftBox>
            </SoftBox>
          </Fade>
    
  </SoftBox>
  </SoftBox>
  )
}

export default Queries