
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PlatformSettings from "layouts/view-user-details/components/PlatformSettings";
import React, { useState, useEffect} from 'react';
import logo from "assets/images/small-logos/logo-apple.svg";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import {useParams} from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
// import team2 from "../../assets/images/team-2.jpg";
import team2 from "../../assets/images/team-2.jpg"
import userPlaceholder from "assets/images/FallbackImage.jpg"

import SoftInput from "components/SoftInput";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { viewUserService } from "../../util/httpService";

function ViewUser() {
  const params = useParams();

  console.log("aaaaaaaaaaaaaaaaa111111111111 params", params);
  const [userInfo, setInfo] = useState({
    image: "",
    first_name: "",
    last_name: "",
    phone_number: "",
    email: "",
    user_type: "",

    // password:'',
    initials: "",
    // genrate_password:false,
    // send_credentials:false
  });

  const [usePlatformSettingData, setPlatformSettingData] = useState({
    quote: false,
    view: false,
    edit: false,
    access_the_calendar: false,
    access_the_workshop_schedule: false,
    make_invoices: false,
    change_prices: false,
    create_customers: false,
    create_repair_order: false,
  });
  console.log("user", userInfo);

  useEffect(() => {
    async function getUserData() {
      console.log("parms 222222222", params);
      let obj = params.id;
      try {
        const resp = await viewUserService(obj);
        console.log("User view data ", resp.data.response.data);
        if (resp?.data.code === 200) {
          const data = resp.data.response.data;
          setInfo({
            image: data.image,
            first_name: data.first_name,
            last_name: data.last_name,
            phone_number: data.phone_number,
            email: data.email,
            user_type: data.user_type,
            // password:'',
            initials: data.initials,
            // genrate_password:data.genrate_password,
            // send_credentials:data.send_credentials
          });
          setPlatformSettingData({
            quote: data.quote,
            view: data.view,
            edit: data.edit,
            access_the_calendar: data.access_the_calendar,
            access_the_workshop_schedule: data.access_the_workshop_schedule,
            make_invoices: data.make_invoices,
            change_prices: data.change_prices,
            create_customers: data.create_customers,
            create_repair_order: data.create_repair_order,
          });
        }
      } catch (err) {
        // alert(err)
      }
    }
    getUserData();
  }, []);

  // custome style
  const styles = {
    icon: {
      width: "1.3rem",
    },
    labelforinput: {
      position: "absolute",
      right: "0px",
      bottom: "12px",
      background: "lightblue",
      width: "30px",
      height: "30px",
      borderRadius: "50%",
      lineHeight: "-1",
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    previewImage: {
      width: "120px",
      height: "120px,",
      borderRadius: "50%",
    },

    selectContainer: {
      position: "relative",
    },

    chevIocn: {
      position: "absolute",
      zIndex: "999",
      right: "10px",
      top: "10px",
    },
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox display="flex" flexDirection="column" p={3}>
              <SoftTypography variant="h4">View User</SoftTypography>
            </SoftBox>

            <SoftBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              p={3}
            >
              <div className="image-wrapper" style={{ position: "relative" }}>
                <div className="image-upload">
                  {/* <label htmlFor="file-input" style={styles.labelforinput}>
                    <EditRoundedIcon className="iconButton" />
                  </label> */}
                  {/* <input id="file-input" type="file" onChange={ImageUploaded} /> */}
                  {userInfo && (
                    <img
                      src={
                        (userInfo.image === "" || userInfo.image === undefined )
                          ? userPlaceholder
                          : process.env.REACT_APP_IMAGE_URL +
                            "sub_users/" +
                            userInfo.image
                      }
                      alt="Selected"
                      style={styles.previewImage}
                    />
                  )}
                </div>
              </div>
            </SoftBox>

            <SoftBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              p={3}
            >
              <Grid container spacing={2} mb={3}>
                <Grid item xs={12} sm={6}>
                  <SoftTypography variant="h4">
                    Personal Information
                  </SoftTypography>
                </Grid>
                <Grid item xs={12} sm={6}></Grid>
                <Grid item xs={12} sm={6}>
                  <SoftBox mb={1} ml={0.5}>
                    <SoftTypography
                      component="label"
                      fontSize="16px"
                      variant="caption"
                      fontWeight="bold"
                    >
                      First Name
                    </SoftTypography>
                  </SoftBox>
                  <SoftInput
                    type="text"
                    placeholder="First Name"
                    name="first_name"
                    value={userInfo.first_name}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SoftBox mb={1} ml={0.5}>
                    <SoftTypography
                      component="label"
                      fontSize="16px"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Last Name
                    </SoftTypography>
                  </SoftBox>
                  <SoftInput
                    type="text"
                    placeholder="Last Name"
                    name="last_name"
                    value={userInfo.last_name}
                    disabled={true}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <SoftBox mb={1} ml={0.5}>
                    <SoftTypography
                      component="label"
                      fontSize="16px"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Email address
                    </SoftTypography>
                  </SoftBox>
                  <SoftInput
                    type="text"
                    placeholder="email@address.com"
                    name="email"
                    value={userInfo.email}
                    disabled={true}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <SoftBox mb={1} ml={0.5}>
                    <SoftTypography
                      component="label"
                      fontSize="16px"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Phone Number
                    </SoftTypography>
                  </SoftBox>
                  <SoftInput
                    type="number"
                    placeholder="Phone Number"
                    name="phone_number"
                    value={userInfo.phone_number}
                    disabled={true}
                  />
                </Grid>

                {/* <Grid item xs={12} sm={6}>

                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography component='label' fontSize="16px" variant='caption' fontWeight='bold'>
                        Company Name
                      </SoftTypography>
                    </SoftBox>
                    <SoftInput type='text' placeholder='Lorem ipsum' />

                  </Grid>
                  <Grid item xs={12} sm={6}>

                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography component='label' fontSize="16px" variant='caption' fontWeight='bold'>
                        Company Logo
                      </SoftTypography>
                    </SoftBox>
                    <SoftInput type='file' placeholder='choosoe file' />

                  </Grid>

                  <Grid item xs={12} sm={6}>

                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography component='label' fontSize="16px" variant='caption' fontWeight='bold'>
                        SIRET Number
                      </SoftTypography>
                    </SoftBox>
                    <SoftInput type='text' placeholder='2334556677777' />

                  </Grid> */}

                <Grid item xs={12} sm={12}>
                  <SoftBox mb={1} ml={0.5}>
                    <SoftTypography
                      component="label"
                      fontSize="16px"
                      variant="caption"
                      fontWeight="bold"
                    >
                      User Type
                    </SoftTypography>
                  </SoftBox>

                  <FormControl style={styles.selectContainer} fullWidth>
                    <ArrowDropDownIcon style={styles.chevIocn} />
                    <Select
                      value={userInfo.user_type}
                      name="user_type"
                      readOnly
                    >
                      {/* <MenuItem value="Select an option"></MenuItem> */}
                      <MenuItem value="body_work_subcontractor">
                        DSP subcontractor
                      </MenuItem>
                      <MenuItem selected value="super_user">
                        Super User
                      </MenuItem>
                      <MenuItem value="body_repairer">Technician </MenuItem> 
                      <MenuItem value="expert">Expert </MenuItem>
                      <MenuItem value="body">Body</MenuItem>
                      <MenuItem value="bodywork_team">Body Work Team</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                {/* <Grid item xs={12} sm={6}>

                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography component='label' fontSize="16px" variant='caption' fontWeight='bold'>
                        Country
                      </SoftTypography>
                    </SoftBox>
                    <FormControl style={styles.selectContainer} fullWidth>
                      <ArrowDropDownIcon style={styles.chevIocn} />
                      <Select value={selectedOptionCountry} onChange={handleChangeCountry} >
                        <MenuItem value="Select an option"></MenuItem>
                        <MenuItem selected value="option1">Option 1</MenuItem>
                        <MenuItem value="option2">Option 2</MenuItem>
                        <MenuItem value="option3">Option 3</MenuItem>
                      </Select>
                    </FormControl>

                  </Grid>

                  <Grid item xs={12} sm={6}>

                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography component='label' fontSize="16px" variant='caption' fontWeight='bold'>
                        Province/State
                      </SoftTypography>
                    </SoftBox>

                    <FormControl style={styles.selectContainer} fullWidth>
                      <ArrowDropDownIcon style={styles.chevIocn} />

                      <Select value={selectedOptionState} onChange={handleChangestate} >
                        <MenuItem value="Select an option">Select an option</MenuItem>
                        <MenuItem selected value="option1">Option 1</MenuItem>
                        <MenuItem value="option2">Option 2</MenuItem>
                        <MenuItem value="option3">Option 3</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>

                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography component='label' fontSize="16px" variant='caption' fontWeight='bold'>
                        City
                      </SoftTypography>
                    </SoftBox>
                    <FormControl style={styles.selectContainer} fullWidth>
                      <ArrowDropDownIcon style={styles.chevIocn} />

                      <Select value={selectedOptionState} onChange={handleChangestate} >
                        <MenuItem value="">Select an option</MenuItem>
                        <MenuItem selected value="option1">Option 1</MenuItem>
                        <MenuItem value="option2">Option 2</MenuItem>
                        <MenuItem value="option3">Option 3</MenuItem>
                      </Select>
                    </FormControl>

                  </Grid>

                  <Grid item xs={12} sm={6}>

                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography component='label' fontSize="16px" variant='caption' fontWeight='bold'>
                        Postal/Zip Code
                      </SoftTypography>
                    </SoftBox>
                    <SoftInput type='text' placeholder='145518' />
                  </Grid> */}
              </Grid>

              <Grid container spacing={2} pt={3}>
                <Grid item xs={12} md={6} xl={5}>
                  <SoftBox className="platformContainer">
                    <PlatformSettings data={usePlatformSettingData} />
                  </SoftBox>
                </Grid>
                <Grid item xs={12} md={6} xl={7}>
                  <Card>
                    <SoftBox p={3}>
                      <Grid className="dataContainer" container spacing={2}>
                        <SoftTypography variant="h6" pt={1} px={2}>
                          Login information
                        </SoftTypography>

                        {/* <Grid item xs={12} sm={12} pt={0}>

                            <SoftBox mb={1} ml={0.5} pt={0}>
                              <SoftTypography className="subdetails" component='label' fontSize="16px" variant='caption' fontWeight='regular'>
                                Password
                              </SoftTypography>
                            </SoftBox>
                            <SoftInput  type='password' name='password' placeholder='Enter Password' value=''/>

                          </Grid>

                          <Grid item xs={12} sm={12}>

                            <SoftBox mb={1} ml={0.5}>
                              <SoftTypography component='label' fontSize="16px" variant='caption' fontWeight='regular'>
                                Confirm Password
                              </SoftTypography>
                            </SoftBox>
                            <SoftInput type='password' name='confirmPssword' placeholder='Enter Password to Confirm' value=''/>
                          </Grid> */}

                        {/* <Grid item xs={12} sm={6}>
                            <SoftBox display="flex" py={1} mb={0.25}>
                              <SoftBox mt={0.25}>
                                <Switch name='genrate_password' />
                              </SoftBox>
                              <SoftBox ml={1}>
                                <SoftTypography variant="button" fontWeight="regular" color="text" checked={userInfo.genrate_password}>
                                  Generate password
                                </SoftTypography>
                              </SoftBox>
                            </SoftBox>
                          </Grid> */}

                        {/* <Grid className="" item xs={12} sm={6}>
                            <SoftBox display="flex" py={1} mb={0.25}>
                              <SoftBox mt={0.25}>
                                <Switch name='send_credentials' checked={userInfo.send_credentials}/>
                              </SoftBox>
                              <SoftBox ml={1}>
                                <SoftTypography variant="button" fontWeight="regular" color="text">
                                  Send credential to new user
                                </SoftTypography>
                              </SoftBox>
                            </SoftBox>
                          </Grid> */}
                      </Grid>

                      <Grid className="spce_top" container spacing={2}>
                        <SoftBox
                          display="flex"
                          justifyContent="start"
                          alignItems="start"
                          flexDirection="column"
                        >
                          <SoftTypography variant="h6" pt={4} px={2}>
                            Others
                          </SoftTypography>
                          {/* <SoftTypography component='label' fontSize="14px" variant='caption'>Initials are used to name documents</SoftTypography> */}
                        </SoftBox>

                        <Grid item xs={12} sm={12}>
                          <SoftBox mb={1} ml={0.5}>
                            <SoftTypography
                              component="label"
                              fontSize="16px"
                              variant="caption"
                              fontWeight="regular"
                            >
                              Initials
                            </SoftTypography>
                          </SoftBox>
                          <SoftInput
                            value={userInfo.initials}
                            name="initials"
                            placeholder="Enter Initials"
                            disabled={true}
                          />
                        </Grid>
                      </Grid>
                    </SoftBox>
                  </Card>
                </Grid>
              </Grid>
            </SoftBox>
          </Card>
        </SoftBox>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ViewUser;
