import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import SoftInput from "components/SoftInput";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import * as React from "react";
import { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { TablePagination } from "@mui/material";

import { Link } from "react-router-dom";

import Modal from "@mui/material/Modal";
import { Dropdown } from "react-bootstrap";

import { toast } from "react-toastify";

//customise funcs
import { universalFun } from "util/httpService";
import { getDate } from "util/timeConverter";

//icons
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { VatModal } from "components/Vat Management/AddVatModel";
import { current } from "@reduxjs/toolkit";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

function VatManagement() {
  const timerRef = React.useRef();

  const [data, setData] = useState({
    country_name: "",
    vat_rate: "",
  });

  //modal states
  const [openModel, setOpenModel] = useState(false);

  const handleVatModelOpen = () => setOpenModel(true);
  const handleVatModelClose = () => {
    setOpenModel(false);
    setData({
      country_name: "",
      vat_rate: "",
    });
  };

  //delete modal
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleDeleteModalOpen = () => setOpenDeleteModal(true);
  const handleDeleteModalClose = () => setOpenDeleteModal(false);

  const [vatListing, setVatListing] = useState([]);

  const [deleteId, setDeleteId] = React.useState("");

  const [selectedModal, setSelectedModal] = useState("add");

  const [search, setSearch] = React.useState("");

  //pagination
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [listCount, setListCount] = useState(100);

  const handlePageChange = (params, value) => {
    // 'value' represents the selected page
    setCurrentPage(value);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const getVatListing = async (debouncSearch) => {
    const params = {
      search: debouncSearch || search,
      limit: rowsPerPage,
      page: currentPage + 1,
    };
    try {
      const res = await universalFun("get", "vat-list", null, params);
      setVatListing(res.data?.data);
      setListCount(res.data?.totalItems)
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    getVatListing();
  }, [currentPage ,rowsPerPage]);

  //search deboucing

  const debounce = (func, delay) => {
    return (...args) => {
      clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const debounceSearch = debounce(getVatListing, 500);

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearch(value);

    debounceSearch(value);
  };

  // ------------------------ column for table
  const columns = [
    { field: "space", headerName: "", width: 5 },
    { field: "index", headerName: "#", width: 100 },

    { field: "country_name", headerName: "Country", width: 200 },
    { field: "vat_rate", headerName: "Vat Rate", width: 200 },

    {
      field: "createdAt",
      headerName: "Created At",
      width: 100,
      renderCell: (params) => getDate(params?.row?.createdAt),
    },
    {
      field: "Action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => (
        <>
          <Link
            to=""
            onClick={() => {
              setSelectedModal("view");
              handleVatModelOpen();
              setData(params?.row);
            }}
            className="actionIcon"
            style={{ color: "#202090", fontSize: "20px", paddingLeft: "5px" }}
          >
            <IconButton aria-label="view">
              <RemoveRedEyeIcon />
            </IconButton>
          </Link>
          <Link
            to=""
            onClick={() => {
              setSelectedModal("edit");
              handleVatModelOpen();
              setData(params?.row);
            }}
            className="actionIcon"
          >
            <IconButton aria-label="edit">
              <EditRoundedIcon />
            </IconButton>
          </Link>
          <Link
            to="" // Provide the correct route or path
            onClick={() => {
              setDeleteId(params.row._id);
              handleDeleteModalOpen();
            }}
            className="actionIcon"
            style={{ color: "#202090", fontSize: "20px", paddingLeft: "5px" }}
          >
            <IconButton aria-label="delete">
              <DeleteIcon />
            </IconButton>
          </Link>
        </>
      ),
    },
  ];

  //making vat row for data grid ****************************
  const vatRow = React.useMemo(() => {
    if (Array.isArray(vatListing)) {
      return vatListing.map((obj, index) => ({
        ...obj,
        index: (index + 1)+(currentPage*rowsPerPage),
      }));
    } else {
      return [];
    }
  }, [vatListing]);

  //handle vat add   ***********************
  const handleVatAdd = async () => {
    console.log(data);
    try {
      const res = await universalFun("post", "add-vat", data);
      toast.success("Vat added successfully");
      handleVatModelClose();
      getVatListing();
    } catch (error) {
      console.log(error);
    }
  };

  //handle vat edit   ***********************
  const handleVatEdit = async () => {
    if (!data._id) {
      console.log("id not found");
      return;
    }
    const body = { country_name : data.country_name, 
      vat_rate : data.vat_rate };


    try {
      const res = await universalFun("patch", `/edit-vat/${data._id}`, body);
      toast.success("Vat edited successfully");
      handleVatModelClose();
      getVatListing();
    } catch (error) {
      console.log(error);
    }
  };

  // handle vat delete
  const handleDeleteVat = async () => {
    if (!deleteId) {
      console.log("delete id not found");
      return;
    }
    try {
      const res = await universalFun("delete", `delete-vat/${deleteId}`);
      handleDeleteModalClose();
      toast.success("Vat deleted successfully");
      getVatListing();
    } catch (error) {}
  };

     // data grid footer
     const [ columnVisible ,  setColoumsVisible] = useState({})
     const [hideDatagridFooter , setHideDatagridFooter] = useState(false)

     const handleColumnModelChange = (param)=>{
       //  console.log(param,"======")
         const hideFooter = columns.every((obj)=> {
           const field = obj?.field
          return  param?.[field] === false} )
          setHideDatagridFooter(hideFooter)
         setColoumsVisible(param)
       }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card className="insurancePage">
            <SoftBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={3}
              className="car-management"
            >
              <SoftTypography variant="h6">Vat Lists</SoftTypography>
              <SoftBox display="flex" alignItems="center" gap="1rem">
                <Dropdown
                  className="fltrDrop"
                  //   onSelect={(value) => setStatus(value)}
                >
                  {/* <Dropdown.Toggle
                    id="dropdown-button-dark-example1"
                    variant="primary"
                  >
                    <FilterAltIcon />
                  </Dropdown.Toggle> */}
                  {/* <Dropdown.Menu>
                    <Dropdown.Item
                      className={status === "" ? "active" : ""}
                      eventKey=""
                    >
                      ALL..
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={status === "active" ? "active" : ""}
                      eventKey="active"
                    >
                      Active
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={status === "inactive" ? "active" : ""}
                      eventKey="inactive"
                    >
                      InActive
                    </Dropdown.Item>
                  </Dropdown.Menu> */}
                </Dropdown>

                <Dropdown
                  className="fltrDrop"
                  //   onSelect={(value) => setSort(value)}
                >
                  {/* <Dropdown.Toggle
                    id="dropdown-button-dark-example1"
                    variant="primary"
                  >
                    {!sort ? (
                      <FilterListIcon />
                    ) : (
                      <FilterListIcon style={{ transform: "rotate(180deg)" }} />
                    )}
                  </Dropdown.Toggle> */}
                  <Dropdown.Menu>
                    <Dropdown.Item
                      //   className={!sort ? "active" : ""}
                      eventKey=""
                    >
                      Descending
                    </Dropdown.Item>
                    <Dropdown.Item
                      //   className={sort ? "active" : ""}
                      eventKey="asc"
                    >
                      Ascending
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

                <SoftBox pr={1}>
                  <SoftInput
                    placeholder="Type here..."
                    icon={{ component: "search", direction: "left" }}
                    value={search}
                    onChange={handleSearch}
                  />
                </SoftBox>
                <div className="top-btn">
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ color: "white" }}
                    fontWeight="medium"
                    className="add-btn"
                    onClick={() => {
                      setSelectedModal("add");
                      setOpenModel(true);
                    }}
                  >
                    Add
                  </Button>
                </div>
              </SoftBox>
            </SoftBox>

            <div style={{ height: 400, width: "100%" }}>
              <DataGrid
                rows={vatRow}
                columns={columns}
                getRowId={(row, index) => row._id}
                // columnVisibilityModel={columnVisible}
                // onColumnVisibilityModelChange={handleColumnModelChange}
              />
            </div>
            <div className="d-flex justify-content-between">
              <div className="pt-2">
                {/* {hideDatagridFooter && (
                  <Button
                    onClick={() => {
                    //   setColoumsVisible({});
                    //   setHideDatagridFooter(false);
                    }}
                  >
                    Show All
                  </Button>
                )} */}
              </div>
              <TablePagination
                component="div"
                count={listCount}
                page={currentPage}
                onPageChange={handlePageChange}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 15, 20, 25]}
              />
            </div>
          </Card>
          {/* <TablePagination
            component="div"
            count={userListCount}
            page={currentPage}
            onPageChange={handlePageChange}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10, 15, 20, 25]}
          /> */}
        </SoftBox>
      </SoftBox>

      <VatModal
        open={openModel}
        handleClose={handleVatModelClose}
        data={data}
        setData={setData}
        modalName={selectedModal}
        style={style}
        title={"Add Vat"}
        handleSave={handleVatAdd}
        handleEdit={handleVatEdit}
      />
      <Modal
        open={openDeleteModal}
        onClose={handleDeleteModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="delete-modal"
      >
        <Box sx={style}>
          <Typography className="modal-image">
            <div className="delete-message">
              Are you sure you want to delete ?
            </div>
          </Typography>
          <Typography
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            <Button
              variant="contained"
              onClick={handleDeleteVat}
              color="primary"
              style={{ color: "white" }}
              fontWeight="medium"
            >
              Yes
            </Button>
            <Button
              variant="contained"
              onClick={handleDeleteModalClose}
              color="primary"
              style={{ color: "white" }}
              fontWeight="medium"
            >
              No
            </Button>
          </Typography>
        </Box>
      </Modal>
      <Footer />
    </DashboardLayout>
  );
}

export default VatManagement;
