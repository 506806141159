/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";

// Data
// import authorsTableData from "layouts/tables/data/authorsTableData";
// import projectsTableData from "layouts/tables/data/projectsTableData";
import Store from "./data/store";
import StoreData from "./data/storeData";
//Button
import Button from '@mui/material/Button';
import { Dropdown } from "react-bootstrap";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

//Links
import { TablePagination } from "@mui/material";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import { Modal } from "@mui/material";
import Typography from "@mui/material/Typography";
import SoftButton from "components/SoftButton";
import { useEffect, useState } from "react";
import SoftInput from "components/SoftInput";
// import SoftTexrarea from "components/SoftTextarea";
import CloseIcon from "@mui/icons-material/Close";
// import SoftBox from "components/SoftBox";
// import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import SoftBadge from "components/SoftBadge";
import Switch from "@mui/material/Switch";
import { Checkbox } from "@mui/material";
// import { useState } from "react";


//logo
import BMWImage from 'assets/images/BMW.png';

//icons
import IconButton from '@mui/material/IconButton';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DeleteIcon from '@mui/icons-material/Delete'
import EditRoundedIcon from '@mui/icons-material/EditRounded';
// import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import { universalFun } from "util/httpService";
import { toast } from "react-toastify";
import { objToQueryString } from "util/httpService";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

function Notification() {

  const [notificationList , setNotificationList] = useState([])
  // const { columns, rows } = StoreData;
  const [deleteIds , setDeleteIds] = useState([])

  // ---------fristmodel------
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  // ---------secondmodel------
  const [show2, setShow2] = useState(false);

  const handleClose2 = () => {
    setShow2(false)
    
    setDeleteIds([])
  };
  const handleShow2 = () =>{ setShow2(true)
    const AllIds =  notificationList.map((obj)=> obj._id)
    setDeleteIds(AllIds)
  }

// *************pagination & filters*********************

  const [listCount ,setListCount] = useState(10)

  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [offset, setOffSet] = useState(0);
  const [sort, setSort] = useState("");



  const handlePageChange = (event, value) => {
    // 'value' represents the selected page
    setCurrentPage(value);
    // You can perform any additional actions here based on the selected page
    console.log(`Selected page: ${value}`);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const CheckForHandleShow = ()=>{
    if(deleteIds.length>0){
      handleShow()
    }else{
      toast.info("Please Select Notification")
    }
  }

  //getting notifications
  const getNotificatonListing = async()=>{
    const params = {
      sort : sort,
      page: currentPage + 1,
      limit: rowsPerPage,
    }
    const paramString = objToQueryString(params);
    try {
      const resp = await universalFun("get",`get-notifications?${paramString}`)
      console.log(resp)
      setNotificationList(resp?.data?.response?.data)
      setListCount(resp?.data?.response?.totalItems)
    } catch (error) {
     
    }
   
  }

  useEffect(()=>{
    getNotificatonListing()
  },[sort,currentPage ,rowsPerPage])

  const handleCheckChange = (id)=>{
    if(deleteIds.includes(id)){
      const newArray = deleteIds.filter((ids) => ids !== id)
      setDeleteIds(newArray)
    }else{
     
      setDeleteIds((prev)=> [...prev,id])
    } 
  }


  const handleDeleteNotify =async (deleteType)=>{
    const body ={
       ids : []
    }
    if(deleteType ==="selected"){
      body.ids = deleteIds
    }
    
    try {
      const resp = await universalFun("delete" ,"delete-notifications" ,body)
      console.log(resp)
      toast.success("Deleted Successfully")
      getNotificatonListing()
      handleClose()
      handleClose2()
    } catch (error) {
      
    }
  }
  



  const getDate = (createdAt) => {
    const originalDate = new Date(createdAt);

// Options for formatting the date
const dateOptions = { day: '2-digit', month: 'short', year: 'numeric' };
const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: true };

// Format the date and time separately
const formattedDate = originalDate.toLocaleDateString('en-US', dateOptions);
const formattedTime = originalDate.toLocaleTimeString('en-US', timeOptions);

// Combine date and time into the desired format
return `${formattedTime}, ${formattedDate}`;
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftTypography variant="h6">Notification List</SoftTypography>
              {/* <Link to="">
                <Button variant="contained" onClick={() => setOpen2(true)} color="primary" style={{ color: "white" }} fontWeight="medium" >
                  Add FAQ
                </Button>
              </Link> */}
              <SoftBox display="flex" gap="0.8rem" align-items="center">
              <Dropdown className="fltrDrop" onSelect={(value) => setSort(value)}>
                  <Dropdown.Toggle id="dropdown-button-dark-example1" variant="primary">
                    {!sort ? <FilterListIcon /> :
                      <FilterListIcon style={{ transform: "rotate(180deg)" }} />}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item  className = {!sort ? "active" :""} eventKey="">Descending</Dropdown.Item>
                    <Dropdown.Item  className = {sort  ? "active" :""} eventKey="asc">Ascending</Dropdown.Item>

                  </Dropdown.Menu>
                </Dropdown>

               
               

                <Button
                  className="theme_btn outline"
                  style={{ width: "100px", border: "none" ,height : "40px"}}
                  onClick={CheckForHandleShow}
                  >
                  Clear
                </Button>
                <Button
                  className="theme_btn"
                  style={{ width: "100px", border: "none",height : "40px" }}
                  onClick={handleShow2}
                  >
                  Clear All
                </Button>
              </SoftBox>
            </SoftBox>
            <SoftBox className="TableContainer"
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <SoftBox className="">

                {/* <Table columns={columns} rows={rows} /> */}
                {/* <Table columns={columns}  /> */}
                <div className="notificationsWrap">
                {notificationList?.map((obj)=>(
                  <div className="notificationItem">
                <Checkbox className="mt-2" checked= {deleteIds.includes(obj?._id)} onChange={()=>handleCheckChange(obj?._id)}/>
                  {/* <img src={usrimg1} className="notifyImg" alt="Notification Image" /> */}
                  <div className="notifyText">
                    <h6 className="notifyTitle">
                      {obj?.title}

                    </h6>
                    <p className="notifyDesc">{obj?.description}</p>

                    <p className="notifyTime">
                     { getDate(obj?.createdAt)}
                    </p>
                  </div>
                </div>
                
                ))}
                

              </div>
              </SoftBox>
            </SoftBox>
            <TablePagination
                  component="div"
                  count={listCount}
                  page={currentPage}
                  onPageChange={handlePageChange}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  rowsPerPageOptions={[5,10, 15, 20, 25]}
                />
          </Card>
        </SoftBox>

      </SoftBox>
       {/* ******************delete  Modal************** */}
      <Modal
        open={show}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="delete-modal"
      >
        <Box sx={style}>
          <Typography className="modal-image">
            <div className="delete-message">
              Are you sure you want to delete Selected Notifications?
            </div>
          </Typography>
          <Typography
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "center",
              gap : "10px"
            }}
          >
            <Button
              variant="contained"
              onClick={()=>handleDeleteNotify("selected")}
              color="primary"
              style={{ color: "white" }}
              fontWeight="medium"
            >
              Yes
            </Button>
            <Button
              variant="contained"
              onClick={handleClose}
              color="primary"
              style={{ color: "white" }}
              fontWeight="medium"
            >
              No
            </Button>
          </Typography>
        </Box>
      </Modal>

      {/* ******************delete all Modal************** */}
      <Modal
        open={show2}
        onClose={handleClose2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="delete-modal"
      >
        <Box sx={style}>
          <Typography className="modal-image">
            <div className="delete-message">
              Are you sure you want to delete All Notifications?
            </div>
          </Typography>
          <Typography
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "center",
              gap : "10px"
            }}
          >
            <Button
              variant="contained"
              onClick={()=>handleDeleteNotify("deletedAll")}
              color="primary"
              style={{ color: "white" }}
              fontWeight="medium"
            >
              Yes
            </Button>
            <Button
              variant="contained"
              onClick={handleClose2}
              color="primary"
              style={{ color: "white" }}
              fontWeight="medium"
            >
              No
            </Button>

          </Typography>
        </Box>
      </Modal>
      <Footer />

    </DashboardLayout>


  );
}

export default Notification;


