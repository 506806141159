import { configureStore } from '@reduxjs/toolkit';
import navbarReducer from './slices/navbarSlice';

const store = configureStore({
  reducer: {
    
    navbar : navbarReducer 

  },
},
window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

export default store;
