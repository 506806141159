import { useState } from 'react';
import { checkMailExists } from 'util/httpService';

const useEmailExistenceCheck = () => {
  const [isEmailAvail, setIsEmailAvail] = useState({ isValid: true, message: '' });
  const emailRegx = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const checkEmailExistence = async (value , valid) => {
    // Perform the email existence check
    if (emailRegx.test(value)) {
      const body = {
        email: value,
      };

      try {
        const resp = await checkMailExists(body);

        if (resp?.data?.status === false) {
          setIsEmailAvail({ isValid: resp?.data?.status, message: 'Email already exists' });
        } else if (resp?.data?.status === true) {
          setIsEmailAvail({ isValid: resp?.data?.status, message: '' });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return { isEmailAvail, setIsEmailAvail , checkEmailExistence };
};

export default useEmailExistenceCheck;
