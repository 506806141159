/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";

import Store from "./data/store";
import Button from "@mui/material/Button";

//Links

import { Link } from "react-router-dom";
import { Modal } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import SoftInput from "components/SoftInput";
import CloseIcon from "@mui/icons-material/Close";
import Switch from "@mui/material/Switch";
import { Dropdown } from "react-bootstrap";

//icons
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

//icons
import IconButton from "@mui/material/IconButton";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";
// import Modal from '@mui/material/Modal';
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import ReplyIcon from "@mui/icons-material/Reply";
import { getContactUsFunction } from "util/httpService";
import { deleteContactUsFunction } from "util/httpService";

import { TablePagination } from "@mui/material";
import { getDate } from "util/timeConverter";
import { toast } from "react-toastify";
import { universalFun } from "util/httpService";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

function ContactUs() {
  const timerRef = useRef();

  // const { columns, rows } = StoreData;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // ---------secondmodel------
  const [open1, setOpen1] = useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => {
    setOpen1(false);
    setReplayMsg("");
  };
  //---------model-------
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const handleOpenDelete = () => setOpenDeleteModal(true);
  const handleCloseDelete = () => setOpenDeleteModal(false);

  const [deleteId, setDeleteId] = useState("");
  const [data, setData] = useState([]);

  const [replayMsg, setReplayMsg] = useState("");
  const [replayId, setReplayId] = useState("");

  //pagination
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [listCount, setListCount] = useState(100);

  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("");
  const [status, setStatus] = useState("");

  const handlePageChange = (event, value) => {
    // 'value' represents the selected page
    setCurrentPage(value);
    // You can perform any additional actions here based on the selected page
    console.log(`Selected page: ${value}`);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const getContact = async (debounceSearch) => {
    const params = {
      page: currentPage + 1,
      limit: rowsPerPage,
      search: debounceSearch || "",
      sort: sort,
      status: status,
    };
    try {
      const result = await getContactUsFunction(params);
      console.log("result", result?.data?.data);
      setData(result?.data?.data?.item);
      setListCount(result?.data?.data?.totalItems);
    } catch (error) {
      console.log(error);
    }
  };

  // Contact Us Function and State-
  useEffect(() => {
    getContact();
  }, [currentPage, rowsPerPage, sort, status]);

  const contactUsDeleteHandler = async () => {
    try {
      const result = await deleteContactUsFunction(deleteId);

      toast.success(result?.data?.msg);
      handleCloseDelete();
      if (result) {
        getContact();
      }
    } catch (error) {
      console.log("deleting error----", error);
      // toast.error("Something Went Wrong")
    }
  };

  const replyHandler = async (e) => {
    e.preventDefault();
    const body = {
      message: replayMsg,
    };
    try {
      const resp = await universalFun("post", `send-reply/${replayId}`, body);
      console.log(resp);
      toast.success("Email Sent");
      getContact();
      handleClose1();
    } catch (error) {}
  };

  const [request, setRequest] = useState(true);

  const handleRequestSend = async (userId, queryId) => {
    // setRequest(!request);
    if (!userId || !queryId) {
      console.log("id not found");
      return;
    }
    try {
      const resp = await universalFun(
        "post",
        `send-login-request/${userId}/${queryId}`
      );
      console.log(resp);
      toast.success(resp?.data?.message);
      getContact();
    } catch (error) {
      console.log(error);
    }
  };

  // ************************handling login from admin ************************
  const handleLoginUser = async (token) => {
    // localStorage.setItem('token', token)

      const url = `https://dev-eurobosse.web.app/app/dashboard/${token}`
     // const url = `http://localhost:5173/app/dashboard/${token}`;
    window.open(url, "_blank");
  };

  const columns = [
    { name: "s.no.", align: "left" },
    { name: "name", align: "left" },
    { name: "email", align: "left" },
    { name: "phone no.", align: "left" },
    { name: "subject", align: "left" },
    { name: "status", align: "left" },
    { name: "created At", align: "left" },
    { name: "action", align: "center" },
  ];

  const rows = data?.map((el, index) => {
    return {
      "s.no.": (
        <SoftTypography
          variant="caption"
          color="secondary"
          fontWeight="medium"
          className="rspnv_tbls"
        >
          {index + 1}
        </SoftTypography>
      ),

      name: (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          {el.first_name} {el.last_name}
        </SoftTypography>
      ),

      email: (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          {el.email}
        </SoftTypography>
      ),
      "phone no.": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          {el.email}
        </SoftTypography>
      ),
      subject: (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          {el.subject}
        </SoftTypography>
      ),
      status: (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          {el.status}
        </SoftTypography>
      ),
      "created At": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          {getDate(el.createdAt)}
        </SoftTypography>
      ),
      // "Status": (
      //   <SoftBox>
      //     <Switch />
      //   </SoftBox>
      // ),

      action: (
        <IconButton aria-label="action">
          <Link to={`/View-Contact-Us/${el._id}`} className="actionIcon">
            <RemoveRedEyeIcon />
          </Link>
          <Link
            to=""
            className="actionIcon"
            onClick={() => {
              setOpen1(true);
              setReplayId(el._id);
            }}
          >
            <ReplyIcon />
          </Link>
          {/* <Link to="/" className="actionIcon" >
            <EmailIcon />
          </Link> */}
          <Link
            to=""
            className="actionIcon"
            onClick={() => {
              setDeleteId(el._id);
              handleOpenDelete();
            }}
          >
            <DeleteIcon />
          </Link>
          {el?.user_id &&
            (el?.request === "sent" ? (
              <Button
                variant="contained"
                style={{
                  backgroundColor: "white",
                  color: "#202090",
                  width: "80px",
                  marginRight: "10px",
                  whiteSpace: "break-spaces",
                  height: "40px",
                  fontSize: "10px",
                  cursor: "wait",
                }}
                color="primary"
                size="small"
                disabled={true}
              >
                Request Sent
              </Button>
            ) : el.login_token ? (
              <Button
                variant="contained"
                style={{
                  backgroundColor: "green",
                  color: "white",
                  width: "80px",
                  marginRight: "10px",
                  whiteSpace: "break-spaces",
                  height: "40px",
                  fontSize: "10px",
                }}
                color="primary"
                size="small"
                onClick={() => handleLoginUser(el.login_token)}
              >
                Go to Dashboard
              </Button>
            ) : (
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#202090",
                  color: "white",
                  width: "80px",
                  marginRight: "10px",
                  whiteSpace: "break-spaces",
                  height: "40px",
                  fontSize: "10px",
                }}
                color="primary"
                size="small"
                onClick={() => handleRequestSend(el.user_id, el._id)}
              >
                Request Access
              </Button>
            ))}
        </IconButton>
      ),
    };
  });

  //search deboucing

  const debounce = (func, delay) => {
    return (...args) => {
      clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const debounceSearch = debounce(getContact, 500);

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearch(value);

    debounceSearch(value);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={3}
            >
              {/* <SoftTypography variant="h6">Frequently Asked Questions</SoftTypography>
              <Link to="">
                <Button variant="contained" onClick={() => setOpen2(true)} color="primary" style={{ color: "white" }} fontWeight="medium" >
                  Add FAQ
                </Button>
              </Link> */}
            </SoftBox>
            <SoftBox
              display="flex"
              alignItems="center"
              gap="1rem"
              className="justify-content-end"
            >
              <Dropdown
                className="fltrDrop"
                onSelect={(value) => setStatus(value)}
              >
                <Dropdown.Toggle
                  id="dropdown-button-dark-example1"
                  variant="primary"
                >
                  <FilterAltIcon />
                  {/* <FilterListIcon /> */}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    className={status === "" ? "active" : ""}
                    eventKey=""
                  >
                    All..
                  </Dropdown.Item>
                  <Dropdown.Item
                    className={status === "pending" ? "active" : ""}
                    eventKey="pending"
                  >
                    Pending
                  </Dropdown.Item>
                  <Dropdown.Item
                    className={status === "complete" ? "active" : ""}
                    eventKey="complete"
                  >
                    Complete
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown
                className="fltrDrop"
                onSelect={(value) => setSort(value)}
              >
                <Dropdown.Toggle
                  id="dropdown-button-dark-example1"
                  variant="primary"
                >
                  {!sort ? (
                    <FilterListIcon />
                  ) : (
                    <FilterListIcon style={{ transform: "rotate(180deg)" }} />
                  )}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item className={!sort ? "active" : ""} eventKey="">
                    Descending
                  </Dropdown.Item>
                  <Dropdown.Item
                    className={sort ? "active" : ""}
                    eventKey="asc"
                  >
                    Ascending
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <SoftBox pr={1}>
                <SoftInput
                  placeholder="Type here..."
                  icon={{ component: "search", direction: "left" }}
                  value={search}
                  onChange={handleSearch}
                />
              </SoftBox>
            </SoftBox>
            <SoftBox
              className="TableContainer"
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <SoftBox className="table-responsive">
                <Table columns={columns} rows={rows} />
                <TablePagination
                  component="div"
                  count={listCount}
                  page={currentPage}
                  onPageChange={handlePageChange}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  rowsPerPageOptions={[5, 10, 15, 20, 25]}
                />
              </SoftBox>
            </SoftBox>
          </Card>
        </SoftBox>
      </SoftBox>
      <SoftBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={3}
      >
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open1}
          onClose={handleClose1}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={open1}>
            <SoftBox sx={style}>
              <SoftTypography
                component="label"
                fontSize="18px"
                variant="caption"
                fontWeight="bold"
                mb={2}
              >
                Reply
              </SoftTypography>
              <form onSubmit={replyHandler}>
                <SoftBox>
                  <SoftBox display="flex" flexDirection="column">
                    <SoftBox mb={2} display="flex" flexDirection="column">
                      <TextareaAutosize
                        type="text"
                        className="Textarea"
                        placeholder=""
                        minRows={8}
                        p={2}
                        value={replayMsg}
                        onChange={(e) => setReplayMsg(e.target.value)}
                        required
                      />
                    </SoftBox>
                    <Button
                      variant="contained"
                      type="submit"
                      color="primary"
                      style={{ color: "white" }}
                      fontWeight="medium"
                    >
                      Reply
                    </Button>
                  </SoftBox>
                </SoftBox>
              </form>
              <SoftBox className="cross-icn cursor-pointer">
                <CloseIcon onClick={handleClose1} />
              </SoftBox>
            </SoftBox>
          </Fade>
        </Modal>
      </SoftBox>
      <Modal
        open={openDeleteModal}
        onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="delete-modal"
      >
        <Box sx={style}>
          <Typography className="modal-image">
            <div className="delete-message">
              Are you sure you want to delete ?
            </div>
          </Typography>
          <Typography
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            <Button
              variant="contained"
              onClick={contactUsDeleteHandler}
              color="primary"
              style={{ color: "white" }}
              fontWeight="medium"
            >
              Yes
            </Button>
            <Button
              variant="contained"
              onClick={handleCloseDelete}
              color="primary"
              style={{ color: "white" }}
              fontWeight="medium"
            >
              No
            </Button>
          </Typography>
        </Box>
      </Modal>
      <Footer />
    </DashboardLayout>
  );
}

export default ContactUs;
