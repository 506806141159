/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import Modal from "@mui/material/Modal";
// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";

// Data
// import authorsTableData from "layouts/tables/data/authorsTableData";
// import projectsTableData from "layouts/tables/data/projectsTableData";
import Store from "./data/store";
import StoreData from "./data/storeData";
//Button
import Button from "@mui/material/Button";

import IconButton from "@mui/material/IconButton";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import Switch from "@mui/material/Switch";
import { Dropdown } from "react-bootstrap";

//icons
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

//Links

import { Link } from "react-router-dom";
import { useState, useEffect, useMemo } from "react";
import { storeListService, universalFun } from "../../../util/httpService";
import {
  deleteStoreService,
  editStoreService,
} from "../../../util/httpService";

import { TablePagination } from "@mui/material";
import { getDate } from "util/timeConverter";
import { toast } from "react-toastify";

//images
import fallBackImage from "assets/images/FallbackImage.jpg";
import placeholderUser from "../../../assets/images/placeholderUser.jpeg";

function StoreManagement() {
  // const { columns, rows } = StoreData;

  //---------model-------
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const handleOpenDelete = () => setOpenDeleteModal(true);
  const handleCloseDelete = () => setOpenDeleteModal(false);

  const [deleteId, setDeleteId] = useState("");
  const [offset, setOffSet] = useState(0);

  const column = [
    { name: "Sr.No.", align: "center" },
    { name: "user", align: "left" },
    { name: "company", align: "left" },
    { name: "company logo", align: "left" },
    { name: "phone no.", align: "left" },
    { name: "business email", align: "left" },
    { name: "employes", align: "center" },
    { name: "Date", align: "left" },
    { name: "siret no.", align: "left" },
    { name: "Status", align: "center" },
    { name: "action", align: "center" },
  ];

  const [rowData, setRow] = useState([]);

  const [isFirstRender, setFirstRender] = useState(true);

  const [statusObj, setStatusObj] = useState([]);
  const [previousState, setPreviousState] = useState([]);

  //pagination
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [listCount, setListCount] = useState(100);

  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("");
  const [status, setStatus] = useState("");

  const handlePageChange = (event, value) => {
    // 'value' represents the selected page
    setCurrentPage(value);
    // You can perform any additional actions here based on the selected page
    console.log(`Selected page: ${value}`);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  // useEffect(() => {
  //   setOffSet(currentPage * rowsPerPage);
  // }, [currentPage]);

  const createdRow = useMemo(() => {
    if (Array.isArray(rowData)) {
      return rowData?.map((ele, index) => ({
        "Sr.No.": (
          <SoftTypography
            variant="caption"
            color="secondary"
            fontWeight="medium"
          >
            {index + 1 + currentPage * rowsPerPage}
          </SoftTypography>
        ),

        user: (
          <SoftTypography
            variant="caption"
            color="secondary"
            fontWeight="medium"
          >
            {ele.first_name + " " + ele.last_name}
          </SoftTypography>
        ),

        "phone no.": (
          <SoftTypography
            variant="caption"
            color="secondary"
            fontWeight="medium"
          >
            {ele.phone_number}
          </SoftTypography>
        ),

        company: (
          <SoftTypography
            variant="caption"
            color="secondary"
            fontWeight="medium"
          >
            {ele.company_name}
          </SoftTypography>
        ),
        "company logo": (
          <SoftTypography
            variant="caption"
            color="secondary"
            fontWeight="medium"
          >
            <img
              alt="company logo"
              className="logo_bmw"
              src={
                !ele.company_logo
                  ? fallBackImage
                  : process.env.REACT_APP_IMAGE_URL +
                    "profile_img/" +
                    ele.company_logo
              }
            />
          </SoftTypography>
        ),
        "siret no.": (
          <SoftTypography
            variant="caption"
            color="secondary"
            fontWeight="medium"
          >
            {ele.siret_number}
          </SoftTypography>
        ),
        "business email": (
          <SoftTypography
            variant="caption"
            color="secondary"
            fontWeight="medium"
          >
            {ele.email}
          </SoftTypography>
        ),
        employes: (
          <SoftTypography
            variant="caption"
            color="secondary"
            fontWeight="medium"
          >
            {ele.totalEmployees}
          </SoftTypography>
        ),

        Date: (
          <SoftTypography
            variant="caption"
            color="secondary"
            fontWeight="medium"
          >
            {getDate(ele?.createdAt)}
          </SoftTypography>
        ),
        Status: (
          <SoftBox>
            <Switch
              checked={statusObj[ele?._id]}
              onClick={(event) => statusChangeFunction(event, ele._id)}
            />
          </SoftBox>
        ),

        action: (
          <SoftTypography aria-label="action">
            <Link to={"/View-Store/" + ele._id} className="actionIcon">
              <RemoveRedEyeIcon />
            </Link>
            <Link to={"/Edit-Store/" + ele._id} className="actionIcon">
              <EditRoundedIcon />
            </Link>
            {/* <Link to="/" className="actionIcon" >
                <EmailIcon />
              </Link> */}
            <IconButton
              onClick={() => {
                handleOpenDelete();
                setDeleteId(ele._id);
              }}
              className="actionIcon"
            >
              <DeleteIcon />
            </IconButton>
            <Link to={"/User-Management/" + ele._id} className="actionIcon">
              <PeopleAltIcon />
            </Link>
            <Link
              to={"/garage-details/" + ele._id}
              className="actionIcon billing-icon"
            >
              <CreditCardIcon />
            </Link>
          </SoftTypography>
        ),
      }));
    } else {
      return [];
    }
  }, [rowData, statusObj]);

  async function getTableData() {
    let obj = {
      page: currentPage + 1,
      limit: rowsPerPage,
      search: search,
      sort: sort,
      status: status,
    };
    try {
      const resp = await storeListService(obj);
      console.log(" login response data=> ", resp.data);
      if (resp?.status === 200) {
        const data = resp.data.data?.items;
        setListCount(resp.data.data?.totalItems);

        setRow(data);

        //  alert("list")
      }

      //  navigate('/dashboard')
    } catch (err) {
      console.log(err);
    }
  }

  // const { columns: prCols, rows: prRows } = Store;
  useEffect(() => {
    getTableData();
  }, [currentPage, rowsPerPage, sort, status]);

  useEffect(() => {
    if (!isFirstRender) {
      const Timer = setTimeout(() => {
        getTableData();
      }, 500);
      return () => {
        clearTimeout(Timer);
      };
    } else {
      setFirstRender(false);
    }
  }, [search]);

  const deleteFunction = async () => {
    try {
      const resp = await deleteStoreService(deleteId);
      console.log(" delete api ", resp);
      if (resp?.status === 200) {
        getTableData();
        toast.success("Deleted Successfully");
        handleCloseDelete();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const statusChangeFunction = async (event, id) => {
    setStatusObj((prev) => {
      setPreviousState(prev);
      return { ...prev, [id]: event.target.checked };
    });

    // let obj = {
    //   id: id,
    //   is_active: event.target.checked === true ? "active" : "inactive",
    // };

    try {
      const resp = await editStoreService(id);
      console.log(" delete api ", resp);
      if (resp?.status === 200) {
        getTableData();
        // toast.success("Store Status Changed Successfully");
      }
    } catch (error) {
      console.log(error);
      setStatusObj(previousState);
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    // boxShadow: 24,
    p: 4,
    borderRadius: "10px",
  };

  //status change handle code
  useEffect(() => {
    if (rowData) {
      makeStatusList(rowData);
    }
  }, [rowData]);

  const makeStatusList = (listing) => {
    const newObj = {};

    listing?.forEach((obj) => {
      newObj[obj._id] = obj.status === "active" ? true : false;
    });
    console.log(
      "🚀 ~ file: userManagement.jsx:248 ~ makeuserStatusArray ~ newObj:",
      newObj
    );
    setStatusObj(newObj);
    setPreviousState(newObj);
  };

  //status changes
  // const handleStatusChange = async (e, id) => {

  //   setStatusObj(prev => {
  //     setPreviousState(prev)
  //     return ({ ...prev, [id]: e.target.checked })
  //   })

  //   const status = e.target.checked ? "active" : "inactive"

  //   try {
  //     const resp = await universalFun("patch", `users/update-subuser-status/${id}`)
  //     console.log("Status Updated", resp)
  //     getTableData()
  //   } catch (error) {
  //     console.log(error)
  //     // notification.error({
  //     //   message : "Something went wrong"
  //     // })
  //     setStatusObj(previousState)
  //     // getData(null)
  //   }

  // }

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={3}
            >
              <SoftTypography variant="h6">Garage list</SoftTypography>
              <SoftBox display="flex" alignItems="center" gap="1rem">
                <Dropdown
                  className="fltrDrop"
                  onSelect={(value) => setStatus(value)}
                >
                  <Dropdown.Toggle
                    id="dropdown-button-dark-example1"
                    variant="primary"
                  >
                    <FilterAltIcon />
                    {/* <FilterListIcon /> */}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      className={status === "" ? "active" : ""}
                      eventKey=""
                    >
                      All..
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={status === "active" ? "active" : ""}
                      eventKey="active"
                    >
                      Active
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={status === "inactive" ? "active" : ""}
                      eventKey="inactive"
                    >
                      InActive
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown
                  className="fltrDrop"
                  onSelect={(value) => setSort(value)}
                >
                  <Dropdown.Toggle
                    id="dropdown-button-dark-example1"
                    variant="primary"
                  >
                    {!sort ? (
                      <FilterListIcon />
                    ) : (
                      <FilterListIcon style={{ transform: "rotate(180deg)" }} />
                    )}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      className={!sort ? "active" : ""}
                      eventKey=""
                    >
                      Descending
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={sort ? "active" : ""}
                      eventKey="asc"
                    >
                      Ascending
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

                <SoftBox pr={1}>
                  <SoftInput
                    placeholder="Type here..."
                    icon={{ component: "search", direction: "left" }}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </SoftBox>
                <Link to="/Add-Garage">
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ color: "white" }}
                    fontWeight="medium"
                  >
                    Add Garage
                  </Button>
                </Link>
              </SoftBox>
            </SoftBox>

            <SoftBox
              className="TableContainer"
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <SoftBox className="table-responsive">
                <Table columns={column} rows={createdRow} />
                <TablePagination
                  component="div"
                  count={listCount}
                  page={currentPage}
                  onPageChange={handlePageChange}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  rowsPerPageOptions={[5, 10, 15, 20, 25]}
                />
              </SoftBox>
            </SoftBox>
          </Card>
        </SoftBox>
      </SoftBox>
      <Modal
        open={openDeleteModal}
        onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="delete-modal"
      >
        <Box sx={style}>
          <Typography className="modal-image">
            <div className="delete-message">
              Are you sure you want to delete ?
            </div>
          </Typography>
          <Typography
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            <Button
              variant="contained"
              onClick={deleteFunction}
              color="primary"
              style={{ color: "white" }}
              fontWeight="medium"
            >
              Yes
            </Button>
            <Button
              variant="contained"
              onClick={handleCloseDelete}
              color="primary"
              style={{ color: "white" }}
              fontWeight="medium"
            >
              No
            </Button>
          </Typography>
        </Box>
      </Modal>
      <Footer />
    </DashboardLayout>
  );
}

export default StoreManagement;
