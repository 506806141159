/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";


// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";

import { Dropdown } from "react-bootstrap";

// Data
// import authorsTableData from "layouts/tables/data/authorsTableData";
// import projectsTableData from "layouts/tables/data/projectsTableData";
import Store from "./data/store";
import StoreData from "./data/storeData";
//Button
import Button from "@mui/material/Button";


//icons
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

//Links

import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

import { useState, useEffect, useMemo, useCallback } from "react";
import debounce from 'lodash/debounce';


import SoftAvatar from "components/SoftAvatar";
import Switch from "@mui/material/Switch";
import IconButton from "@mui/material/IconButton";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import EmailIcon from "@mui/icons-material/Email";

import { deleteUserService, universalFun } from "../../util/httpService";
import { userListService } from "../../util/httpService";
import { editUserService } from "../../util/httpService";

import SoftPagination from "components/SoftPagination";

import fallBackImage from "assets/images/FallbackImage.jpg";
import team2 from "assets/images/team-2.jpg";
import { toast } from "react-toastify";
import DeleteModal from "components/DeleteModal";
import { TablePagination } from "@mui/material";
import EmptyComponent from "components/Empty";

function UserManagement() {
  const params = useParams();

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleDeleteOpen = () => setShowDeleteModal(true);
  const handleDeleteClose = () => setShowDeleteModal(false);

  const [deleteId, setDeleteId] = useState("");

  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("");
  const [status, setStatus] = useState("");

   //pagination
   const [currentPage, setCurrentPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [listCount, setListCount] = useState(100);

   const handlePageChange = (event, value) => {
    // 'value' represents the selected page
    setCurrentPage(value);
    // You can perform any additional actions here based on the selected page
    console.log(`Selected page: ${value}`);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const [statusObj, setStatusObj] = useState([]);
  const [previousState, setPreviousState] = useState([]);

  const column = [
    { name: "Sr.No.", align: "center" },
    { name: "user", align: "left" },
    { name: "user type", align: "left" },
    { name: "phone no.", align: "left" },
    { name: "Status", align: "center" },
    { name: "action", align: "center" },
  ];

  const [rowData, setRow] = useState([]);

  const paginationFunc = (event) => {
    console.log("pagination event", event);
  };

  function Author({ image, name, email }) {
    return (
      <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
        <SoftBox mr={2}>
          <SoftAvatar src={image} alt={name} size="sm" variant="rounded" />
        </SoftBox>
        <SoftBox display="flex" flexDirection="column">
          <SoftTypography variant="button" fontWeight="medium">
            {name}
          </SoftTypography>
          <SoftTypography variant="caption" color="secondary">
            {email}
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    );
  }

  async function getTableData(debouncedSearch) {
    let obj = {
      page: currentPage + 1,
      limit: rowsPerPage,
      search: debouncedSearch || search,
      sort: sort,
      status: status,
    };

    try {
      const resp = await universalFun(
        "get",
        `subsusers/${params.id}`,
        null,
        obj
      );
      console.log(resp);
      setRow(resp?.data?.data?.items);
      setListCount(resp?.data?.data?.totalItems)
    } catch (err) {
      console.log(err);
    }
  }

  const createdRow = useMemo(() => {
    if (Array.isArray(rowData)) {
      return rowData?.map((ele, index) => ({
        "Sr.No.": (
          <SoftTypography
            variant="caption"
            color="secondary"
            fontWeight="medium"
          >
            {index + 1 + (currentPage * rowsPerPage)}
          </SoftTypography>
        ),
        user: (
          <Author
            image={
              ele.image && ele.image !== ""
                ? process.env.REACT_APP_IMAGE_URL + "sub_users/" + ele.image
                : fallBackImage
            }
            name={ele.first_name + " " + ele.last_name}
            email={ele.email}
          />
        ),

        "user type": (
          <SoftTypography
            variant="caption"
            color="secondary"
            fontWeight="medium"
          >
            {ele.user_type === "super_user"
              ? "Super User"
              : ele.user_type === "body_work_subcontractor"
              ? "DSP Constractor"
              : ele.user_type === "body_repairer"
              ? "Technician"
              : ele.user_type === "expert"
              ? "Expert"
              : ele.user_type === "body"
              ? "Body"
              : ele.user_type === "bodywork_team"
              ? "Body Work Team"
              : ""}
          </SoftTypography>
        ),

        "phone no.": (
          <SoftTypography
            variant="caption"
            color="secondary"
            fontWeight="medium"
          >
            {ele.phone_number}
          </SoftTypography>
        ),

        Status: (
          <SoftBox>
            <Switch
              checked={statusObj[ele?._id]}
              onChange={(event) => statusChangeFunction(event, ele._id)}
            />
          </SoftBox>
        ),

        action: (
          <SoftTypography aria-label="action">
            <Link to={"/View-User/" + ele._id} className="actionIcon">
              <RemoveRedEyeIcon />
            </Link>
            <Link to={"/Edit-User/" + ele._id} className="actionIcon">
              <EditRoundedIcon />
            </Link>
            {/* <Link to="/" className="actionIcon" >
                <EmailIcon />
              </Link> */}
            <IconButton
              onClick={(event) => {
                handleDeleteOpen();
                setDeleteId(ele._id);
              }}
              className="actionIcon"
            >
              <DeleteIcon />
            </IconButton>
            {/* <Link to="/" className="actionIcon">
                <PeopleAltIcon />
              </Link> */}
          </SoftTypography>
        ),
      }));
    }
  }, [rowData, statusObj]);

  // const { columns: prCols, rows: prRows } = Store;

  useEffect(() => {
    getTableData();
  }, [currentPage, rowsPerPage , sort , status]);

  const handleSearchChange = (e)=>{
   const value = e.target.value;
   setSearch(value)

   debouncedSearch(value)

  }

  const debouncedSearch = useCallback(
    debounce((value) => {
      getTableData(value);
    }, 500),
    [] // empty dependency array if getTableData doesn't depend on any props or state
  );
  const deleteFunction = async () => {
    //  let obj = {
    //   id:deleteId
    //  }

    try {
      const resp = await deleteUserService(deleteId);
      console.log(" delete api ", resp);
      if (resp?.status === 200) {
        getTableData();
        toast.success("User Deleted Successfully");
        handleDeleteClose();
      }
    } catch (error) {}
  };

  const statusChangeFunction = async (event, id) => {

    setStatusObj(prev => {
      setPreviousState(prev)
      return ({ ...prev, [id]: event.target.checked })
    })

    let obj = {
      // id: id,
      is_active: event.target.checked === true ? "active" : "inactive",
    };

    try {
      const resp = await editUserService(obj , id);
      console.log(" delete api ", resp);
      if (resp?.status === 200) {
        getTableData();
        // toast.success("User Status Changed Successfully");
      }
    } catch (error) {
      setStatusObj(previousState)
    }
  };

  //status change handle code
  useEffect(() => {
    if (rowData) {
      makeStatusList(rowData);
    }
  }, [rowData]);

  const makeStatusList = (listing) => {
    const newObj = {};

    listing?.forEach((obj) => {
      newObj[obj._id] = obj.is_active === "active" ? true : false;
    });

    setStatusObj(newObj);
    setPreviousState(newObj);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={3}
            >
              <SoftTypography variant="h6">User List</SoftTypography>
              <SoftBox display="flex" alignItems="center" gap="1rem">
              <Dropdown className="fltrDrop" onSelect={(value) => setStatus(value)}>
                  <Dropdown.Toggle id="dropdown-button-dark-example1" variant="primary">
                    <FilterAltIcon />
                    {/* <FilterListIcon /> */}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item  className = {status === "" ? "active" :""} eventKey="">All..</Dropdown.Item>
                    <Dropdown.Item className = {status === "active" ? "active" :""} eventKey="active">Active</Dropdown.Item>
                    <Dropdown.Item className = {status === "inactive" ? "active" :""} eventKey="inactive">InActive</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown className="fltrDrop" onSelect={(value) => setSort(value)}>
                  <Dropdown.Toggle id="dropdown-button-dark-example1" variant="primary">
                    {!sort ? <FilterListIcon /> :
                      <FilterListIcon style={{ transform: "rotate(180deg)" }} />}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item  className = {!sort ? "active" :""} eventKey="">Descending</Dropdown.Item>
                    <Dropdown.Item  className = {sort  ? "active" :""} eventKey="asc">Ascending</Dropdown.Item>

                  </Dropdown.Menu>
                </Dropdown>

                <SoftBox pr={1}>
                  <SoftInput
                    placeholder="Type here..."
                    icon={{ component: "search", direction: "left" }}
                    value={search}
                    onChange={handleSearchChange}
                  />
                </SoftBox>
                <Link to={"/add-User/" + params.id}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ color: "white" }}
                  fontWeight="medium"
                >
                  Add User
                </Button>
              </Link>
              </SoftBox>
             
            </SoftBox>

            <SoftBox
             style={{ minHeight: '400px' }}
              className="TableContainer"
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
            { 
              rowData?.length > 0
              ?
              <Table
              
               columns={column} rows={createdRow} />
               :
              <EmptyComponent />
            }
              
              
               
            </SoftBox>
            <TablePagination
                  component="div"
                  count={listCount}
                  page={currentPage}
                  onPageChange={handlePageChange}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  rowsPerPageOptions={[5,10, 15, 20, 25]}
                />
          </Card>
        </SoftBox>

        {/* <SoftPagination color="secondary">
         <SoftPagination item click={paginationFunc}>{'<'}
         </SoftPagination>
         <SoftPagination item active secondary>1</SoftPagination>
         <SoftPagination item onChange={paginationFunc}>2</SoftPagination>
         <SoftPagination item>3</SoftPagination>
         <SoftPagination item> {'>'}
         </SoftPagination>
       </SoftPagination> */}
      </SoftBox>
      <DeleteModal
        show={showDeleteModal}
        handleConfirm={deleteFunction}
        handleClose={handleDeleteClose}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default UserManagement;
