import { createSlice } from '@reduxjs/toolkit';



const navbarSlice = createSlice({
  name: 'navbar',
  initialState: {
    unseenNotify : null,
    unseenNotifyCount : null  
  },
  reducers: {
    setUnseenNotify : (state, action) => {
      state.unseenNotify = action.payload?.data;
      state.unseenNotifyCount = action.payload?.unseen_counts;
     
    },
    setUnseenNotifyCount : (state, action) => {
      state.unseenNotifyCount = action.payload;
     
    },
  },
});


export const { setUnseenNotify ,setUnseenNotifyCount } = navbarSlice.actions;
export default navbarSlice.reducer;