import { Box, Button, Modal, Typography } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import IconButton from "@mui/material/IconButton";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import SoftInput from "components/SoftInput";
import AddIcon from "@mui/icons-material/Add";
import placeholderUser from "../../assets/images/placeholderUser.jpeg";
// import ChipDelete from "@mui/joy/ChipDelete";
import InputAdornment from "@mui/material/InputAdornment";

export const CarModal = ({
  open,
  handleClose,
  style,
  inputFields,
  handleInputChange,
  handleAddField,
  handleImageUpload,
  makeName,
  image,
  setMakeName,
  brandName,
  setBrandName,
  handleSave,
  uploadedImage,
  modalName,
  handleModelDelete
}) => {

  const handleSubmit =(e) =>{
     e.preventDefault()
    if(modalName==='view'){
      handleClose()
    }else{
      handleSave()
    }
  }
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="add-modal"
    >
    <form onSubmit={handleSubmit}>
      <Box sx={style} className="modal-scrol">
        <Typography className="modal-image">
          <div className="image-wrap">
            <div className="image">
              {uploadedImage ? (
                <img
                  src={uploadedImage}
                  alt="Uploaded "
                  style={{ width: "120px", height: "120px", maxWidth: "100%" }}
                 
                />
              ) : (
                <img
                  src={image ? process.env.REACT_APP_IMAGE_URL + "carlogos/" + image : placeholderUser} // Placeholder image URL
                  alt="logo"
                  style={{ width: "120px", height: "120px", maxWidth: "100%" }}
               
                />
              )}
            </div>
            <label className="input-file" htmlFor="file-input">
              <EditRoundedIcon />
              <input type="file" onChange={handleImageUpload}  accept = "image/*"/>
            </label>
            <div className="upload-image mt-2 pr-2" style={{paddingRight: "10px"}}>
              <h3>Brand Image</h3>
            </div>
          </div>
        </Typography>

        <Typography sx={{ mt: 2 }}>
          <SoftBox mb={2} display="flex" flexDirection="column">
            <SoftTypography
              component="label"
              fontSize="13px"
              mb={1}
              variant="caption"
              fontWeight="bold"
            >
              Category Type
            </SoftTypography>
            <SoftInput
              type="text"
              className="TextField"
              name="question"
              onChange={(e) => setMakeName(e.target.value)}
              placeholder=""
              value={makeName}
              disabled={modalName === "view"}
              required
            />
          </SoftBox>
        </Typography>
        <Typography sx={{ mt: 2 }}>
          <SoftBox mb={2} display="flex" flexDirection="column">
            <SoftTypography
              component="label"
              fontSize="13px"
              mb={1}
              variant="caption"
              fontWeight="bold"
            >
              Brand Name
            </SoftTypography>
            <SoftInput
              type="text"
              className="TextField"
              name="question"
              placeholder="enter brand name"
              onChange={(e) => setBrandName(e.target.value)}
              value={brandName}
              disabled={modalName === "view"}
              required
            />
          </SoftBox>
        </Typography>
        <Typography sx={{ mt: 2 }}>
          <SoftBox mb={2} display="flex" flexDirection="column">
            <SoftBox
              className="model-name"
              display="flex"
              justifyContent="space-between"
            >
              <SoftTypography
                component="label"
                fontSize="13px"
                mb={1}
                variant="caption"
                fontWeight="bold"
              >
                Create Car Models
              </SoftTypography>
              {modalName !== "view" && (
                <SoftTypography className="add-icon" onClick={handleAddField}>
                  <AddIcon />
                </SoftTypography>
              )}
            </SoftBox>
            {inputFields.map((value, index) => (
              <div className="d-flex">
                <SoftInput
                  style={{ marginBottom: "3%" }}
                  key={index}
                  type="text"
                  className="TextField"
                  name={`question-${index}`}
                  placeholder={`Car Model ${index + 1}`}
                  value={value}
                  disabled={modalName === "view"}
                  onChange={(e) => handleInputChange(index, e.target.value)}
                />
                {modalName !== "view" &&
                <IconButton
                className="delete-models"
                  aria-label="delete"
                  onClick={() => handleModelDelete(index)} // Define a function to handle the delete action
                >
                  <DeleteIcon />
                </IconButton>
                }
              </div>
            ))}
          </SoftBox>
        </Typography>
        <Typography>
          <Button
            variant="contained"
            // onClick={handleSave}
            type="submit"
            color="primary"
            style={{ color: "white" }}
            fontWeight="medium"
          >
            {modalName ==="view" ? "Close" : "Save"}
          </Button>
        </Typography>
      </Box>
      </form>
    </Modal>
  );
};
