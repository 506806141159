/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useMemo } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard React examples
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Soft UI Dashboard React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Soft UI Dashboard React routes
import routes from "routes";

// Soft UI Dashboard React contexts
import {
  useSoftUIController,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";

// Images
import brand from "assets/images/main-logo.png";
import "react-toastify/dist/ReactToastify.css";
import "./style.css";

import ResetPassword from "./layouts/authentication/reset-password/index";
import SignIn from "./layouts/authentication/sign-in/index";
import AddUser from "./layouts/add-user/index";
import ViewUser from "./layouts/view-user-details/index";
import UserManagement from "layouts/user-management";
import AddStore from "layouts/garage-managment/add-store";
import EditStore from "layouts/garage-managment/edit-store";
import ViewStore from "layouts/garage-managment/view-store";
import ViewContactUs from "layouts/view-contact-us";
import EditSubscription from "layouts/general-management/editSubscription/index";
import ViewSubscription from "layouts/general-management/viewSubscription";
import AddSubscription from "layouts/general-management/addSubscription";
import EditVehicle from "layouts/editVehicle";
import ViewVehicle from "layouts/viewVehicle";
import EditUser from "layouts/edit-user";
import EditProfile from "layouts/edit-profile";
import { ToastContainer } from "react-toastify";
import { universalFun } from "util/httpService";
import { useDispatch } from "react-redux";
import { setUnseenNotify } from "store/slices/navbarSlice";
import Billing from "layouts/billing";
import GarageDetails from "layouts/billing/components/GarageDetails";
export default function App() {
  const dispatcher = useDispatch();

  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, direction, layout, openConfigurator, sidenavColor } =
    controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  const token = localStorage.getItem("token");

  //getting unseen notifications
  const getNotifications = async () => {
    try {
      const resp = await universalFun("get", "get-notifications?limit=3");
      console.log(resp);
      dispatcher(setUnseenNotify(resp?.data?.response));
    } catch (error) {}
  };

  useEffect(() => {
    if (token) {
      getNotifications();
    }
  }, []);

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={route.component}
            key={route.key}
          />
        );
      }

      return null;
    });

  const configsButton = (
    <SoftBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.5rem"
      height="3.5rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="default" color="inherit">
        settings
      </Icon>
    </SoftBox>
  );

  return direction === "rtl" ? (
    <CacheProvider value={rtlCache}>
      <ThemeProvider theme={themeRTL}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Sidenav
              brandName="test"
              color={sidenavColor}
              brand={brand}
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
            {/* {configsButton} */}
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          {getRoutes(routes)}
          <Route path="*" element={<Navigate to="/dashboard" />} />
          <Route path="/garage-details/:id" element={GarageDetails} />
        </Routes>
      </ThemeProvider>
    </CacheProvider>
  ) : token !== null ? (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            brandName="test"
            color={sidenavColor}
            brand={brand}
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator />
          {/* {configsButton} */}
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Routes>
        {getRoutes(routes)}
        <Route path="*" element={<Navigate to="/dashboard" />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        {/* <Route path="/sign-in" element={<SignIn />} /> */}
        <Route path="/add-User/:id" element={<AddUser />} />
        <Route path="/Edit-User/:id" element={<EditUser />} />
        <Route path="/View-User/:id" element={<ViewUser />} />
        <Route path="/User-Management/:id" element={<UserManagement />} />
        <Route path="/Add-Garage" element={<AddStore />} />
        <Route path="/Edit-Store/:id" element={<EditStore />} />
        <Route path="/View-Store/:id" element={<ViewStore />} />
        <Route path="/View-Contact-Us/:id" element={<ViewContactUs />} />
        <Route path="/Edit-Subscription/:id" element={<EditSubscription />} />
        <Route path="/View-Subscription/:id" element={<ViewSubscription />} />
        <Route path="/Add-Subscription" element={<AddSubscription />} />
        <Route path="/Edit-Vehicle" element={<EditVehicle />} />
        <Route path="/View-Vehicle" element={<ViewVehicle />} />
        <Route path="/Edit-Profile" element={<EditProfile />} />
        <Route path="/billing/:id" element={<Billing />} />
        <Route path="/garage-details/:id" element={<GarageDetails />} />
      </Routes>
      {/* <ToastContainer /> */}
    </ThemeProvider>
  ) : (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="*" element={<Navigate to="/sign-in" />} />
      </Routes>
      {/* <ToastContainer /> */}
    </ThemeProvider>
  );
}
