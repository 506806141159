/* eslint-disable react/prop-types */
// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import SoftBadge from "components/SoftBadge";
import Switch from "@mui/material/Switch";
import { useState } from "react";

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";

//logo
import BMWImage from 'assets/images/BMW.png';

//icons
import IconButton from '@mui/material/IconButton';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DeleteIcon from '@mui/icons-material/Delete'
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import EmailIcon from '@mui/icons-material/Email';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';


//Links

import { Link } from "react-router-dom";



function Author({ image, name, email }) {


  return (
    <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
      <SoftBox mr={2}>
        <SoftAvatar src={image} alt={name} size="sm" variant="rounded" />
      </SoftBox>
      <SoftBox display="flex" flexDirection="column">
        <SoftTypography variant="button" fontWeight="medium">
          {name}
        </SoftTypography>
        <SoftTypography variant="caption" color="secondary">
          {email}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
}



function Function({ job, org }) {
  return (
    <SoftBox display="flex" flexDirection="column">
      <SoftTypography variant="caption" fontWeight="medium" color="text">
        {job}
      </SoftTypography>
      <SoftTypography variant="caption" color="secondary">
        {org}
      </SoftTypography>
    </SoftBox>
  );
}

const styles = {
  icon: {
    width: "1.3rem", // set the desired width of the icon
  }
};



const StoreData = {



  columns: [
    { name: "user", align: "left" },
    { name: "company", align: "left" },
    { name: "company logo", align: "left" },
    { name: "phone no.", align: "left" },
    { name: "business email", align: "left" },
    { name: "siret no.", align: "left" },
    { name: "Status", align: "center" },
    { name: "action", align: "center" },

  ],

  rows: [
    {
      "user": <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        John Michael
      </SoftTypography>,

      "phone no.": <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        7979881545
      </SoftTypography>,

      "company": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          ABC PVT LTD
        </SoftTypography>
      ),
      "company logo": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          <img className="logo_bmw" src={BMWImage} />
        </SoftTypography>
      ),
      "siret no.": <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        7979881545
      </SoftTypography>,
      "business email": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          euro123@gmail.com
        </SoftTypography>
      ),
      "Status": (
        <SoftBox>
          <Switch />
        </SoftBox>
      ),

      "action": (
        <IconButton aria-label="action">
          <Link to="/View-Store" className="actionIcon">
            <RemoveRedEyeIcon />
          </Link>
          <Link to="/Edit-Store" className="actionIcon" >
            <EditRoundedIcon />
          </Link>
          {/* <Link to="/" className="actionIcon" >
            <EmailIcon />
          </Link> */}
          <Link to="/" className="actionIcon">
            <DeleteIcon />
          </Link>
          <Link to="/User-Management" className="actionIcon">
            <PeopleAltIcon />
          </Link>
        </IconButton>
      ),
    },
    {
      "user":
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          Alexa Liras
        </SoftTypography>,

      "company logo": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          <img className="logo_bmw" src={BMWImage} alt="logo"/>
        </SoftTypography>
      ),

      "phone no.": <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        7979881545
      </SoftTypography>,

      "company": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          ABC PVT LTD
        </SoftTypography>
      ),

      "business email": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          euro123@gmail.com
        </SoftTypography>
      ),
      "siret no.": <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        7979881545
      </SoftTypography>,
      "Status": (
        <SoftBox>
          <Switch />
        </SoftBox>
      ),

      "action": (
        <IconButton aria-label="action">
          <Link to="/View-Store" className="actionIcon">
            <RemoveRedEyeIcon />
          </Link>
          <Link to="/Edit-Store" className="actionIcon" >
            <EditRoundedIcon />
          </Link>
          {/* <Link to="/" className="actionIcon" >
            <EmailIcon />
          </Link> */}
          <Link to="/" className="actionIcon">
            <DeleteIcon />
          </Link>
          <Link to="/User-Management" className="actionIcon">
            <PeopleAltIcon />
          </Link>
        </IconButton>
      ),
    },
    {
      "user": <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        Laurent Perrier
      </SoftTypography>,

      "user type": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          Technician
        </SoftTypography>
      ),
      "company logo": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          <img className="logo_bmw" src={BMWImage} />
        </SoftTypography>
      ),
      "phone no.": <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        7979881545
      </SoftTypography>,

      "company": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          ABC PVT LTD
        </SoftTypography>
      ),

      "business email": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          euro123@gmail.com
        </SoftTypography>
      ),
      "siret no.": <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        7979881545
      </SoftTypography>,
      "Status": (
        <SoftBox>
          <Switch />
        </SoftBox>
      ),

      "action": (
        <IconButton aria-label="action">
          <Link to="/View-Store" className="actionIcon">
            <RemoveRedEyeIcon />
          </Link>
          <Link to="/Edit-Store" className="actionIcon" >
            <EditRoundedIcon />
          </Link>
          {/* <Link to="/" className="actionIcon" >
            <EmailIcon />
          </Link> */}
          <Link to="/" className="actionIcon">
            <DeleteIcon />
          </Link>
          <Link to="/User-Management" className="actionIcon">
            <PeopleAltIcon />
          </Link>
        </IconButton>
      ),
    },
    {
      "user": <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        Michael Levi
      </SoftTypography>,


      "company logo": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          <img className="logo_bmw" src={BMWImage} />
        </SoftTypography>
      ),
      "phone no.": <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        7979881545
      </SoftTypography>,

      "company": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          ABC PVT LTD
        </SoftTypography>
      ),


      "business email": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          euro123@gmail.com
        </SoftTypography>
      ),
      "siret no.": <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        7979881545
      </SoftTypography>,
      "Status": (
        <SoftBox>
          <Switch />
        </SoftBox>
      ),

      "action": (
        <IconButton aria-label="action">
          <Link to="/View-Store" className="actionIcon">
            <RemoveRedEyeIcon />
          </Link>
          <Link to="/Edit-Store" className="actionIcon" >
            <EditRoundedIcon />
          </Link>
          {/* <Link to="/" className="actionIcon" >
            <EmailIcon />
          </Link> */}
          <Link to="/" className="actionIcon">
            <DeleteIcon />
          </Link>
          <Link to="/User-Management" className="actionIcon">
            <PeopleAltIcon />
          </Link>
        </IconButton>
      ),
    },
  ],
};

export default StoreData;
