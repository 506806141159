/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard React components
import MasterCard from "examples/Cards/MasterCard";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Billing page components
import PaymentMethod from "layouts/billing/components/PaymentMethod";
import Invoices from "layouts/billing/components/Invoices";
import BillingInformation from "layouts/billing/components/BillingInformation";
import Transactions from "layouts/billing/components/Transactions";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { universalFun } from "util/httpService";
import EmptyComponent from "components/Empty";

function Billing() {
  const param = useParams();
  const garageId = param?.id;

  const [cardsDetails, setCardsDetails] = useState([]);
  const [invoiceDetails, setInvoiceDetails] = useState([]);
  console.log("🚀 ~ Billing ~ invoiceDetails:", invoiceDetails);

  // ********************* getting cards details ****************
  const getCardsDetails = async () => {
    if (!garageId) {
      console.log("id not found");
      return;
    }
    try {
      const resp = await universalFun("get", `billing-info/${garageId}`);
      setCardsDetails(resp?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  // ***********************getting invoice details ****************
  const getInvoiceDetails = async () => {
    if (!garageId) {
      console.log("id not found");
      return;
    }
    try {
      const resp = await universalFun("get", `client-transactions/${garageId}`);
      setInvoiceDetails(resp?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCardsDetails();
    getInvoiceDetails();
  }, []);

  //making expire format for card
  const makeCardExpire = (month, year) => {
    if (!month || !year) {
      console.log("expire not recive");
      return "";
    }
    const formatedMonth = month.toString().padStart(2, "0");

    const formatedYear = year.toString().slice(-2);

    return `${formatedMonth}/${formatedYear}`;
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox mt={4}>
        <SoftBox mb={1.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={8}>
              <Grid container spacing={3}>
            
                  {/* <MasterCard number={4562112245947852} holder="jack peterson" expires="11/22" /> */}
                  {cardsDetails.length > 0 ? (
                    
                    cardsDetails.map((card , index) => (
                      <Grid item key ={index} xs={12} xl={6}>
                      <MasterCard
                        number={card?.last$}
                        holder="Rahul"
                        expires={makeCardExpire(
                          card?.exp_month,
                          card?.exp_year
                        )}
                      /> 
                   
                      </Grid>
                     
                    ) 
                    )
                   
                  ) : (
                    <Grid item  xs={12} xl={12}>
                    <EmptyComponent message = "No Card Found" />
                    </Grid>
                  )}
            
                {/* <Grid item xs={12} md={6} xl={3}>
                  <DefaultInfoCard
                    icon="account_balance"
                    title="salary"
                    description="Belong Interactive"
                    value="+$2000"
                  />
                 
                </Grid> */}
                <Grid item xs={12} md={6} xl={3}>
                  {/* <DefaultInfoCard
                    icon="paypal"
                    title="paypal"
                    description="Freelance Payment"
                    value="$455.00"
                  />
                   <DefaultInfoCard
                    icon=""
                    title=""
                    description=" "
                    value=""
                  /> */}
                </Grid>
                {/* <Grid item xs={12}>
                  <PaymentMethod />
                </Grid> */}
              </Grid>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Invoices invoices={invoiceDetails} />
            </Grid>
          </Grid>
        </SoftBox>
        {/* <SoftBox my={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={7}>
              <BillingInformation />
            </Grid>
            <Grid item xs={12} md={5}>
              <Transactions />
            </Grid>
          </Grid>
        </SoftBox> */}
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Billing;
