/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";

// Data
// import authorsTableData from "layouts/tables/data/authorsTableData";
// import projectsTableData from "layouts/tables/data/projectsTableData";
import Store from "./data/store";
import StoreData from "./data/storeData";
//Button
import Button from '@mui/material/Button';

//Links

import { Link } from "react-router-dom";
import { Modal } from "@mui/material";
import SoftButton from "components/SoftButton";
import { useState } from "react";
import SoftInput from "components/SoftInput";
// import SoftTexrarea from "components/SoftTextarea";
import CloseIcon from "@mui/icons-material/Close";
import Box from '@mui/material/Box';
import Switch from "@mui/material/Switch";
// import { useState } from "react";

import * as React from 'react';

//icons
import IconButton from '@mui/material/IconButton';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DeleteIcon from '@mui/icons-material/Delete'
import EditRoundedIcon from '@mui/icons-material/EditRounded';
// import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { universalFun } from "util/httpService";
import { toast } from "react-toastify";
import DeleteModal from "components/DeleteModal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function Subscription() {
  const [data, setData] = useState([]);
  const [deleteId , setDeleteId] = useState('');

  //delete modal 
  const [showDeleteModal ,setShowDeleteModal] = useState(false)

  const handleDeleteClose = ()=> setShowDeleteModal(false)

  // Use Effect-
  React.useEffect(() => {
    getSubscriptionList()
  }, [])

  // Subscription List-
  const getSubscriptionList = async () => {
    try{
      const result = await universalFun("POST", "subscription", ({type: "get"}));
      console.log("result", result?.data?.response)
      setData(result?.data?.response)
    }
    catch(error){
      console.log(error);
    }
  }

  // Delete Subscription-
  const deleteSubscription = async () => {
    const body =({type: "destroy", id : deleteId})
    try{
      const result = await universalFun("POST", "subscription", body);
      if(result?.data?.code === 200){
        toast.success(result?.data?.response?.msg);
        getSubscriptionList()
        handleDeleteClose()
      }
    }
    catch(error){
      console.log(error);
    }
  } 

  const handleClick = (event) => {
    console.log("hell")
    // Check if the clicked element is an anchor tag
    if (event.target.tagName.toLowerCase() === 'a') {
      // Prevent the propagation of the click event
      event.stopPropagation();
    }
    // Add other logic if needed
  };

  const columns = [
    { name: "s.no.", align: "left" },
    { name: "plan name", align: "left" },
    { name: "description", align: "left" },
    { name: "price", align: "left" },
    // { name: "features", align: "left" },
    { name: "action", align: "center" },

  ]

  const rows = data?.map((el, index) => ({
    "s.no.": <SoftTypography variant="caption" color="secondary" fontWeight="medium" className="rspnv_tbls">
      {index + 1}
    </SoftTypography>,

    "plan name": <SoftTypography variant="caption" color="secondary" fontWeight="medium">
      {el?.plan_name}
    </SoftTypography>,

    "description": (
      <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        {el?.description}
      </SoftTypography>
    ),
    "price": <SoftTypography variant="caption" color="secondary" fontWeight="medium">
      {el?.price}
    </SoftTypography>,

    "features": (
      <SoftTypography variant="caption" color="secondary" fontWeight="medium" 
        onClick={handleClick}
      dangerouslySetInnerHTML={{ __html: el?.features }}>
      </SoftTypography>


    ),
    "Status": (
      <SoftBox>
        <Switch />
      </SoftBox>
    ),

    "action": (
      <IconButton aria-label="action">
        <Link to={`/View-Subscription/${el?._id}`} className="actionIcon">
          <RemoveRedEyeIcon />
        </Link>
        <Link to={`/Edit-Subscription/${el?._id}`} className="actionIcon" >
          <EditRoundedIcon />
        </Link>
        {/* <Link to="/" className="actionIcon" >
          <EmailIcon />
        </Link> */}
        <Link to="" onClick={() =>{  setDeleteId(el?._id); setShowDeleteModal(true)}} className="actionIcon">
          <DeleteIcon />
        </Link>
      </IconButton>
    ),
  }))



  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftTypography variant="h6">Subscription Plan</SoftTypography>
              <Link to="/Add-Subscription">
                <Button variant="contained" color="primary" style={{ color: "white" }} fontWeight="medium" >
                  Add Subscription Plan
                </Button>
              </Link>
            </SoftBox>
            <SoftBox className="TableContainer"
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >

              <SoftBox className="table-responsive">

                <Table columns={columns} rows={rows} />
              </SoftBox>

            </SoftBox>
          </Card>
        </SoftBox>

      </SoftBox>
       {/* ***********model************** */}
       <DeleteModal
        show = {showDeleteModal}
        handleConfirm ={deleteSubscription}
        handleClose ={handleDeleteClose}
        />
      <Footer />

    </DashboardLayout>
  );
}

export default Subscription;


