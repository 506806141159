import React from "react";
import MasterCardImg from "../../../../../../assets/images/mastercard.svg";
import { RiDeleteBin6Line } from "react-icons/ri";

const SavedCards = () => {
  return (
    <div className="saved-cards shadow">
      <div className="flex justify-between items-center">
        <h3>Saved cards</h3>
        <button>Add card</button>
      </div>
      <div className="flex justify-between master-div items-center">
        <div className="flex justify-start">
          <img src={MasterCardImg} alt="" />
          <div className="flex flex-col">
            <h5>Mastercard ******9120</h5>
            <p>Exp. 02/22</p>
          </div>
        </div>
        <RiDeleteBin6Line className="icon-red" />
      </div>
      <div className="flex justify-between master-div items-center">
        <div className="flex justify-start">
          <img src={MasterCardImg} alt="" />
          <div className="flex flex-col">
            <h5>Mastercard ******9120</h5>
            <p>Exp. 02/22</p>
          </div>
        </div>
        <RiDeleteBin6Line className="icon-red" />
      </div>
      <div className="flex justify-between master-div items-center">
        <div className="flex justify-start">
          <img src={MasterCardImg} alt="" />
          <div className="flex flex-col">
            <h5>Mastercard ******9120</h5>
            <p>Exp. 02/22</p>
          </div>
        </div>
        <RiDeleteBin6Line className="icon-red" />
      </div>
      <div className="flex justify-between master-div items-center">
        <div className="flex justify-start">
          <img src={MasterCardImg} alt="" />
          <div className="flex flex-col">
            <h5>Mastercard ******9120</h5>
            <p>Exp. 02/22</p>
          </div>
        </div>
        <RiDeleteBin6Line className="icon-red" />
      </div>
    </div>
  );
};

export default SavedCards;
