/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";
// import ReactDOM from "react-dom";
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import App from "App";
import 'bootstrap/dist/css/bootstrap.min.css';

// Soft UI Dashboard React Context Provider
import { SoftUIControllerProvider } from "context";
import { ToastContainer } from 'react-toastify';
import store from "store/store";
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { requestForToken } from "./firebase";

// console.log("env",process.env.REACT_APP_SERVER_URL)
const root = createRoot(document.getElementById('root'));

const queryClient = new QueryClient();

requestForToken()
root.render(
  <BrowserRouter>
    <Provider store={store}>
    <QueryClientProvider client={queryClient}>
    <SoftUIControllerProvider>
    <ToastContainer
          autoClose={1000} // Close after 1 second
          hideProgressBar // Hide the loading bar
          progressClassName="transparent-progress-bar" // Use a transparent progress bar
          position="bottom-center" // Position at the bottom center
        />
      <App />
      {/* <ReactQueryDevtools /> */}
    </SoftUIControllerProvider>
    </QueryClientProvider>
    </Provider>
  </BrowserRouter>,
  // document.getElementById("root")
);
