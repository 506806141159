import { useState } from "react";

import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Checkbox from "@mui/material/Checkbox";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

function PlatformSettings(props) {
  // console.log("props",props)

  return (
    <Card>
      <SoftBox pt={2} px={2}>
        <SoftTypography
          variant="h6"
          fontWeight="medium"
          textTransform="capitalize"
        >
          User Features
        </SoftTypography>
      </SoftBox>
      <SoftBox pt={1.5} pb={2} px={2} lineHeight={1.25}>
        <SoftBox display="flex" flexDirection="column">
          <SoftBox className="fllw_tes" display="flex" py={2} mb={0.25}>
            <SoftBox mt={0.25}>
              <Switch
                checked={props.data.quote}
                name="quote"
                onChange={props.handelFunction}
              />
            </SoftBox>
            <SoftBox ml={2}>
              <SoftTypography
                variant="button"
                fontWeight="regular"
                color="text"
              >
                Quote
              </SoftTypography>
            </SoftBox>
          </SoftBox>

          <SoftBox
            className="iconwrapper"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <SoftBox className="icheck-wrap">
              <Checkbox
                className="checkbox"
                checked={props.data.view}
                name="view"
                onChange={props.handelFunction}
              />
              <SoftTypography
                variant="button"
                fontWeight="regular"
                color="text"
              >
                View
              </SoftTypography>
            </SoftBox>

            <SoftBox className="icheck-wrap">
              <Checkbox
                className="checkbox"
                checked={props.data.edit}
                name="edit"
                onChange={props.handelFunction}
              />
              <SoftTypography
                variant="button"
                fontWeight="regular"
                color="text"
              >
                Edit
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        </SoftBox>
        <SoftBox display="flex" py={1} mb={0.25}>
          <SoftBox mt={0.25}>
            <Switch
              checked={props.data.access_the_calendar}
              name="access_the_calendar"
              onChange={props.handelFunction}
            />
          </SoftBox>
          <SoftBox ml={2}>
            <SoftTypography variant="button" fontWeight="regular" color="text">
              Access the calendar
            </SoftTypography>
          </SoftBox>
        </SoftBox>
        <SoftBox display="flex" py={1} mb={0.25}>
          <SoftBox mt={0.25}>
            <Switch
              checked={props.data.access_the_workshop_schedule}
              name="access_the_workshop_schedule"
              onChange={props.handelFunction}
            />
          </SoftBox>
          <SoftBox ml={2}>
            <SoftTypography variant="button" fontWeight="regular" color="text">
              Access the workshop schedule
            </SoftTypography>
          </SoftBox>
        </SoftBox>

        <SoftBox display="flex" py={1} mb={0.25}>
          <SoftBox mt={0.25}>
            <Switch
              checked={props.data.make_invoices}
              name="make_invoices"
              onChange={props.handelFunction}
            />
          </SoftBox>
          <SoftBox ml={2}>
            <SoftTypography variant="button" fontWeight="regular" color="text">
              Make invoices
            </SoftTypography>
          </SoftBox>
        </SoftBox>
        <SoftBox display="flex" py={1} mb={0.25}>
          <SoftBox mt={0.25}>
            <Switch
              checked={props.data.change_prices}
              name="change_prices"
              onChange={props.handelFunction}
            />
          </SoftBox>
          <SoftBox ml={2}>
            <SoftTypography variant="button" fontWeight="regular" color="text">
              Change prices
            </SoftTypography>
          </SoftBox>
        </SoftBox>
        <SoftBox display="flex" py={1} mb={0.25}>
          <SoftBox mt={0.25}>
            <Switch
              checked={props.data.create_customers}
              name="create_customers"
              onChange={props.handelFunction}
            />
          </SoftBox>
          <SoftBox ml={2}>
            <SoftTypography variant="button" fontWeight="regular" color="text">
              Create customers
            </SoftTypography>
          </SoftBox>
        </SoftBox>
        <SoftBox display="flex" py={1} mb={0.25}>
          <SoftBox mt={0.25}>
            <Switch
              checked={props.data.create_repair_order}
              name="create_repair_order"
              onChange={props.handelFunction}
            />
          </SoftBox>
          <SoftBox ml={2}>
            <SoftTypography variant="button" fontWeight="regular" color="text">
              Create repair order
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

export default PlatformSettings;
