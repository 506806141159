/* eslint-disable react/prop-types */
// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import SoftBadge from "components/SoftBadge";
import Switch from "@mui/material/Switch";
import { useState } from "react";

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";

//logo
import BMWImage from 'assets/images/BMW.png';

//icons
import IconButton from '@mui/material/IconButton';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DeleteIcon from '@mui/icons-material/Delete'
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import EmailIcon from '@mui/icons-material/Email';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

//Links

import { Link } from "react-router-dom";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #202090',
  boxShadow: 24,
  overflow: "auto",
  p: 4,
  height: 554.8,
  minHeight: 554.8,

};

function Author({ image, name, email }) {

  return (
    <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
      <SoftBox mr={2}>
        <SoftAvatar src={image} alt={name} size="sm" variant="rounded" />
      </SoftBox>
      <SoftBox display="flex" flexDirection="column">
        <SoftTypography variant="button" fontWeight="medium">
          {name}
        </SoftTypography>
        <SoftTypography variant="caption" color="secondary">
          {email}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
}



function Function({ job, org }) {
  return (
    <SoftBox display="flex" flexDirection="column">
      <SoftTypography variant="caption" fontWeight="medium" color="text">
        {job}
      </SoftTypography>
      <SoftTypography variant="caption" color="secondary">
        {org}
      </SoftTypography>
    </SoftBox>
  );
}

const styles = {
  icon: {
    width: "1.3rem", // set the desired width of the icon
  }
};




const StoreData = {



  columns: [
    { name: "s.no.", align: "left" },
    { name: "question", align: "left" },
    { name: "answer", align: "left" },
    { name: "action", align: "center" },

  ],

  rows: [
    {
      "s.no.": <SoftTypography variant="caption" color="secondary" fontWeight="medium" className="rspnv_tbls">
        1
      </SoftTypography>,

      "question": <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
      </SoftTypography>,

      "answer": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        </SoftTypography>
      ),
      "Status": (
        <SoftBox>
          <Switch />
        </SoftBox>
      ),

      "action": (
        <IconButton aria-label="action">
          <Link to="/View-Store" className="actionIcon">
            <RemoveRedEyeIcon />
          </Link>
          <Link to="/Edit-Store" className="actionIcon" >
            <EditRoundedIcon />
          </Link>
          {/* <Link to="/" className="actionIcon" >
            <EmailIcon />
          </Link> */}
          <Link to="/" className="actionIcon">
            <DeleteIcon />
          </Link>
        </IconButton>
      ),
    },
    {
      "s.no.": <SoftTypography variant="caption" color="secondary" fontWeight="medium" className="rspnv_tbls">
        2
      </SoftTypography>,


      "question": <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
      </SoftTypography>,

      "answer": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        </SoftTypography>
      ),
      "Status": (
        <SoftBox>
          <Switch />
        </SoftBox>
      ),

      "action": (
        <IconButton aria-label="action">
          <Link to="/View-Store" className="actionIcon">
            <RemoveRedEyeIcon />
          </Link>
          <Link to="/Edit-Store" className="actionIcon" >
            <EditRoundedIcon />
          </Link>
          {/* <Link to="/" className="actionIcon" >
            <EmailIcon />
          </Link> */}
          <Link to="/" className="actionIcon">
            <DeleteIcon />
          </Link>
        </IconButton>
      ),
    },
    {
      "s.no.": <SoftTypography variant="caption" color="secondary" fontWeight="medium" className="rspnv_tbls">
        3
      </SoftTypography>,


      "question": <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
      </SoftTypography>,

      "answer": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        </SoftTypography>
      ),
      "Status": (
        <SoftBox>
          <Switch />
        </SoftBox>
      ),

      "action": (
        <IconButton aria-label="action">
          <Link to={""} className="actionIcon">
            <RemoveRedEyeIcon />
          </Link>
          <Link to="/Edit-Store" className="actionIcon" >
            <EditRoundedIcon />
          </Link>
          {/* <Link to="/" className="actionIcon" >
            <EmailIcon />
          </Link> */}
          <Link to="/" className="actionIcon">
            <DeleteIcon />
          </Link>
        </IconButton>
      ),
    },
    {
      "s.no.": <SoftTypography variant="caption" color="secondary" fontWeight="medium" className="rspnv_tbls">
        4
      </SoftTypography>,


      "question": <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
      </SoftTypography>,

      "answer": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        </SoftTypography>
      ),
      "Status": (
        <SoftBox>
          <Switch />
        </SoftBox>
      ),

      "action": (
        <IconButton aria-label="action">
          {/* <Link to="/View-Store" className="actionIcon"> */}
          <RemoveRedEyeIcon />
          {/* </Link> */}
          <Link to="/Edit-Store" className="actionIcon" >
            <EditRoundedIcon />
          </Link>
          {/* <Link to="/" className="actionIcon" >
            <EmailIcon />
          </Link> */}
          <Link to="/" className="actionIcon">
            <DeleteIcon />
          </Link>
        </IconButton>
      ),
    },
  ],
};

export default StoreData;

