import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";


import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import * as React from "react";
import {useState} from 'react';
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { Link, useNavigate } from "react-router-dom";
import carImage from "assets/images/car.png";
import SoftAvatar from "components/SoftAvatar";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import { Dropdown } from "react-bootstrap";

//icons
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

import { useDropzone } from "react-dropzone";
import { CarModal } from "components/CarManagement";
import { toast } from "react-toastify";
import axios from "axios";
import { universalFun } from "util/httpService";
import { getDate } from "util/timeConverter";
import { TablePagination } from "@mui/material";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

function CarManagement() {

  const timerRef = React.useRef()

  const [carsData, setCarsData] = React.useState([]);
  console.log("🚀 ~ file: index.js:39 ~ CarManagement ~ carsData:", carsData)
  const [modalName, setModalName] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setBrandName("");
    setInputFields([]);
    setImage({});
    setMakeName("");
    setModalName("add");
    setUploadedImage(null)
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const [open_1, setOpen_1] = React.useState(false);
  const handleOpen_1 = () => {
    setOpen_1(true);
  };
  const handleClose_1 = () => setOpen_1(false);

  const [open_2, setOpen_2] = React.useState(false);
  const handleOpen_2 = () => {
    setUploadedImage(null)
    setOpen_2(true);
    setModalName("view");
  };
  const handleClose_2 = () => setOpen_2(false);

  const [open_3, setOpen_3] = React.useState(false);
  const handleOpen_3 = () => {
    setUploadedImage(null)
    setOpen_3(true);
    setModalName("edit");
  };
  const handleClose_3 = () => setOpen_3(false);

  const [open_4, setOpen_4] = React.useState(false);
  const handleOpen_4 = () => setOpen_4(true);
  const handleClose_4 = () => setOpen_4(false);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
  const [inputFields, setInputFields] = React.useState([]);
  const [uploadedImage, setUploadedImage] = React.useState(null);
  const [makeName, setMakeName] = React.useState("");
  const [brandName, setBrandName] = React.useState("");
  const [image, setImage] = React.useState(null);
  const [brandId, setBrandId] = React.useState("");

  const [deleteId, setDeleteId] = React.useState('')

  //status states
  const [userStatusObj, setStatusObj] = React.useState([])
  const [previousState, setPreviousState] = React.useState([])

  //pagination
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [listCount, setListCount] = useState(100);

  const [search, setSearch] = React.useState("");
  const [sort, setSort] = useState("");
  const [status, setStatus] = useState("");


  const handlePageChange = (params, value) => {
    // 'value' represents the selected page
    setCurrentPage(value);
  
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };
  

  //---------------------------------------------------------

  const navigate = useNavigate();

  const handleNavigate = () => {
    // You can replace '/your-target-route' with the actual route you want to navigate to
    const targetRoute = '/editcar';

    // You can replace 'param1=value1&param2=value2' with your actual query parameters
    const search = 'param1=add';

    // Combine the route and query parameters
    const fullPath = `${targetRoute}?${search}`;

    // Navigate to the new route
    navigate(fullPath);
  };

  const getBrand = async (brand) => {
    try {
      const res = await axios.get(
        `https://betazone1.promaticstechnologies.com:5011/admin/findallcarlogos?id=${brand}`
      );
      console.log(res)
      const data = res.data.subcategory[0];
      setBrandName(data?.main_title);
      setMakeName(data?.category_title);
      setInputFields(data?.carmodel);
      setImage(data?.logo);
    } catch (error) {
      
    }
   
  };

  const handleAddField = () => {
    setInputFields([...inputFields, ""]);
  };
  
  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      //checking for type
      if (!file.type.startsWith("image/")) {
        toast.error("Please upload an image file");

        
        return;
      }
    const payload = {
      media: event.target.files[0],
      path: "carlogos",
    };
    const formData = new FormData();

    for (const key in payload) {
      formData.append(key, payload[key]);
    }
    try {
      const res = await axios.post(
        `https://betazone1.promaticstechnologies.com:5011/admin/uploadAdminMedia`,
        formData
      );
      console.log(res)
  
      setImage(res.data.media);
      console.log(URL.createObjectURL(event.target.files[0]))
      setUploadedImage(URL.createObjectURL(event.target.files[0]));
    } catch (error) {
      
    }
  }
    
  };

  const handleInputChange = (index, value) => {
    const updatedFields = [...inputFields];
    updatedFields[index] = value;
    setInputFields(updatedFields);
  };

  const handleModelDelete = (index)=>{
    const updatedFields = [...inputFields]
     updatedFields.splice(index ,1)
    setInputFields(updatedFields);
  }

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const handleSave = async () => {
    if(!uploadedImage){
      toast.info("Please add the brand logo")
      return;
    }
    const payload = {
      logo: image,
      category_title: makeName,
      main_title: brandName,
      carmodel: inputFields,
    };
    try {
      const res = await axios.post(
        `https://betazone1.promaticstechnologies.com:5011/admin/addCategory`,
        payload
      );
      if (res.status === 200) {
        toast.success("Brand has been Added");
        handleClose();
      }
      getAllCars();
    } catch (error) {
      
    }
   
  };

  const handleEdit = async () => {
    if(!uploadedImage && !image){
      toast.info("Please add the brand logo")
      return;
    }
    const payload = {
      logo: image,
      category_title: makeName,
      main_title: brandName,
      carmodel: inputFields,
    };
    try {
      const res = await axios.patch(
        `https://betazone1.promaticstechnologies.com:5011/admin/editcarmodel/${brandId}`,
        payload
      );
      if (res.status === 200) {
        toast.success("Car or model has been successfully updated");
        handleClose_3();
      }
      getAllCars();
    } catch (error) {
      
    }
    
  };

  //--------------------Deleting Cars
  const handleDeleteCarBrand = async()=>{
    try {
      const resp = await universalFun("delete",`deletecarmodel/${deleteId}`)
      console.log(resp)
      toast.success("Deleted Successfully")
      getAllCars()
      handleClose_4()
    } catch (error) {
      console.log(error)
    }
  }

  function Author({ image, name, email }) {
    return (
      <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
        <SoftBox mr={2}>
          <SoftAvatar src={image} alt={name} size="sm" variant="rounded" />
        </SoftBox>
      </SoftBox>
    );
  }

  // --------------status change
  const handleStatusChange = async (e, id) => {
console.log("ID-------",id)
    setStatusObj(prev => {
      setPreviousState(prev)
      return ({ ...prev, [id]: e.target.checked })
    })

    const status = e.target.checked ? "active" : "inactive"

    try {
      const resp = await universalFun("patch", `update-car-status/${id}`)
      console.log("Status Updated", resp)
      getAllCars()
    } catch (error) {
      console.log(error)
      setStatusObj(previousState)
      getAllCars()
    }

  }

// ------------------------ column for table
  const columns = [
    { field: "space", headerName: "", width: 5 },
    { field: "index", headerName: "#", width: 100 },
    {
      field: "Logo",
      headerName: "Logo",
      width: 150,
      renderCell: (params) => {
        return (
          <img
            src={`${process.env.REACT_APP_IMAGE_URL}/carlogos/${params.row.logo}`}
            alt={`Logo for ${params.row.brand_name}`}
            style={{ width: "80px", objectFit: "cover" }}
            onError={(e) => (e.target.src = carImage)}
          />
        );
      },
    },
    { field: "main_title", headerName: "Brand Name", width: 200 },
    { field: "category_title", headerName: "Category Type", width: 200 },
  //   { field: "status", headerName: "Status", width: 100 ,
  //  renderCell : (params)=> (
  //   <SoftBox>
  //   <Switch
  //    checked={userStatusObj[params.row?._id]} 
  //    onChange={(e) => handleStatusChange(e, params.row?._id)}
        
  //   />
  //   </SoftBox>
  //  )
  // },
    { field: "createdAt", headerName: "Created At", width: 100 , 
    renderCell : (params)=> getDate(params?.row?.createdAt)
  },
    {
      field: "Action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => (
        <>
          <Link
            to={{
              pathname: "/editcar",
              search: `?id=${params?.row?._id}&param1=view`,
            }}
            onClick={(e) => {
              e.stopPropagation();
              setUploadedImage(null)
              setOpen_2(true);
              // getBrand(params?.row?._id);
              // console.log(params?.row?._id)
            }}
            className="actionIcon"
            style={{ color: "#202090", fontSize: "20px", paddingLeft: "5px" }}
          >
            <IconButton aria-label="view">
              <RemoveRedEyeIcon />
            </IconButton>
          </Link>
          <Link
             to={{
              pathname: "/editcar",
              search: `?id=${params?.row?._id}&param1=edit`,
            }}
            className="actionIcon"
            // onClick={(e) => {
            //   e.stopPropagation();
            //   setUploadedImage(null)
            //   setOpen_3(true);
            //   setBrandId(params?.row?._id);
            //   getBrand(params?.row?._id);
            // }}
            // style={{ color: "#202090", fontSize: "20px", paddingLeft: "5px" }}
          >
            <IconButton aria-label="edit">
              <EditRoundedIcon />
            </IconButton>
          </Link>
          <Link
            to="" // Provide the correct route or path
            onClick={()=>{
              handleOpen_4();
              setDeleteId(params.row._id)
            }}
            className="actionIcon"
            style={{ color: "#202090", fontSize: "20px", paddingLeft: "5px" }}
          >
            <IconButton aria-label="delete">
              <DeleteIcon />
            </IconButton>
          </Link>
        </>
      ),
    },
  ];

  const dataWithIndex = carsData?.map((row, index) => ({
    ...row,
    index: index + 1,
  }));

  const getAllCars = async (debouncSearch) => {
    const params ={
      limit : rowsPerPage,
      page : currentPage + 1,
      sort : sort ,
      status : status,
      search : debouncSearch || "",
    }
    try {
      const res = await universalFun("get","all-cars",null, params);  
      console.log(res)
      setCarsData(res.data?.data?.items);
      makeStatusList(res.data?.data?.items)
      setListCount(res.data?.data?.totalItems)
    } catch (err) {
      console.log(err);
    }
  };

  React.useEffect(() => {
    getAllCars();
  }, [currentPage ,rowsPerPage ,sort ,status]);

  const makeStatusList = (userList) => {
    const newObj = {}

    userList.forEach((obj) => {
      newObj[obj._id] = obj.status === "active" ? true : false
    })
    setStatusObj(newObj)
    setPreviousState(newObj)
  }

      //search deboucing

      const debounce = (func ,delay)=>{
        return (...args)=>{
         clearTimeout(timerRef.current)
         timerRef.current  = setTimeout(()=>{
          func(...args)
         },delay)
        }
      }
    
      const debounceSearch = debounce(getAllCars ,500)
    
      const handleSearch = (e)=>{
        const value = e.target.value
        setSearch(value)
    
        debounceSearch(value)
      }

      // data grid footer
      const [ columnVisible ,  setColoumsVisible] = useState({})
      const [hideDatagridFooter , setHideDatagridFooter] = useState(false)

      const handleColumnModelChange = (param)=>{
        //  console.log(param,"======")
          const hideFooter = columns.every((obj)=> {
            const field = obj?.field
           return  param?.[field] === false} )
           setHideDatagridFooter(hideFooter)
          setColoumsVisible(param)
        }


      const footerShowButton = React.useCallback(()=>{
        return (
          <div className = "amint_set">
          <Button onClick={()=>{setColoumsVisible({}); setHideDatagridFooter(false) }}>Show all</Button>
          </div>
        )
      },[])

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={3}
              className="car-management"
            >
              <SoftTypography variant="h6">Car List</SoftTypography>
              <SoftBox display="flex" alignItems="center" gap="1rem">
              <Dropdown className="fltrDrop" onSelect={(value) => setStatus(value)}>
                  <Dropdown.Toggle id="dropdown-button-dark-example1" variant="primary">
                    <FilterAltIcon />
                    {/* <FilterListIcon /> */}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item  className = {status === "" ? "active" :""} eventKey="">ALL..</Dropdown.Item>
                    <Dropdown.Item className = {status === "active" ? "active" :""} eventKey="active">Active</Dropdown.Item>
                    <Dropdown.Item className = {status === "inactive" ? "active" :""} eventKey="inactive">InActive</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown className="fltrDrop" onSelect={(value) => setSort(value)}>
                  <Dropdown.Toggle id="dropdown-button-dark-example1" variant="primary">
                    {!sort ? <FilterListIcon /> :
                      <FilterListIcon style={{ transform: "rotate(180deg)" }} />}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item  className = {!sort ? "active" :""} eventKey="">Descending</Dropdown.Item>
                    <Dropdown.Item  className = {sort  ? "active" :""} eventKey="asc">Ascending</Dropdown.Item>

                  </Dropdown.Menu>
                </Dropdown>

                <SoftBox pr={1}>
                  <SoftInput
                    placeholder="Type here..."
                    icon={{ component: "search", direction: "left" }}
                    value={search}
                    onChange={handleSearch}

                  />
                </SoftBox>
                <div className="top-btn">
                {/* <Button
                  variant="contained"
                  color="primary"
                  style={{ color: "white" }}
                  fontWeight="medium"
                  className="bulk"
                  onClick={handleOpen_1}
                >
                  Bulk upload
                </Button> */}
                <Button
                  variant="contained"
                  color="primary"
                  style={{ color: "white" }}
                  fontWeight="medium"
                  className="add-btn"
                  onClick={handleNavigate}
                >
                  Add
                </Button>
              </div>
              </SoftBox>
             
            </SoftBox>

            <div style={{ height: 400, width: "100%" }}>
              <DataGrid
                rows={dataWithIndex?.length > 0 && dataWithIndex}
                columns={columns}
                // initialState={{
                //   pagination: {
                //     paginationModel: { page: 0, pageSize: 5 },
                //   },
                // }}
                // pageSizeOptions={[5, 10]}
                // checkboxSelection
                getRowId={(row, index) => row._id}
                columnVisibilityModel={columnVisible}
                onColumnVisibilityModelChange={handleColumnModelChange}
                // components={{
                //             Footer:  footerShowButton 

                //           }}
              />
            </div>

            
            <div className="d-flex justify-content-between">
            <div className="pt-2">
            {
              hideDatagridFooter &&
            <Button  onClick={()=>{setColoumsVisible({}); setHideDatagridFooter(false) }}>Show All</Button>
            }
            </div>
            
            <TablePagination
                  component="div"
                  count={listCount}
                  page={currentPage}
                  onPageChange={handlePageChange}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  rowsPerPageOptions={[5,10, 15, 20, 25]}
                />

            </div>
            
          </Card>
        </SoftBox>
      </SoftBox>
      {/* Add Modal */}
      <CarModal
        open={open}
        handleAddField={handleAddField}
        handleClose={handleClose}
        style={style}
        inputFields={inputFields}
        brandName={brandName}
       
        setBrandName={setBrandName}
        handleImageUpload={handleImageUpload}
        handleInputChange={handleInputChange}
        makeName={makeName}
        setMakeName={setMakeName}
        handleSave={handleSave}
        uploadedImage={uploadedImage}
        modalName="add"
        handleModelDelete ={handleModelDelete}
      />
      {/* Bulk Modla */}
      <Modal
        open={open_1}
        onClose={handleClose_1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="bulk-modal"
      >
        <Box sx={style}>
          <Typography className="modal-image">
            <section className="container bulk-upload">
              <div {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop some files here, or click to select files</p>
              </div>
              <aside>
                <h4>Files</h4>
                <ul>{files}</ul>
              </aside>
            </section>
          </Typography>
          <Typography>
            <Button
              variant="contained"
              onClick={handleClose_1}
              color="primary"
              style={{ color: "white" }}
              fontWeight="medium"
            >
              Save
            </Button>
          </Typography>
        </Box>
      </Modal>
      {/* View Modal */}
      <CarModal
        open={open_2}
        handleAddField={handleAddField}
        handleClose={handleClose_2}
        style={style}
        inputFields={inputFields}
        brandName={brandName}
        image = {image}
        setBrandName={setBrandName}
        handleImageUpload={handleImageUpload}
        handleInputChange={handleInputChange}
        makeName={makeName}
        setMakeName={setMakeName}
        handleSave={handleSave}
        uploadedImage={uploadedImage}
        modalName="view"
      />
      {/* Edit Modal */}
      <CarModal
        open={open_3}
        handleAddField={handleAddField}
        handleClose={handleClose_3}
        style={style}
        inputFields={inputFields}
        brandName={brandName}
        image = {image}
        setBrandName={setBrandName}
        handleImageUpload={handleImageUpload}
        handleInputChange={handleInputChange}
        makeName={makeName}
        setMakeName={setMakeName}
        handleSave={handleEdit}
        uploadedImage={uploadedImage}
        handleModelDelete ={handleModelDelete}
        modalName= "edit"
      />
      <Modal
        open={open_4}
        onClose={handleClose_4}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="delete-modal"
      >
        <Box sx={style}>
          <Typography className="modal-image">
            <div className="delete-message">
              Are you sure you want to delete ?
            </div>
          </Typography>
          <Typography
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "center",
              gap: "10px"
            }}
          >
            <Button
              variant="contained"
              onClick={handleDeleteCarBrand}
              color="primary"
              style={{ color: "white" }}
              fontWeight="medium"
            >
              Yes
            </Button>
            <Button
              variant="contained"
              onClick={handleClose_4}
              color="primary"
              style={{ color: "white" }}
              fontWeight="medium"
            >
              No
            </Button>
          </Typography>
        </Box>
      </Modal>
      <Footer />
    </DashboardLayout>
  );
}

export default CarManagement;
