import React, { useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Tabs from "./Tabs/Tabs";

const GarageDetails = () => {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <>
        <section className="garage-details">
          <Tabs />
        </section>
      </>
      <Footer />
    </DashboardLayout>
  );
};

export default GarageDetails;
