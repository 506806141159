/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import * as React from 'react';
import { useState, Component,useEffect } from "react";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import SoftBox from "components/SoftBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import Button from '@mui/material/Button';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

import {getTermAndConditionService} from "../../../util/httpService";
import {UsTimeConverter} from "../../../util/timeConverter";
import {updateCmsService} from "../../../util/httpService";
import { toast } from 'react-toastify';





function TermsCondition() {


  const [heading,setHeading] = useState('');

  const [lastupdate,setLastUpdate] = useState('');

  const [description,setDescription] = useState('');

  async function getPrivacyPolicy (){
    try {
      const resp = await getTermAndConditionService();
      console.log("resp ", resp.data);
     if(resp?.data.code === 200){
       let data = resp.data.item.data;
        setHeading(data.title_1);
        setDescription(data.description)
        setLastUpdate(UsTimeConverter(data.updatedAt))
       }
      }
      catch (err) {
       // alert(err)
    }
}

  useEffect(() => {
     getPrivacyPolicy();
   },[])

    const submit = async () => {
      let obj = {
         type:"terms",
         title_1:heading,
         description:description,
      }
       try {
         const resp = await updateCmsService(obj);
         console.log(" cms response", resp);
         if(resp?.data.code === 200){
            toast.success("Term and Condition Updated Successfully");
            getPrivacyPolicy()
         }
       } catch (err) {
          console.log(err)
       }
    }



  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox mt={5}>
        <Card className="card crd_new">
          <SoftBox mb={2}>
            <SoftBox mb={2}>
              <SoftBox mb={0} ml={0.5}>
                <SoftTypography component='label' variant='caption' fontWeight='bold'>
                  Eurobosse's Terms of Service
                </SoftTypography>
              </SoftBox>
              <SoftInput type='text' placeholder="Eurobosse's Terms of Service" value={heading} onChange={(event) => {setHeading(event.target.value)}}/>
            </SoftBox>
            <SoftBox mb={2}>
              <SoftBox mb={0} ml={0.5}>
                <SoftTypography component='label' variant='caption' fontWeight='bold'>
                  Last Update
                </SoftTypography>
              </SoftBox>
              <SoftInput type='text' placeholder="--"  value={lastupdate} />
            </SoftBox>
            <div className="App">
              <h4>Terms & Conditions</h4>
              <CKEditor
                editor={ClassicEditor}
                data={description}
                onReady={editor => {
                  // You can store the "editor" and use when it is needed.
                  console.log('Editor is ready to use!', editor);
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setDescription(data)
                  console.log("description",description)
                  // console.log({ event, editor, data });
                }}
                onBlur={(event, editor) => {
                  console.log('Blur.', editor);
                }}
                onFocus={(event, editor) => {
                  console.log('Focus.', editor);
                }}
              />
            </div>
          </SoftBox>
        </Card>



        <SoftBox display="flex" justifyContent="center">

          <Button variant="contained" color="primary" style={{ color: "white" }} fontWeight="medium" onClick={submit}>
            Save
          </Button>
        </SoftBox>
      </SoftBox>


      <Footer />
    </DashboardLayout>
  );
}

export default TermsCondition;
