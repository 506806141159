import { Box } from "@mui/material";
import React from "react";
import { IoPersonSharp } from "react-icons/io5";
import { FaEnvelope } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { FaIdCard } from "react-icons/fa";
import { RiEdit2Fill } from "react-icons/ri";

const ContactInfo = () => {
  return (
    <div className="contact-info shadow">
      <div className="profile">
        <span>A</span>
      </div>
      <div className="flex flex-col">
        <p>Contact Info</p>
        <span>
          <IoPersonSharp className="info-icon" /> ABC
        </span>
        <span>
          <FaEnvelope className="info-icon" /> aaaa@gmail.com
        </span>
        <span>
          <FaPhoneAlt className="info-icon" /> 5457788867
        </span>
        <span>
          <span>
            <FaIdCard className="info-icon" />{" "}
          </span>
          (323) 751-1853 7609 Mckinely Ave Los Angeles,California(CA),90001
        </span>
        <button type="button">
          <RiEdit2Fill /> Edit
        </button>
      </div>
    </div>
  );
};

export default ContactInfo;
