import React from "react";

const PaymentStatus = () => {
  return (
    <div className="payment-status">
      <div>
        <h3>$0.00 USD</h3>
        <p>Paid last 12 months</p>
      </div>
      <div>
        <h3>$0.00 USD</h3>
        <p>Total unpaid</p>
      </div>
      <div>
        <h3>$0.00 USD</h3>
        <p>Last item sent</p>
      </div>
    </div>
  );
};

export default PaymentStatus;
