// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { GoogleAuthProvider } from "firebase/auth";
import { getMessaging, getToken } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyAL9JjyFi_O6N7Oqx1hJzL7K7EEVqWpmr8",
  authDomain: "deveurobossadmin.firebaseapp.com",
  projectId: "deveurobossadmin",
  storageBucket: "deveurobossadmin.appspot.com",
  messagingSenderId: "166974073567",
  appId: "1:166974073567:web:993050989db58c1f5d576a",
};

const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app);

const provider = new GoogleAuthProvider();

const messaging = getMessaging(app);

const requestForToken = async () => {
  const permission = await Notification.requestPermission();
  if (permission === "granted") {
    return getToken(messaging, {
      // vapidKey: process.env.REACT_Firebase_Public_KEY,
      vapidKey:
        "BHuupGuO1YgrJH64LvBUydDvPQ9PrQJm1YD5zoUB6eetm43qiIiUgh9QwjCUbd57GEDHE2o6__HxqbhtORh0O9A",
    })
      .then((currentToken) => {
        if (currentToken) {
          localStorage.setItem("DeviceToken", currentToken);
          console.log("🚀 ~ .then ~ currentToken:", currentToken);
        } else {
          // Show permission request UI
          console.log(
            "No registration token available. Request permission to generate one."
          );
        }
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
      });
  } else if (permission === "denied") {
  }
};

export { provider, app, requestForToken };
