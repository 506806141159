import React from 'react'
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    // boxShadow: 24,
    p: 4,
    borderRadius: "10px",
  };

const DeleteModal = ({show ,handleConfirm , handleClose}) => {
  return (
    <Modal
    open={show}
    onClose={handleClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    className="delete-modal"
  >
    <Box sx={style}>
      <Typography className="modal-image">
        <div className="delete-message">
          Are you sure you want to delete ?
        </div>
      </Typography>
      <Typography
        style={{
          marginTop: "20px",
          display: "flex",
          justifyContent: "center",
          gap: "10px"
        }}
      >
        <Button
          variant="contained"
          onClick={handleConfirm}
          color="primary"
          style={{ color: "white" }}
          fontWeight="medium"
        >
          Yes
        </Button>
        <Button
          variant="contained"
          onClick={handleClose}
          color="primary"
          style={{ color: "white" }}
          fontWeight="medium"
        >
          No
        </Button>
      </Typography>
    </Box>
  </Modal>
  )
}

export default DeleteModal