/* eslint-disable react/prop-types */
// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import Switch from "@mui/material/Switch";

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";


//icons
import IconButton from '@mui/material/IconButton';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DeleteIcon from '@mui/icons-material/Delete'
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import EmailIcon from '@mui/icons-material/Email';


//Links

import { Link } from "react-router-dom";



function Author({ image, name, email }) {


  return (
    <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
      <SoftBox mr={2}>
        <SoftAvatar src={image} alt={name} size="sm" variant="rounded" />
      </SoftBox>
      <SoftBox display="flex" flexDirection="column">
        <SoftTypography variant="button" fontWeight="medium">
          {name}
        </SoftTypography>
        <SoftTypography variant="caption" color="secondary">
          {email}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
}



function Function({ job, org }) {
  return (
    <SoftBox display="flex" flexDirection="column">
      <SoftTypography variant="caption" fontWeight="medium" color="text">
        {job}
      </SoftTypography>
      <SoftTypography variant="caption" color="secondary">
        {org}
      </SoftTypography>
    </SoftBox>
  );
}

const styles = {
  icon: {
    width: "1.3rem", // set the desired width of the icon
  }
};



const StoreData = {



  columns: [
    { name: "user", align: "left" },
    { name: "user type", align: "left" },
    { name: "phone no.", align: "left" },
    { name: "Status", align: "center" },
    { name: "action", align: "center" },

  ],

  rows: [
    {
      "user": <Author image={team2} name="John Michael" email="john@creative-tim.com" />,

      "user type": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          DSP subcontractor
        </SoftTypography>
      ),

      "phone no.": <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        7979881545
      </SoftTypography>,

      "Status": (
        <SoftBox>
          <Switch />
        </SoftBox>
      ),

      "action": (
        <IconButton aria-label="action">
          <Link to="/view-User" className="actionIcon">
            <RemoveRedEyeIcon />
          </Link>
          <Link to="/edit-User" className="actionIcon" >
            <EditRoundedIcon />
          </Link>
          <Link to="/" className="actionIcon" >
            <EmailIcon />
          </Link>
          <Link to="/" className="actionIcon">
            <DeleteIcon />
          </Link>
          {/* <Link to="/" className="actionIcon">
            <PeopleAltIcon />
          </Link> */}
        </IconButton>
      ),
    },
    {
      "user": <Author image={team3} name="Alexa Liras" email="alexa@creative-tim.com" />,

      "user type": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          Super User
        </SoftTypography>
      ),

      "phone no.": <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        7979881545
      </SoftTypography>,

  
      "Status": (
        <SoftBox>
          <Switch />
        </SoftBox>
      ),

      "action": (
        <IconButton aria-label="action">
          <Link to="/viewUser" className="actionIcon">
            <RemoveRedEyeIcon />
          </Link>
          <Link to="/edit-User" className="actionIcon" >
            <EditRoundedIcon />
          </Link>
          <Link to="/" className="actionIcon" >
            <EmailIcon />
          </Link>
          <Link to="/" className="actionIcon">
            <DeleteIcon />
          </Link>
          {/* <Link to="/" className="actionIcon">
            <PeopleAltIcon />
          </Link> */}
        </IconButton>
      ),
    },
    {
      "user": <Author image={team4} name="Laurent Perrier" email="laurent@creative-tim.com" />,

      "user type": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          Technician
        </SoftTypography>
      ),

      "phone no.": <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        7979881545
      </SoftTypography>,

 
      "Status": (
        <SoftBox>
          <Switch />
        </SoftBox>
      ),

      "action": (
        <IconButton aria-label="action">
          <Link to="/viewUser" className="actionIcon">
            <RemoveRedEyeIcon />
          </Link>
          <Link to="/edit-User" className="actionIcon" >
            <EditRoundedIcon />
          </Link>
          <Link to="/" className="actionIcon" >
            <EmailIcon />
          </Link>
          <Link to="/" className="actionIcon">
            <DeleteIcon />
          </Link>
          {/* <Link to="/" className="actionIcon">
            <PeopleAltIcon />
          </Link> */}
        </IconButton>
      ),
    },
    {
      "user": <Author image={team3} name="Michael Levi" email="michael@creative-tim.com" />,

      "user type": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          DSP subcontractor
        </SoftTypography>
      ),

      "phone no.": <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        7979881545
      </SoftTypography>,

   
      "Status": (
        <SoftBox>
          <Switch />
        </SoftBox>
      ),

      "action": (
        <IconButton aria-label="action">
          <Link to="/viewUser" className="actionIcon">
            <RemoveRedEyeIcon />
          </Link>
          <Link to="/edit-User" className="actionIcon" >
            <EditRoundedIcon />
          </Link>
          <Link to="/" className="actionIcon" >
            <EmailIcon />
          </Link>
          <Link to="/" className="actionIcon">
            <DeleteIcon />
          </Link>
          {/* <Link to="/" className="actionIcon">
            <PeopleAltIcon />
          </Link> */}
        </IconButton>
      ),
    },
    {
      "user": <Author image={team2} name="Richard Gran" email="richard@creative-tim.com" />,

      "user type": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          Super User
        </SoftTypography>
      ),

      "phone no.": <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        7979881545
      </SoftTypography>,

      "Status": (
        <SoftBox>
          <Switch />
        </SoftBox>
      ),

      "action": (
        <IconButton aria-label="action">
          <Link to="/viewUser" className="actionIcon">
            <RemoveRedEyeIcon />
          </Link>
          <Link to="/edit-User" className="actionIcon" >
            <EditRoundedIcon />
          </Link>
          <Link to="/" className="actionIcon" >
            <EmailIcon />
          </Link>
          <Link to="/" className="actionIcon">
            <DeleteIcon />
          </Link>
          {/* <Link to="/" className="actionIcon">
            <PeopleAltIcon />
          </Link> */}
        </IconButton>
      ),
    },
    {
      "user": <Author image={team2} name="Richard Gran" email="richard@creative-tim.com" />,

      "user type": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          Technician
        </SoftTypography>
      ),

      "phone no.": <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        7979881545
      </SoftTypography>,

      "Status": (
        <SoftBox>
          <Switch />
        </SoftBox>
      ),

      "action": (
        <IconButton aria-label="action">
          <Link to="/view-User" className="actionIcon">
            <RemoveRedEyeIcon />
          </Link>
          <Link to="/edit-User" className="actionIcon" >
            <EditRoundedIcon />
          </Link>
          <Link to="/" className="actionIcon" >
            <EmailIcon />
          </Link>
          <Link to="/" className="actionIcon">
            <DeleteIcon />
          </Link>
          {/* <Link to="/" className="actionIcon">
            <PeopleAltIcon />
          </Link> */}
        </IconButton>
      ),
    },
  ],
};

export default StoreData;
