import { Box, Button, Modal, Typography } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import { truncate } from "lodash";

export const VatModal = ({
  open,
  handleClose,
  style,
  handleSave,
  modalName,
  data,
  setData,
  title,
  handleEdit
}) => {
  const handleButtonClick = (e) => {
    e.preventDefault();
    if (modalName === "view") {
      handleClose();
    } else if( modalName === "edit"){
      handleEdit();
    }
    else {
      handleSave();
    }
  };


  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="add-modal"
    >
      <form onSubmit={handleButtonClick}>
        {/* <h4>Add Model</h4> */}
        <Box sx={style}>
          <Typography sx={{ mt: 2 }}>
            <SoftBox mb={2} display="flex" flexDirection="column">
              <SoftTypography
                component="label"
                fontSize="13px"
                mb={1}
                variant="caption"
                fontWeight="bold"
              >
                Country Name
              </SoftTypography>
              <SoftInput
                type="text"
                className="TextField"
                name="question"
                onChange={(e) =>
                  setData((prev) => ({
                    ...prev,
                    country_name: e.target.value,
                  }))
                }
                placeholder="Enter Country Name"
                value={data.country_name}
                disabled={modalName === "view"}
                required
              />
            </SoftBox>
          </Typography>
          <Typography sx={{ mt: 2 }}>
            <SoftBox mb={2} display="flex" flexDirection="column">
              <SoftTypography
                component="label"
                fontSize="13px"
                mb={1}
                variant="caption"
                fontWeight="bold"
              >
                Vat Rate
              </SoftTypography>
              <SoftInput
                type="number"
                className="TextField"
                name="question"
                onChange={(e) =>
                  setData((prev) => ({
                    ...prev,
                    vat_rate: e.target.value,
                  }))
                }
                placeholder="Enter Vat"
                value={data?.vat_rate}
                disabled={modalName === "view"}
                required
              />
            </SoftBox>
          </Typography>
          <Typography>
            <Button
              variant="contained"
              type="submit"
              // onClick={handleButtonClick}
              color="primary"
              style={{ color: "white" }}
              fontWeight="medium"
            >
              {modalName === "view" ? "Close" : "Save"}
            </Button>
          </Typography>
        </Box>
      </form>
    </Modal>
  );
};
