import { Box, Button, Modal, Typography } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";

export const InsuranceModal = ({
  open,
  handleClose,
  style,
  handleSave,
  modalName,
  insuranceDetails,
  setInsuranceDetails
}) => {


const handleButtonClick =(e)=>{
 e.preventDefault()
  if(modalName === "view"){
    handleClose()
  }else {
    handleSave()
  }
}

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="add-modal"
    >
    <form onSubmit={handleButtonClick}>
      <Box sx={style}>
        <Typography sx={{ mt: 2 }}>
          <SoftBox mb={2} display="flex" flexDirection="column">
            <SoftTypography
              component="label"
              fontSize="13px"
              mb={1}
              variant="caption"
              fontWeight="bold">
              Insurance Name
            </SoftTypography>
            <SoftInput
              type="text"
              className="TextField"
              name="question"
              onChange={(e) => setInsuranceDetails((prev)=> ({...prev ,company_name : e.target.value}))}
              placeholder=""
              value={insuranceDetails?.company_name}
              disabled={modalName === "view"}
              required
            />
          </SoftBox>
        </Typography>
        <Typography>
          <Button
            variant="contained"
            type = "submit"
            // onClick={handleButtonClick}
            color="primary"
            style={{ color: "white" }}
            fontWeight="medium"
          >
           {modalName === "view" ? "Close" : "Save"}
          </Button>
        </Typography>
      </Box>
      </form>
    </Modal>
  );
};
