/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import PlaceholderCard from "examples/Cards/PlaceholderCard";

// Overview page components
import Header from "layouts/profile/components/Header";
import PlatformSettings from "layouts/profile/components/PlatformSettings";

// Data
import profilesListData from "layouts/profile/data/profilesListData";

// Images
import homeDecor1 from "assets/images/home-decor-1.jpg";
import homeDecor2 from "assets/images/home-decor-2.jpg";
import homeDecor3 from "assets/images/home-decor-3.jpg";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import { useEffect, useState } from "react";
import axios from "axios";
import { getProfileFunction } from "util/httpService";

function Overview() {
  const [userData, setUserData] = useState({data: "", success: false})

  // get Profile function-
  const getProfile = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("Token not found in localStorage");
        return;
      }

      const response = await getProfileFunction(token);
      if(response){
        setUserData({data: response?.data?.data, success: response?.data?.success})
      }
    } catch (error) {
      console.error("Error", error);
    }
  };

  useEffect(() => {
    getProfile()
  }, [])

  // destructure info if data is-
  if(!userData?.success){
    return;
  }

  // Profile Information-
  const {data: {name, phone, email, description, facebookUrl, instagramUrl, twitterUrl, address: {country, state, city}}} = userData;

  console.log("userData", userData)

  return (
    <DashboardLayout>
      <Header />
      <SoftBox mt={5} mb={3}>
        <Grid container spacing={3} justifyContent={"center"}>
          {/* <Grid item xs={12} md={6} xl={4}>
            <PlatformSettings 
              profileSetting = {userData}
              userData = {userData}
              setUserData = {setUserData} 
            />
          </Grid> */}
          <Grid item xs={12} md={8} xl={8}>
            <ProfileInfoCard
              title="profile information"
              description={description}
              // info={{
              //   fullName: name,
              //   mobile: phone,
              //   email: email,
              //   location: city, state, country,
              // }}
              info={{
                fullName: name,
                mobile: phone,
                email: email,
              }}
              social={[
                {
                  link: facebookUrl,
                  icon: <FacebookIcon />,
                  color: "facebook",
                },
                {
                  link: twitterUrl,
                  icon: <TwitterIcon />,
                  color: "twitter",
                },
                {
                  link: instagramUrl,
                  icon: <InstagramIcon />,
                  color: "instagram",
                },
              ]}
              action={{ route: "/edit-profile", tooltip: "Edit Profile" }}
            />
          </Grid>
          {/* <Grid item xs={12} xl={4}>
            <ProfilesList title="conversations" profiles={profilesListData} />
          </Grid> */}
        </Grid>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
