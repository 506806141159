import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import debounce from 'lodash/debounce';


// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

//Button
import Button from "@mui/material/Button";

//usestate

import { useState, useEffect } from "react";

//image
import team2 from "assets/images/team-2.jpg";
import userPlaceholder from "assets/images/FallbackImage.jpg";

// @mui material components
import Grid from "@mui/material/Grid";

//icons
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

//select
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { useParams } from "react-router-dom";

// Overview page components

import PlatformSettings from "layouts/edit-user/components/PlatformSettings";

import { editUserService } from "../../util/httpService";
import { uploadMedia } from "../../util/httpService";

import logo from "assets/images/logo.jpg";
import { useNavigate } from "react-router-dom";

import { viewUserService } from "../../util/httpService";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { toast } from "react-toastify";
import useEmailExistenceCheck from "customHooks/useEmailExistenceCheck";

function EditUser() {
  const param = useParams();

  const navigate = useNavigate();
  const { isEmailAvail, setIsEmailAvail, checkEmailExistence } =
    useEmailExistenceCheck();
  console.log("🚀 ~ EditUser ~ isEmailAvail:", isEmailAvail);

  const emailRegx = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const passRegx = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/;

  const [addPersonalInfo, setPersonalInfo] = useState({
    image: "",
    first_name: "",
    last_name: "",
    phone_number: "",
    email: "",
    user_type: "super_user",
    initials: "",
  });

  const [addPersonalInfoValidation, setPersonalInfoValidation] = useState({
    first_name: true,
    last_name: true,
    phone_number: true,
    email: true,
  });

  const [addUserFeature, setUserFeature] = useState({
    quote: false,
    view: false,
    edit: false,
    access_the_calendar: false,
    access_the_workshop_schedule: false,
    make_invoices: false,
    change_prices: false,
    create_customers: false,
    create_repair_order: false,
  });

  const [addLoginInfo, setLoginInfo] = useState({
    password: "",
    initials: "",
    genrate_password: false,
    send_credentials: false,
  });

  const [addLoginInfoValidation, setLoginInfoValidation] = useState({
    password: true,
    initials: true,
  });

  const [isFormValid, setIsFormValid] = useState(true);

  useEffect(() => {
    const isValid = Object.values(addPersonalInfoValidation).every(
      (value) => value
    );
    setIsFormValid(isValid);
  }, [addPersonalInfoValidation]);

  const [storeID, setStoreID] = useState("");

  const [passShow1, setPassShow1] = useState(false);
  const [passShow2, setPassShow2] = useState(false);

  useEffect(() => {
    async function getUserData() {
      console.log("parms 222222222", param);
      let obj = param.id;
      try {
        const resp = await viewUserService(obj);
        console.log("User view data ", resp.data.response.data);
        if (resp?.data.code === 200) {
          const data = resp.data.response.data;
          setStoreID(data.store_id);
          setPersonalInfo({
            image: data.image,
            first_name: data.first_name,
            last_name: data.last_name,
            phone_number: data.phone_number,
            email: data.email,
            user_type: data.user_type,
            initials: data.initials,
            // password:'',
            // initials:data.initials,
            // genrate_password:data.genrate_password,
            // send_credentials:data.send_credentials
          });
          setUserFeature({
            quote: data.quote,
            view: data.view,
            edit: data.edit,
            access_the_calendar: data.access_the_calendar,
            access_the_workshop_schedule: data.access_the_workshop_schedule,
            make_invoices: data.make_invoices,
            change_prices: data.change_prices,
            create_customers: data.create_customers,
            create_repair_order: data.create_repair_order,
          });
          setLoginInfo({
            password: "",
            initials: data.initials,
            genrate_password: false,
            send_credentials: false,
          });
        }
      } catch (err) {
        // alert(err)
      }
    }
    getUserData();
  }, []);

  const handleChangeForPersonalInfo = (event) => {
    console.log("info person info handel", event);
    setPersonalInfo((addPersonalInfoData) => ({
      ...addPersonalInfoData,
      [event.target.name]: event.target.value,
    }));
    const { name, value } = event.target;
    // ValidationCheckPersonalInfo(event.target.name);
    ValidateFields(name, value);
    if (name === "email") {
      setIsEmailAvail({ isValid: true, message: "" });
    }
  };

  // useEffect(()=>{
  // if(addPersonalInfoValidation.email){

  // }

  // },[addPersonalInfoValidation.email])

  //checking field on change
  function ValidateFields(name, value) {
    switch (name) {
      case "first_name":
        setPersonalInfoValidation((prev) => ({
          ...prev,
          first_name: value.length === 0 ? false : true,
        }));
        break;
      case "last_name":
        setPersonalInfoValidation((prev) => ({
          ...prev,
          last_name: value.length === 0 ? false : true,
        }));
        break;
      case "email":
        setPersonalInfoValidation((prev) => ({
          ...prev,
          email: value.length === 0 || !emailRegx.test(value) ? false : true,
        }));
        break;
      case "siret_number":
        setPersonalInfoValidation((prev) => ({
          ...prev,
          siret_number:
            // addStoreData[name].length === 0 ||
            // !SiretRegx.test(addStoreData.siret_number)
            //   ? false
            //   : true,
            value.length < 14 ? false : true,
        }));
        break;
      case "company_name":
        setPersonalInfoValidation((prev) => ({
          ...prev,
          company_name: value.length === 0 ? false : true,
        }));
        break;
      case "phone_number":
        setPersonalInfoValidation((prev) => ({
          ...prev,
          phone_number: value.length === 0 ? false : true,
        }));
        break;
      default:
    }
  }

  function ValidationCheckPersonalInfo(name) {
    switch (name) {
      case "first_name":
        setPersonalInfoValidation((addPersonalInfoValidation) => ({
          ...addPersonalInfoValidation,
          first_name: addPersonalInfo[name].length === 0 ? false : true,
        }));
        break;
      case "last_name":
        setPersonalInfoValidation((addPersonalInfoValidation) => ({
          ...addPersonalInfoValidation,
          last_name: addPersonalInfo[name].length === 0 ? false : true,
        }));
        break;
      case "email":
        setPersonalInfoValidation((addPersonalInfoValidation) => ({
          ...addPersonalInfoValidation,
          email:
            addPersonalInfo[name].length === 0 ||
            !emailRegx.test(addPersonalInfo.email)
              ? false
              : true,
        }));
        break;
      case "phone_number":
        setPersonalInfoValidation((addPersonalInfoValidation) => ({
          ...addPersonalInfoValidation,
          phone_number: addPersonalInfo[name].length === 0 ? false : true,
        }));
        break;
      default:
    }
  }

  const handleChangeForUserFeature = (event) => {
    console.log("test userFeature", event);
    setUserFeature((addUserFeature) => ({
      ...addUserFeature,
      [event.target.name]: event.target.checked,
    }));
    console.log("check for data", addUserFeature);
  };

  const handleChangeForLoginInfo = (event) => {
    console.log("test LoginInfo", event);
    if (
      event.target.name === "genrate_password" ||
      event.target.name === "send_credentials"
    ) {
      setLoginInfo((addLoginInfo) => ({
        ...addLoginInfo,
        [event.target.name]: event.target.checked,
      }));
      if (
        event.target.name === "genrate_password" &&
        event.target.checked === true
      ) {
        setLoginInfo((addLoginInfo) => ({ ...addLoginInfo, password: "" }));
        setLoginInfoValidation((addLoginInfoValidation) => ({
          ...addLoginInfoValidation,
          password: true,
        }));
        setConfirmPassword("");
        setConfirmPasswordValidation(true);
      }
      if (
        event.target.name === "genrate_password" &&
        event.target.checked === false
      ) {
        setConfirmPasswordValidation(false);
        setLoginInfoValidation((addLoginInfoValidation) => ({
          ...addLoginInfoValidation,
          password: false,
        }));
      }
    } else {
      setLoginInfo((addLoginInfo) => ({
        ...addLoginInfo,
        [event.target.name]: event.target.value,
      }));
    }
    console.log("check for data", addLoginInfo);
    ValidationCheckForLoginInfo(event.target.name);
  };

  function ValidationCheckForLoginInfo(name) {
    console.log("asdasdas asdasdasd", name);
    switch (name) {
      case "password":
        if (
          addLoginInfo.genrate_password === false &&
          addLoginInfo.password.length > 0
        ) {
          setLoginInfoValidation((addLoginInfoValidation) => ({
            ...addLoginInfoValidation,
            password:
              addLoginInfo[name].length < 8 ||
              !passRegx.test(addLoginInfo.password)
                ? false
                : true,
          }));
          console.log(
            "password validations values",
            addLoginInfoValidation,
            addLoginInfo.password.length
          );
        } else {
          setLoginInfoValidation((addLoginInfoValidation) => ({
            ...addLoginInfoValidation,
            password: true,
          }));
        }
        break;
      case "initials":
        setLoginInfoValidation((addLoginInfoValidation) => ({
          ...addLoginInfoValidation,
          initials: addLoginInfo[name].length === 0 ? false : true,
        }));
        console.log(
          "initials validations values",
          addLoginInfoValidation,
          addLoginInfo.initials.length
        );
        break;
      default:
    }
  }

  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordValidation, setConfirmPasswordValidation] =
    useState(true);

  const handleConfirmPassword = (event) => {
    setConfirmPassword(event.target.value);
    console.log("check for data", confirmPassword);
    setConfirmPasswordValidation(
      addLoginInfo.password !== event.target.value ? false : true
    );
  };

  const ImageUploaded = async (event) => {
    let file = event.target.files[0];
    const type = file.type;
    console.log("file name", file);
    if (type.includes("image")) {
      try {
        let formData = new FormData();
        formData.append("path", "sub_users");
        formData.append("media", file);
        let resp = await uploadMedia(formData);
        if (resp?.status === 200) {
          console.log(" login response data=> ", resp);
          setPersonalInfo({ ...addPersonalInfo, image: resp?.data?.media });
        }
      } catch (error) {
        // alert(error);
      }
    } else {
      toast.error("Only Images are supported");
    }
  };

  function checkFuncForValuesForPersonalInfo(obj) {
    const newKey = Object.keys(
      Object.fromEntries(
        Object.entries(obj).filter(
          ([key]) =>
            key !== "image" && key !== "user_type" && key !== "initials"
        )
      )
    );
    console.log("new key personal info value22222", newKey);
    // const keys = Object.keys(obj);
    if (newKey.find((ele) => obj[ele].length === 0) === undefined) {
      console.log("ture for pers values");
      return true;
    } else {
      for (let i = 0; i <= newKey.length; i++) {
        ValidationCheckPersonalInfo(newKey[i]);
        console.log("ture for pers values");
      }
      return false;
    }
  }

  function checkFuncForPersonalInfoValidation(obj) {
    const newKey = Object.keys(
      Object.fromEntries(
        Object.entries(obj).filter(
          ([key]) => key !== "image" && key !== "user_type"
        )
      )
    );
    console.log("new key personal info Validation", newKey);
    // const keys = Object.keys(obj);
    if (newKey.find((ele) => obj[ele] === false) === undefined) {
      return true;
    } else {
      for (let i = 0; i <= newKey.length; i++) {
        ValidationCheckPersonalInfo(newKey[i]);
      }
      return false;
    }
  }

  function checkFuncForValuesForLoginInfo(obj) {
    const newKey = Object.keys(
      Object.fromEntries(
        Object.entries(obj).filter(([key]) =>
          key !== "genrate_password" &&
          key !== "send_credentials" &&
          addLoginInfo.genrate_password === true
            ? key !== "password"
            : addLoginInfo.password === ""
            ? key !== "password"
            : key === "password"
        )
      )
    );
    // const keys = Object.keys(obj);
    console.log("new key value", newKey);
    if (newKey.find((ele) => obj[ele].length === 0) === undefined) {
      return true;
    } else {
      for (let i = 0; i <= newKey.length; i++) {
        ValidationCheckForLoginInfo(newKey[i]);
      }
      return false;
    }
  }

  function checkFuncForIoginInfoValidation(obj) {
    const newKey = Object.keys(
      Object.fromEntries(
        Object.entries(obj).filter(
          ([key]) => key !== "genrate_password" && key !== "send_credentials"
        )
      )
    );
    // const keys = Object.keys(obj);
    console.log("new key Validation", newKey);
    if (newKey.find((ele) => obj[ele] === false) === undefined) {
      return true;
    } else {
      for (let i = 0; i <= newKey.length; i++) {
        ValidationCheckForLoginInfo(newKey[i]);
      }
      return false;
    }
  }

  const submit = async () => {
    if (
      checkFuncForValuesForPersonalInfo(addPersonalInfo) === true
      // checkFuncForValuesForLoginInfo(addLoginInfo) === true
    ) {
      if (
        checkFuncForPersonalInfoValidation(addPersonalInfoValidation) === true
        // checkFuncForIoginInfoValidation(addLoginInfoValidation) === true
      ) {
        const data = Object.assign(
          addPersonalInfo,
          // addLoginInfo,
          addUserFeature
        );
        let newdata = JSON.parse(JSON.stringify(data));
        // if (newdata.password === "") {
        //   delete newdata.password;
        // }
        console.log("data", newdata);
        try {
          const resp = await editUserService(newdata, param.id);
          console.log(" login response data=> ", resp);
          if (resp?.code === 200) {
            toast.success("User Data Successfully Edited");
          }
          navigate("/User-Management/" + storeID);
        } catch (err) {
          // alert(err);
        }
      } else {
        console.log("validation failed");
      }
    } else {
      console.log("test");
    }
    // else{
    //   setAddStoreValidation({'emailValidation': (addStoreData.email.length === 0 || !emailRegx.test(addStoreData.email)) ? false : true,'passwordValidation': (addStoreData.password.length === 0 || !passRegx.test(addStoreData.password)) ? false : true})
    // }
  };

  // custome style
  const styles = {
    icon: {
      width: "1.3rem",
    },
    labelforinput: {
      position: "absolute",
      right: "0px",
      bottom: "12px",
      background: "lightblue",
      width: "30px",
      height: "30px",
      borderRadius: "50%",
      lineHeight: "-1",
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    previewImage: {
      width: "120px",
      height: "120px",
      borderRadius: "50%",
    },

    selectContainer: {
      position: "relative",
    },

    chevIocn: {
      position: "absolute",
      zIndex: "999",
      right: "10px",
      top: "10px",
    },
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox display="flex" flexDirection="column" p={3}>
              <SoftTypography variant="h4">Edit User</SoftTypography>
            </SoftBox>

            <SoftBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              p={3}
            >
              <div className="image-wrapper" style={{ position: "relative" }}>
                <div className="image-upload">
                  <label htmlFor="file-input" style={styles.labelforinput}>
                    <EditRoundedIcon className="iconButton" />
                  </label>
                  <input
                    id="file-input"
                    type="file"
                    onChange={ImageUploaded}
                    accept="image/*"
                  />
                  {addPersonalInfo && (
                    <img
                      src={
                        addPersonalInfo.image === "" ||
                        addPersonalInfo.image === undefined
                          ? userPlaceholder
                          : process.env.REACT_APP_IMAGE_URL +
                            "sub_users/" +
                            addPersonalInfo.image
                      }
                      alt="Selected"
                      style={styles.previewImage}
                    />
                  )}
                </div>
              </div>
            </SoftBox>

            <SoftBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              p={3}
            >
              <Grid container spacing={2} mb={3}>
                <Grid item xs={12} sm={6}>
                  <SoftTypography variant="h4">
                    Personal Information
                  </SoftTypography>
                </Grid>
                <Grid item xs={12} sm={6}></Grid>
                <Grid item xs={12} sm={6}>
                  <SoftBox mb={1} ml={0.5}>
                    <SoftTypography
                      component="label"
                      fontSize="16px"
                      variant="caption"
                      fontWeight="bold"
                    >
                      First Name
                    </SoftTypography>
                  </SoftBox>
                  <SoftInput
                    type="text"
                    placeholder="First Name"
                    name="first_name"
                    value={addPersonalInfo.first_name}
                    onChange={handleChangeForPersonalInfo}
                    onBlur={handleChangeForPersonalInfo}
                  />
                  {addPersonalInfoValidation.first_name === false ? (
                    <div className="validation_message">
                      First Name is required
                    </div>
                  ) : (
                    <></>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SoftBox mb={1} ml={0.5}>
                    <SoftTypography
                      component="label"
                      fontSize="16px"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Last Name
                    </SoftTypography>
                  </SoftBox>
                  <SoftInput
                    type="text"
                    placeholder="Last Name"
                    name="last_name"
                    value={addPersonalInfo.last_name}
                    onChange={handleChangeForPersonalInfo}
                    onBlur={handleChangeForPersonalInfo}
                  />
                  {addPersonalInfoValidation.last_name === false ? (
                    <div className="validation_message">
                      Last Name is required
                    </div>
                  ) : (
                    <></>
                  )}
                </Grid>

                <Grid item xs={12} sm={6}>
                  <SoftBox mb={1} ml={0.5}>
                    <SoftTypography
                      component="label"
                      fontSize="16px"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Email address
                    </SoftTypography>
                  </SoftBox>
                  <SoftInput
                    type="text"
                    placeholder="email@address.com"
                    name="email"
                    value={addPersonalInfo.email}
                    onChange={handleChangeForPersonalInfo}
                    onBlur={(e) => {
                      handleChangeForPersonalInfo(e);
                      checkEmailExistence(
                        e.target.value,
                        addPersonalInfoValidation.email
                      );
                      
                    }}
                    disabled= {true}
                  />
                  {addPersonalInfoValidation.email === false ? (
                    <div className="validation_message">Email is not valid</div>
                  ) : isEmailAvail?.isValid === false ? (
                    <div className="validation_message">
                      {isEmailAvail?.message}
                    </div>
                  ) : (
                    <></>
                  )}
                </Grid>

                <Grid item xs={12} sm={6}>
                  <SoftBox mb={1} ml={0.5}>
                    <SoftTypography
                      component="label"
                      fontSize="16px"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Phone Number
                    </SoftTypography>
                  </SoftBox>
                  <SoftInput
                    type="number"
                    placeholder="Phone Number"
                    name="phone_number"
                    value={addPersonalInfo.phone_number}
                    onChange={handleChangeForPersonalInfo}
                    onBlur={handleChangeForPersonalInfo}
                  />
                  {addPersonalInfoValidation.phone_number === false ? (
                    <div className="validation_message">
                      Phone Number is required
                    </div>
                  ) : (
                    <></>
                  )}
                </Grid>

                {/* <Grid item xs={12} sm={6}>

                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography component='label' fontSize="16px" variant='caption' fontWeight='bold'>
                        Company Name
                      </SoftTypography>
                    </SoftBox>
                    <SoftInput type='text' placeholder='Lorem ipsum' />

                  </Grid>
                  <Grid item xs={12} sm={6}>

                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography component='label' fontSize="16px" variant='caption' fontWeight='bold'>
                        Company Logo
                      </SoftTypography>
                    </SoftBox>
                    <SoftInput type='file' placeholder='choosoe file' />

                  </Grid>

                  <Grid item xs={12} sm={6}>

                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography component='label' fontSize="16px" variant='caption' fontWeight='bold'>
                        SIRET Number
                      </SoftTypography>
                    </SoftBox>
                    <SoftInput type='text' placeholder='2334556677777' />

                  </Grid> */}

                <Grid item xs={12} sm={12}>
                  <SoftBox mb={1} ml={0.5}>
                    <SoftTypography
                      component="label"
                      fontSize="16px"
                      variant="caption"
                      fontWeight="bold"
                    >
                      User Type
                    </SoftTypography>
                  </SoftBox>

                  <FormControl style={styles.selectContainer} fullWidth>
                    <ArrowDropDownIcon style={styles.chevIocn} />
                    <Select
                      value={addPersonalInfo.user_type}
                      onChange={handleChangeForPersonalInfo}
                      name="user_type"
                    >
                      {/* <MenuItem value="Select an option"></MenuItem> */}
                      <MenuItem value="body_work_subcontractor">
                        DSP subcontractor
                      </MenuItem>
                      <MenuItem selected value="super_user">
                        Super User
                      </MenuItem>
                      <MenuItem value="body_repairer">Technician </MenuItem>
                      <MenuItem value="expert">Expert </MenuItem>
                      <MenuItem value="body">Body</MenuItem>
                      <MenuItem value="bodywork_team">Body Work Team </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                {/* <Grid item xs={12} sm={6}>

                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography component='label' fontSize="16px" variant='caption' fontWeight='bold'>
                        Country
                      </SoftTypography>
                    </SoftBox>
                    <FormControl style={styles.selectContainer} fullWidth>
                      <ArrowDropDownIcon style={styles.chevIocn} />
                      <Select value={selectedOptionCountry} onChange={handleChangeCountry} >
                        <MenuItem value="Select an option"></MenuItem>
                        <MenuItem selected value="option1">Option 1</MenuItem>
                        <MenuItem value="option2">Option 2</MenuItem>
                        <MenuItem value="option3">Option 3</MenuItem>
                      </Select>
                    </FormControl>

                  </Grid>

                  <Grid item xs={12} sm={6}>

                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography component='label' fontSize="16px" variant='caption' fontWeight='bold'>
                        Province/State
                      </SoftTypography>
                    </SoftBox>

                    <FormControl style={styles.selectContainer} fullWidth>
                      <ArrowDropDownIcon style={styles.chevIocn} />

                      <Select value={selectedOptionState} onChange={handleChangestate} >
                        <MenuItem value="Select an option">Select an option</MenuItem>
                        <MenuItem selected value="option1">Option 1</MenuItem>
                        <MenuItem value="option2">Option 2</MenuItem>
                        <MenuItem value="option3">Option 3</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>

                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography component='label' fontSize="16px" variant='caption' fontWeight='bold'>
                        City
                      </SoftTypography>
                    </SoftBox>
                    <FormControl style={styles.selectContainer} fullWidth>
                      <ArrowDropDownIcon style={styles.chevIocn} />

                      <Select value={selectedOptionState} onChange={handleChangestate} >
                        <MenuItem value="">Select an option</MenuItem>
                        <MenuItem selected value="option1">Option 1</MenuItem>
                        <MenuItem value="option2">Option 2</MenuItem>
                        <MenuItem value="option3">Option 3</MenuItem>
                      </Select>
                    </FormControl>

                  </Grid>

                  <Grid item xs={12} sm={6}>

                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography component='label' fontSize="16px" variant='caption' fontWeight='bold'>
                        Postal/Zip Code
                      </SoftTypography>
                    </SoftBox>
                    <SoftInput type='text' placeholder='145518' />
                  </Grid> */}
              </Grid>

              <Grid container spacing={2} pt={3}>
                <Grid item xs={12} md={6} xl={5}>
                  <SoftBox className="platformContainer">
                    <PlatformSettings
                      data={addUserFeature}
                      handelFunction={handleChangeForUserFeature}
                    />
                  </SoftBox>
                </Grid>
                <Grid item xs={12} md={6} xl={7}>
                  <Card>
                    <SoftBox p={3}>
                      {/* <Grid className="dataContainer" container spacing={2}>
                        <SoftTypography variant="h6" pt={1} px={2}>
                          Login information
                        </SoftTypography>

                        <Grid item xs={12} sm={12} pt={0}>
                          <SoftBox mb={1} ml={0.5} pt={0}>
                            <SoftTypography
                              className="subdetails"
                              component="label"
                              fontSize="16px"
                              variant="caption"
                              fontWeight="regular"
                            >
                              Password
                            </SoftTypography>
                          </SoftBox>
                          <SoftBox className="passEye">
                            {passShow1 ? (
                              <VisibilityIcon
                                onClick={() => setPassShow1((prev) => !prev)}
                              />
                            ) : (
                              <VisibilityOffIcon
                                onClick={() => setPassShow1((prev) => !prev)}
                              />
                            )}
                            <SoftInput
                              type={passShow1 ? "text" : "password"}
                              name="password"
                              placeholder="Enter Password"
                              value={addLoginInfo.password}
                              onChange={handleChangeForLoginInfo}
                              onBlur={handleChangeForLoginInfo}
                              disabled={addLoginInfo.genrate_password}
                            />
                            {addLoginInfoValidation.password === false ? (
                              <div className="validation_message">
                                Password must be of at least 8 alphanumeric
                                character include lowercase and uppercase
                                character
                              </div>
                            ) : (
                              <></>
                            )}
                          </SoftBox>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                          <SoftBox mb={1} ml={0.5}>
                            <SoftTypography
                              component="label"
                              fontSize="16px"
                              variant="caption"
                              fontWeight="regular"
                            >
                              Confirm Password
                            </SoftTypography>
                          </SoftBox>
                          <SoftBox className="passEye">
                            {passShow2 ? (
                              <VisibilityIcon
                                onClick={() => setPassShow2((prev) => !prev)}
                              />
                            ) : (
                              <VisibilityOffIcon
                                onClick={() => setPassShow2((prev) => !prev)}
                              />
                            )}
                            <SoftInput
                              type={passShow2 ? "text" : "password"}
                              name="confirmPssword"
                              placeholder="Enter Password to Confirm"
                              value={confirmPassword}
                              onChange={handleConfirmPassword}
                              onBlur={handleConfirmPassword}
                              disabled={addLoginInfo.genrate_password}
                            />
                            {confirmPasswordValidation === false ? (
                              <div className="validation_message">
                                Password do not match
                              </div>
                            ) : (
                              <></>
                            )}
                          </SoftBox>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <SoftBox display="flex" py={1} mb={0.25}>
                            <SoftBox mt={0.25}>
                              <Switch
                                checked={addLoginInfo.genrate_password}
                                name="genrate_password"
                                onChange={handleChangeForLoginInfo}
                              />
                            </SoftBox>
                            <SoftBox ml={1}>
                              <SoftTypography
                                variant="button"
                                fontWeight="regular"
                                color="text"
                              >
                                Generate password
                              </SoftTypography>
                            </SoftBox>
                          </SoftBox>
                        </Grid>

                        <Grid className="" item xs={12} sm={6}>
                          <SoftBox display="flex" py={1} mb={0.25}>
                            <SoftBox mt={0.25}>
                              <Switch
                                checked={addLoginInfo.send_credentials}
                                name="send_credentials"
                                onChange={handleChangeForLoginInfo}
                              />
                            </SoftBox>
                            <SoftBox ml={1}>
                              <SoftTypography
                                variant="button"
                                fontWeight="regular"
                                color="text"
                              >
                                Send credential to new user
                              </SoftTypography>
                            </SoftBox>
                          </SoftBox>
                        </Grid>
                      </Grid> */}

                      <Grid className="spce_top" container spacing={2}>
                        <SoftBox
                          display="flex"
                          justifyContent="start"
                          alignItems="start"
                          flexDirection="column"
                        >
                          <SoftTypography variant="h6" pt={4} px={2}>
                            Others
                          </SoftTypography>
                          {/* <SoftTypography component='label' fontSize="14px" variant='caption'>Initials are used to name documents</SoftTypography> */}
                        </SoftBox>

                        <Grid item xs={12} sm={12}>
                          <SoftBox mb={1} ml={0.5}>
                            <SoftTypography
                              component="label"
                              fontSize="16px"
                              variant="caption"
                              fontWeight="regular"
                            >
                              Initials
                            </SoftTypography>
                          </SoftBox>
                          <SoftInput
                            value={addPersonalInfo.initials}
                            name="initials"
                            placeholder="Enter Initials"
                            onChange={handleChangeForPersonalInfo}
                            onBlur={handleChangeForPersonalInfo}
                          />
                          {/* {addLoginInfoValidation.initials === false ? (
                            <div className="validation_message">
                              Initials are required
                            </div>
                          ) : (
                            <></>
                          )} */}
                        </Grid>
                      </Grid>
                    </SoftBox>
                  </Card>
                </Grid>
                <Grid
                  item
                  xs={12}
                  display={"flex"}
                  justifyContent={"start"}
                  alignContent={"start"}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ color: "white" }}
                    type="submit"
                    onClick={submit}
                    disabled={!isFormValid || !isEmailAvail?.isValid}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </SoftBox>
          </Card>
        </SoftBox>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EditUser;
