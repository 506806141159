/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import * as React from 'react';
import { useState, useEffect } from "react";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import SoftBox from "components/SoftBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import Button from '@mui/material/Button';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import { TextareaAutosize } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { universalFun } from 'util/httpService';
import { toast } from 'react-toastify';


function EditSubscription() {
  const {id} = useParams();
  const navigate = useNavigate()
  const [subscription, setSubscription] = useState({
    _id: "",
    description: "",
    features: "",
    plan_name: "",
    price: "",
    type: "update"
  });
  console.log("🚀 ~ EditSubscription ~ subscription:", subscription)


  const [dataValidation, setDataValidation] = useState({
    plan_name: true,
    price: true,
    description: true,
    features: true,
  
  })
  console.log("🚀 ~ EditSubscription ~ dataValidation:", dataValidation)

  useEffect(() => {
    singleSubscription();
  }, [])

  //bug with ckeditor show error after first render
  useEffect(()=>{
    ValidationCheck("features")
  },[subscription.features])

  // Details Of Single Subscription-
  const singleSubscription = async () => {
    try{
      const result = await universalFun("POST", `subscription`, {type: "get", id});
      setSubscription(result?.data?.response?.data)
      // console.log("result?.data?.response?.data", result?.data?.response?.data)
    }
    catch(error){
      console.log(error);
    }
  }

  // Handle Change-
  const handleChange = (field, e, editor) => {
    console.log(field)
    if (field !== "editor") {
      setSubscription((prevSubscription) => ({
        ...prevSubscription,
        [e.target.name]: e.target.value
      }));
      ValidationCheck(e.target.name)
     
    } else if (editor.getData()) { 
      const data = editor.getData();
      console.log("hello ", data)
      // const modifiedData = addTargetBlankToLinks(data);

      // Check if CKEditor data is not empty
      setSubscription((prevSubscription) => ({
        ...prevSubscription,
        "features": data
      }));
      ValidationCheck("features")
     
    }
  }

  const addTargetBlankToLinks = (html) => {
    // Use a regular expression to add target="_blank" to anchor tags
    const modifiedHtml = html.replace(/<a /g, '<a target="_blank" ');

    return modifiedHtml;
  };

  function ValidationCheck(name){
    switch(name){
      case 'plan_name' :
        setDataValidation((dataValidation)=>({...dataValidation,'plan_name':(
          subscription[name].length === 0) ? false : true})) 
      break;
      case 'price' :
        console.log(dataValidation)
        setDataValidation((dataValidation)=>({...dataValidation,'price':(subscription[name]?.length === 0) ? false : true}))  
      break;  
      case 'description' : 
      setDataValidation((dataValidation)=>({...dataValidation,'description':(
        subscription[name].length === 0 )  ? false : true}))
      break;
      case 'features' :
        setDataValidation((dataValidation)=>({...dataValidation,'features':(subscription[name].length === 0 ) ? false : true}))
      break;
      default :   
    }
   }


   function checkFuncForValues(obj){
    const keys = Object.keys(obj);
        if(keys.find((ele) => obj[ele].length === 0) === undefined){
          return true;
      }
      else{
        for(let i = 0; i <= keys.length; i++){
          ValidationCheck(keys[i]);
        }
       return false;
      }
   }
  
   function checkFuncForValidation(obj){
    const keys = Object.keys(obj);
         if(keys.find((ele) => obj[ele] === false) === undefined){
             return true;
         }
         else{
            for(let i = 0; i <= keys.length; i++){
              ValidationCheck(keys[i]);
            }
          return false;
         }
  
   }

  // Handle Submit-
  const handleSubmit = async () => {
    if(checkFuncForValues(subscription) === true){
      if(checkFuncForValidation(dataValidation) === true){
        try{
          const result = await universalFun("POST", "subscription", {...subscription, id: subscription?._id, type: "update"});
          if(result?.data?.response?.success){
            toast.success("Added Successfully")
          }
        }
        catch(error){
          console.log(error)
        }
      }
    }
  }

  // console.log("subscription 64", subscription)


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox mt={5}>
        <Card className="card crd_new">
          <SoftBox mb={2}>
            <SoftBox className="prce_subsptns" mb={2}>
              <SoftBox className="flex_basis" mr={3} mb={2}>
                <SoftBox mb={0} ml={0.5}>
                  <SoftTypography component='label' variant='caption' fontWeight='bold'>
                    Plan Name
                  </SoftTypography>
                </SoftBox>
                <SoftInput 
                  type='text' 
                  placeholder="Basic" 
                  name="plan_name"
                  value={subscription?.plan_name}
                  onChange={(e) => handleChange("plan_name", e)}
                  onBlur={(e) => handleChange("plan_name", e)}
                />
                 {dataValidation.plan_name === false ? (<div className="validation_message">Plan Name is required</div>) : <></>}
              </SoftBox>
              <SoftBox className="flex_basis" mb={2}>
                <SoftBox mb={0} ml={0.5}>
                  <SoftTypography component='label' variant='caption' fontWeight='bold'>
                    Price
                  </SoftTypography>
                </SoftBox>
                <SoftInput 
                  type='number' 
                  placeholder="€29" 
                  name="price"
                  value={subscription?.price}
                  onChange={(e) => handleChange("price", e)}
                  onBlur={(e) => handleChange("price", e)}
                  />
                  {dataValidation.price === false ? (<div className="validation_message">Price is required</div>) : <></>}
              </SoftBox>

            </SoftBox>
            <SoftBox mb={2} display="flex" flexDirection="column">
              <SoftTypography component='label' fontSize="13px" mb={1} variant='caption' fontWeight='bold'>
                Description
              </SoftTypography>
              <TextareaAutosize 
                type="text" 
                className="Textarea" 
                placeholder="Enter a description..." minRows={4} p={2} 
                name="description"
                value={subscription?.description}
                onChange={(e) => handleChange("description", e)}
                onBlur={(e) => handleChange("description", e)}
                />
                 {dataValidation.description === false ? (<div className="validation_message">Description is required</div>) : <></>}
            </SoftBox>
            <div className="App">
              <h4>Features</h4>
              <CKEditor
                editor={ClassicEditor}
                placeholder="Features"
                data={subscription?.features}
                onReady={editor => {
                  // You can store the "editor" and use when it is needed.
                  console.log('Editor is ready to use!', editor);
                }}
                onChange={(e, editor) => handleChange("editor", e, editor)}
                onBlur={ (e, editor) => handleChange("editor", e, editor) }
               
                // onFocus={(event, editor) => {
                //   console.log('Focus.', editor);
                // }}
              />
              {dataValidation.features === false ? (<div className="validation_message">Features is required</div>) : <></>}
            </div>
          </SoftBox>
        </Card>



        <SoftBox display="flex" className="gap-3 pb-4" justifyContent="center">

        <Button onClick={()=>navigate(-1)} variant="contained" color="primary" style={{ color: "white" }} fontWeight="medium">
            Back
          </Button>

          <Button onClick={handleSubmit} variant="contained" color="primary" style={{ color: "white" }} fontWeight="medium">
            Save
          </Button>
        </SoftBox>
      </SoftBox>


      <Footer />
    </DashboardLayout>
  );
}

export default EditSubscription;
