import axios from "axios";
import { toast } from "react-toastify";

const BASE_URL = process.env.REACT_APP_SERVER_URL;

export function getToken() {
  if (
    localStorage.getItem("remember_me") &&
    localStorage.getItem("remember_me") == "yes"
  ) {
    return localStorage.getItem("token");
  }
  if (
    localStorage.getItem("remember_me") &&
    localStorage.getItem("remember_me") == "no"
  ) {
    return sessionStorage.getItem("token");
  } else {
    return null;
  }
}

export async function isAuthenticated() {
  if (
    localStorage.getItem("remember_me") &&
    localStorage.getItem("remember_me") == "yes"
  ) {
    return localStorage.getItem("isLogged") == "true";
  }
  if (
    localStorage.getItem("remember_me") &&
    localStorage.getItem("remember_me") == "no"
  ) {
    return localStorage.getItem("isLogged") == "true";
  } else {
    return false;
  }
}

export function loggedUserDetails() {
  if (
    localStorage.getItem("remember_me") &&
    localStorage.getItem("remember_me") == "yes"
  ) {
    return JSON.parse(localStorage.getItem("user_details"));
  }
  if (localStorage.getItem("remember_me")) {
    return JSON.parse(sessionStorage.getItem("user_details"));
  } else {
    return null;
  }
}

export function objToQueryString(obj) {
  const searchParams = new URLSearchParams();
  const params = obj;
  Object.keys(params).forEach((key) => searchParams.append(key, params[key]));

  return searchParams.toString();
}

axios.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      // if token avaiable
      config.headers["Authorization"] = "Bearer " + token;
    }

    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  (error) => {
    if (
      error.response &&
      error.response.status === 401 &&
      error.response.data === "Unauthorized"
    ) {
      window.alert("Session Expired, Plesse login again");
      localStorage.clear();
      sessionStorage.clear();
      window.location.reload();
    } else {
      console.log("Server error -----", error);
      if (error?.response?.data?.errors) {
        toast.error(error?.response?.data?.errors?.msg);
      } else if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }else {
        // console.log("error data", error?.response?.data?.errors?.msg);
        toast.error(error?.message);
      }
    }
    return Promise.reject(error);
  }
);

export async function loginservice(body) {
  try {
    var res = await axios.post(`${BASE_URL}auth/admin/login`, body);
    return res;
  } catch (error) {
    throw new Error(error);
  }
}
export async function addFCMToken(body) {
  try {
    await axios.post(`${BASE_URL}admin/add-fcm-token`, body);
  } catch (error) {
    console.error(error);
  }
}
export async function forgotPasswordservice(body) {
  try {
    var res = await axios.patch(`${BASE_URL}auth/resetForgotPassword`, body);
    return res;
  } catch (error) {
    throw new Error(error);
  }
}

export async function addStoreService(body) {
  try {
    var res = await axios.post(`${BASE_URL}admin/add-store`, body);
    return res;
  } catch (error) {
    throw new Error(error);
  }
}

export async function uploadMedia(body) {
  try {
    var res = await axios.post(`${BASE_URL}admin/uploadAdminMedia`, body);
    return res;
  } catch (error) {
    throw new Error(error);
  }
}

export async function storeListService(body) {
  const data = objToQueryString(body);
  try {
    var res = await axios.get(`${BASE_URL}admin/get/store?${data}`);
    return res;
  } catch (error) {
    throw new Error(error);
  }
}

export async function viewStoreService(body) {
  const data = objToQueryString(body);
  try {
    var res = await axios.get(`${BASE_URL}admin/getstore?${data}`);
    return res;
  } catch (error) {
    throw new Error(error);
  }
}

export async function editStoreService(id) {
  try {
    var res = await axios.patch(`${BASE_URL}admin/update/store/status/${id}`);
    return res;
  } catch (error) {
    throw new Error(error);
  }
}

export async function deleteStoreService(id) {
  try {
    var res = await axios.delete(`${BASE_URL}admin/delete/store/${id}`);
    return res;
  } catch (error) {
    throw new Error(error);
  }
}

export async function addUserService(body) {
  try {
    var res = await axios.post(`${BASE_URL}admin/create/sub/user`, body);
    return res;
  } catch (error) {
    throw new Error(error);
  }
}

export async function userListService(body) {
  const data = objToQueryString(body);
  try {
    var res = await axios.get(`${BASE_URL}admin/get/sub/users?${data}`);
    return res;
  } catch (error) {
    throw new Error(error);
  }
}

export async function deleteUserService(id) {
  try {
    var res = await axios.delete(`${BASE_URL}admin/delete/subuser/${id}`);
    return res;
  } catch (error) {
    throw new Error(error);
  }
}

export async function editUserService(body, id) {
  if (id) {
    try {
      var res = await axios.patch(`${BASE_URL}admin/edit/subuser/${id}`, body);
      return res;
    } catch (error) {
      throw new Error(error);
    }
  }
}

export async function viewUserService(body) {
  try {
    var res = await axios.get(`${BASE_URL}admin/view/user/detail/${body}`);
    return res;
  } catch (error) {
    throw new Error(error);
  }
}

export async function getPrivacyPolicyService() {
  try {
    var res = await axios.get(`${BASE_URL}admin/get/cms/privacy_policy`);
    return res;
  } catch (error) {
    throw new Error(error);
  }
}

export async function updateCmsService(body) {
  try {
    var res = await axios.patch(`${BASE_URL}admin/update/cms`, body);
    return res;
  } catch (error) {
    throw new Error(error);
  }
}

export async function getTermAndConditionService() {
  try {
    var res = await axios.get(`${BASE_URL}admin/get/cms/terms`);
    return res;
  } catch (error) {
    throw new Error(error);
  }
}

// Profile-
export async function getProfileFunction(token) {
  try {
    var res = await axios.get(
      `${BASE_URL}admin/getAdminProfile?token=${token}`
    );
    return res;
  } catch (error) {
    throw new Error(error);
  }
}

export async function updateProfileFunction(token, body) {
  try {
    var res = await axios.patch(
      `${BASE_URL}admin/editAdminProfile?token=${token}`,
      body
    );
    return res;
  } catch (error) {
    throw new Error(error);
  }
}

// FAQs-
export async function getFaqsFunction() {
  try {
    var res = await axios.get(`${BASE_URL}admin/get/faqs`);
    return res;
  } catch (error) {
    throw new Error(error);
  }
}

export async function getSingleFaqFunction(id) {
  try {
    var res = await axios.get(`${BASE_URL}admin/getFaqDetails/${id}`);
    return res;
  } catch (error) {
    throw new Error(error);
  }
}

export async function addFaqFunction(body) {
  try {
    var res = await axios.post(`${BASE_URL}admin/add/faq`, body);
    return res;
  } catch (error) {
    throw new Error(error);
  }
}

export async function updateFaqFunction(id, body) {
  try {
    var res = await axios.patch(`${BASE_URL}admin/update/faq/${id}`, body);
    return res;
  } catch (error) {
    throw new Error(error);
  }
}

export async function deleteFaqFunction(id) {
  try {
    var res = await axios.post(`${BASE_URL}admin/delete/faq/${id}`);
    return res;
  } catch (error) {
    throw new Error(error);
  }
}

// About us-
const urls = [
  `${BASE_URL}admin/get/about/us/other?type=why_choose_us`,
  `${BASE_URL}admin/get/about/us/other?type=what_our_client_say`,
  `${BASE_URL}admin/get/about/us/other?type=our_services`,
];
export async function getAboutUsFunction() {
  try {
    const resposnes = await Promise.all(
      urls.map(async (url) => {
        try {
          const response = await axios.get(url);
          return response.data.response.data;
        } catch (error) {
          console.error("Error in request:", error);
          return null;
        }
      })
    );
    return resposnes;
  } catch (error) {
    throw new Error(error);
  }
}

// FAQs-
export async function getContactUsFunction(param) {
  let stringParams = "";

  if (param) {
    stringParams = objToQueryString(param);
  }
  try {
    var res = await axios.get(`${BASE_URL}admin/getContactUs?${stringParams}`);
    return res;
  } catch (error) {
    throw new Error(error);
  }
}

export async function getSingleContactUsFunction(id) {
  try {
    var res = await axios.get(`${BASE_URL}admin/get-contact-us/${id}`);
    return res;
  } catch (error) {
    throw new Error(error);
  }
}

export async function deleteContactUsFunction(_id) {
  try {
    var res = await axios.delete(`${BASE_URL}admin/delete/contactUs/${_id}`);
    return res;
  } catch (error) {
    throw new Error(error);
  }
}

// Testing Purpose-
export async function addFunction(body) {
  try {
    var res = await axios.post(`${BASE_URL}admin/addContactUs`, {
      first_name: "David",
      last_name: "Hussy",
      email: "hussy@gmail.com",
      phone: "1234567890",
      address: "Australia",
      subject: "Back Ligh New",
      message: "Please change backlight of my car",
      reply: "",
    });
    return res;
  } catch (error) {
    throw new Error(error);
  }
}

// Universal Function-
export async function universalFun(method, route, data = null, params = null) {
  try {
    const url = `${BASE_URL}admin/${route}`;
    var res = await axios({ method, url, data, params });
    return res;
  } catch (error) {
    throw new Error(error);
  }
}

export const fetchNotifications = async () => {
  const resp = await axios.get(`${BASE_URL}admin/get-notifications?limit=4`);
  return resp?.data?.response;
};
// addFunction()

export const checkMailExists = async (body) => {
  try {
    const resp = await axios.post(`${BASE_URL}users/isEmailExist`, body);
    return resp;
  } catch (e) {
    throw new Error(e);
  }
};

export const generateDeviceId = () => {
  // You can gather information from the user agent or other sources
  const userAgent = navigator.userAgent;
  const platform = navigator.platform;

  // Combine and hash this information to create a unique ID
  const newUniqueId = btoa(`${userAgent}-${platform}`);

  // Store the unique ID in localStorage
  localStorage.setItem("device_id", newUniqueId);
  // console.log("Device Id is", newUniqueId)
};

export * from "./httpService.js";
