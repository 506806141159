/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";

// Data
// import authorsTableData from "layouts/tables/data/authorsTableData";
// import projectsTableData from "layouts/tables/data/projectsTableData";
import Store from "./data/store";
import StoreData from "./data/storeData";
//Button
import Button from '@mui/material/Button';

//Links

import { Link } from "react-router-dom";
// import { Modal } from "@mui/material";

// import SoftTexrarea from "components/SoftTextarea";
// import CloseIcon from "@mui/icons-material/Close";
// import SoftBox from "components/SoftBox";
// import SoftTypography from "components/SoftTypography";

import Switch from "@mui/material/Switch";
// import { useState } from "react";


//logo
import BMWImage from 'assets/images/BMW.png';

//icons
import IconButton from '@mui/material/IconButton';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DeleteIcon from '@mui/icons-material/Delete'
import EditRoundedIcon from '@mui/icons-material/EditRounded';
// import Modal from '@mui/material/Modal';


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

function VehicleManagement() {
  const columns = [
    { name: "s.no.", align: "left" },
    { name: "image", align: "left" },
    { name: "model", align: "left" },
    { name: "year", align: "left" },
    { name: "registration number", align: "left" },
    { name: "Type of Paint", align: "left" },
    { name: "Type of Rims", align: "left" },
    { name: "Status", align: "center" },
    { name: "action", align: "center" },

  ]

  const rows = [
    {
      "s.no.": <SoftTypography variant="caption" color="secondary" fontWeight="medium" className="rspnv_tbls">
        1
      </SoftTypography>,

      "image": <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        <img className="logo_bmw" src={BMWImage} alt="logo"/>
      </SoftTypography>,
      
      "model": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          BMW
        </SoftTypography>
      ),
      "year": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          2019
        </SoftTypography>
      ),
      "registration number": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          AC-123-CD
        </SoftTypography>
      ),

      "Type of Paint": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          Vanish
        </SoftTypography>
      ),

      "Type of Rims": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            Polychrome
        </SoftTypography>
      ),


      "Status": (
        <SoftBox>
          <Switch />
        </SoftBox>
      ),

      "action": (
        <IconButton aria-label="action">
          <Link to="/View-Vehicle" className="actionIcon">
            <RemoveRedEyeIcon />
          </Link>
          <Link to="/Edit-Vehicle" className="actionIcon">
            <EditRoundedIcon />
          </Link>
          {/* <Link to="/" className="actionIcon" >
            <EmailIcon />
          </Link> */}
          <Link to="/" className="actionIcon">
            <DeleteIcon />
          </Link>
        </IconButton>
      ),
    },
    {
      "s.no.": <SoftTypography variant="caption" color="secondary" fontWeight="medium" className="rspnv_tbls">
        2
      </SoftTypography>,


      "image": <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        <img className="logo_bmw" src={BMWImage} alt="logo" />
      </SoftTypography>,

      "model": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          BMW
        </SoftTypography>
      ),
      "year": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          2019
        </SoftTypography>
      ),
      "registration number": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          AC-123-CD
        </SoftTypography>
      ),
      "Type of Paint": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
           Sorting Layers
        </SoftTypography>
      ),

      "Type of Rims": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            Two-tone
        </SoftTypography>
      ),

      "Status": (
        <SoftBox>
          <Switch />
        </SoftBox>
      ),

      "action": (
        <IconButton aria-label="action">
          <Link to="/View-Vehicle" className="actionIcon">
            <RemoveRedEyeIcon />
          </Link>
          <Link to="/Edit-Vehicle" className="actionIcon" >
            <EditRoundedIcon />
          </Link>
          {/* <Link to="/" className="actionIcon" >
            <EmailIcon />
          </Link> */}
          <Link to="/" className="actionIcon">
            <DeleteIcon />
          </Link>
        </IconButton>
      ),
    },
    {
      "s.no.": <SoftTypography variant="caption" color="secondary" fontWeight="medium" className="rspnv_tbls">
        3
      </SoftTypography>,


      "image": <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        <img className="logo_bmw" src={BMWImage} alt="logo"/>
      </SoftTypography>,

  

      "model": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          BMW
        </SoftTypography>
      ),
      "year": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          2019
        </SoftTypography>
      ),
      "registration number": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          AC-123-CD
        </SoftTypography>
      ),

      "Type of Paint": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
           Pearl
        </SoftTypography>
      ),

      "Type of Rims": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            United
        </SoftTypography>
      ),

      "Status": (
        <SoftBox>
          <Switch />
        </SoftBox>
      ),
      "action": (
        <IconButton aria-label="action">
          <Link to="/View-Vehicle" className="actionIcon">
            <RemoveRedEyeIcon />
          </Link>
          <Link to="/Edit-Vehicle" className="actionIcon" >
            <EditRoundedIcon />
          </Link>
          <Link to="/" className="actionIcon">
            <DeleteIcon />
          </Link>
        </IconButton>
      ),
    },
  ]


  // const { columns: prCols, rows: prRows } = Store;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftTypography variant="h6">List</SoftTypography>
            </SoftBox>
            <SoftBox className="TableContainer"
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <SoftBox className="">

                {/* <Table columns={columns} rows={rows} /> */}
                <Table columns={columns}  />
              </SoftBox>
            </SoftBox>
          </Card>
        </SoftBox>

      </SoftBox>
      <Footer />

    </DashboardLayout>
  );
}

export default VehicleManagement;


