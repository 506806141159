/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
// import SoftBadge from "components/SoftBadge";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";

// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import { useEffect, useState } from "react";
import { universalFun } from "util/httpService";

//Images
import team2 from "assets/images/team-2.jpg";
import placeholderUser from "../../assets/images/placeholderUser.jpeg";
import fallBackImage from "assets/images/FallbackImage.jpg";

//icons
import CheckCircleIcon from "@mui/icons-material/CheckCircle"; // Material-UI check circle icon
import CancelIcon from "@mui/icons-material/Cancel";
import IconButton from "@mui/material/IconButton";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import EmailIcon from "@mui/icons-material/Email";

//Links
import { Link } from "react-router-dom";
import Switch from "@mui/material/Switch";

//component
import { Author, Function } from "layouts/tables/data/authorsTableData";
import { Button } from "@mui/material";
import ConfirmPopover from "components/ConfirmBtn/ConfirmPopover";
import { toast } from "react-toastify";

function Tables() {
  // const { columns, rows } = authorsTableData;
  // const { Author, Function } = authorsTableData;

  const [rowData, setRow] = useState([]);
  const [requestList, setRequestList] = useState([]);
  console.log("🚀 ~ Tables ~ requestList:", requestList);

  const columns = [
    { name: "user", align: "left" },
    // { name: "user type", align: "left" },
    { name: "phone number", align: "left" },
    { name: "company name", align: "left" },
    { name: "description", align: "left", width: 200 },
    // { name: "Access", align: "center" },
    { name: "action", align: "center" },
  ];

  const getTableData = async () => {
    try {
      const result = await universalFun("get", "all-requests");
      console.log(result);
      setRequestList(result?.data?.data?.items);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTableData();
  }, []);

  // ******************************ACCEPT & REJECT REQuest****************************
  const [reqId, setReqId] = useState("");
  console.log("🚀 ~ Tables ~ reqId:", reqId);

  const handleReqAccept = async (reqId) => {
    if (!reqId) {
      console.log("id not found");
      return;
    }

    const params = {
      request: "accept",
    };
    const body = {
      requestId: reqId,
    };
    try {
      const resp = await universalFun("patch", "update-carModel", body, params);
      console.log(resp);
      // toast.info("Approved")
      getTableData();
    } catch (error) {
      console.log(error);
    }
  };

  const handleReqReject = async (reqId) => {
    if (!reqId) {
      console.log("id not found");
      return;
    }

    const params = {
      request: "decline",
    };
    const body = {
      requestId: reqId,
    };
    try {
      const resp = await universalFun("patch", "update-carModel", body, params);
      console.log(resp);
      //  toast.info("Rejected")
      getTableData();
    } catch (error) {
      console.log(error);
    }
  };

  const randerTable = () => {
    let rowDataTemp = [];

    requestList?.forEach((ele, i, arr) => {
      rowDataTemp.push({
        user: (
          <Author
            image={
              ele?.user?.company_logo
                ? process.env.REACT_APP_IMAGE_URL +
                  "profile_img/" +
                  ele?.user?.company_logo
                : fallBackImage
            }
            name={`${ele?.user?.first_name} ${ele?.user?.last_name}`}
            email={ele?.user?.email}
          />
        ),

        // "user type": ele.user_type && (
        //   <SoftTypography
        //     variant="caption"
        //     color="secondary"
        //     fontWeight="medium"
        //   >
        //     {ele.user_type}
        //   </SoftTypography>
        // ),

        "phone number": ele?.user?.phone_number && (
          <SoftTypography
            variant="caption"
            color="secondary"
            fontWeight="medium"
          >
            {ele?.user?.phone_number}
          </SoftTypography>
        ),

        "company name": ele?.user?.company_name && (
          <SoftTypography
            variant="caption"
            color="secondary"
            fontWeight="medium"
          >
            {ele?.user?.company_name}
          </SoftTypography>
        ),
        description: ele?.description && (
          <SoftTypography
            variant="caption"
            color="secondary"
            fontWeight="medium"
            style={{
              maxWidth: "200px",
            }}
            // maxWidth = "200px"
          >
            {ele?.description}
          </SoftTypography>
        ),

        // "siret number": ele.siret_number && (
        //   <SoftTypography
        //     variant="caption"
        //     color="secondary"
        //     fontWeight="medium"
        //   >
        //     {ele.siret_number}
        //   </SoftTypography>
        // ),
        // Access: (
        //   <SoftBox>
        //     <Switch
        //       checked={ele.super_admin_allowance}
        //       onChange={(e) =>
        //         handleTogle(e, "super_admin_allowance", ele._id, arr)
        //       }
        //     />
        //   </SoftBox>
        // ),

        action: (
          <div className="status-container">
            {ele?.status === "accept" ? (
              <>
                <CheckCircleIcon className="status-icon approved-icon" />
                <p className="status-text approved-text">Approved</p>
              </>
            ) : ele?.status === "decline" ? (
              <>
                <CancelIcon className="status-icon rejected-icon" />
                <p className="status-text rejected-text">Rejected</p>
              </>
            ) : (
              <div>
                <ConfirmPopover
                  buttonText="Accept"
                  reqId={ele?._id}
                  style={{
                    backgroundColor: "green",
                    color: "white",
                    width: "80px",
                    marginRight: "10px",
                  }}
                  confirmText="Are you sure you want to perform this action?"
                  onConfirm={handleReqAccept}
                />
                <ConfirmPopover
                  buttonText="Reject"
                  style={{
                    borderColor: "red",
                    backgroundColor: "white",
                    color: "red",
                    width: "80px",
                  }}
                  reqId={ele?._id}
                  confirmText="Are you sure you want to perform this action?"
                  onConfirm={handleReqReject}
                />
              </div>
            )}
          </div>
        ),

        // <IconButton aria-label="action">
        //   <Link to="" className="actionIcon">
        //     <RemoveRedEyeIcon />
        //   </Link>
        //   <Link to="" className="actionIcon">
        //     <EditRoundedIcon />
        //   </Link>
        //   <Link to="" className="actionIcon">
        //     <EmailIcon />
        //   </Link>
        //   <Link to="" className="actionIcon">
        //     <DeleteIcon />
        //   </Link>
        // </IconButton>
      });
    });
    // console.log("temp row",rowDataTemp)

    // console.log("row", rows)
    setRow(rowDataTemp);
  };

  useEffect(() => {
    randerTable();
  }, [requestList]);

  // console.log("test",authrData)

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={3}
            >
              <SoftTypography variant="h6">Authors Table</SoftTypography>
            </SoftBox>
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <Table columns={columns} rows={rowData} />
            </SoftBox>
          </Card>
        </SoftBox>
        {/* <Card>
          <SoftBox 
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={3}
          >
            <SoftTypography variant="h6">Projects table</SoftTypography>
          </SoftBox>
          <SoftBox
            sx={{
              "& .MuiTableRow-root:not(:last-child)": {
                "& td": {
                  borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                },
              },
            }}
          >
            <Table columns={prCols} />
          </SoftBox>
        </Card> */}
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Tables;
