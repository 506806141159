/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";


// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";



// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";

// Data
// import authorsTableData from "layouts/tables/data/authorsTableData";
// import projectsTableData from "layouts/tables/data/projectsTableData";

//Button
import Button from '@mui/material/Button';

//usestate

import { useState } from "react";

//image
import team2 from "assets/images/team-2.jpg";

// @mui material components
import Grid from "@mui/material/Grid";



//icons
// import EditRoundedIcon from '@mui/icons-material/EditRounded';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

//select
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

// Overview page components

// import PlatformSettings from "layouts/add-user/components/PlatformSettings";



function EditVehicle() {




  //switch

  // const [GeneratePassword, setGeneratePassword] = useState(true);
  // const [credential, setcredential] = useState(false);

  //Edit Image

  // const [selectedImage, setSelectedImage] = useState(team2);

  // const handleImageSelect = (event) => {
  //   setSelectedImage(URL.createObjectURL(event.target.files[0]));
  // };


  //select for Country

  // const [selectedOptionCountry, setselectedOptionCountry] = useState('option2');

  // const handleChangeCountry = (event) => {
  //   setselectedOptionCountry(event.target.value);
  // };


  //select for State

  // const [selectedOptionState, setselectedOptionState] = useState('option1');

  // const handleChangestate = (event) => {
  //   setselectedOptionState(event.target.value);
  // };

  //select for                        

  const [selectedOptionUser, setselectedOptionUser] = useState('European');

  const handleChangeUserType = (event) => {
    setselectedOptionUser(event.target.value);
  };

  const [selectedpaint, setselectedpaint] = useState('SuperUser');

  const handleChangePaintype = (event) => {
    setselectedpaint(event.target.value);
  };



  // custome style 
  const styles = {
    icon: {
      width: "1.3rem",

    },
    labelforinput: {
      position: "absolute",
      right: "0px",
      bottom: "12px",
      background: "lightblue",
      width: "30px",
      height: "30px",
      borderRadius: "50%",
      lineHeight: "-1",
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    previewImage: {
      width: "120px",
      height: "120px,",
      borderRadius: "50%"
    },

    selectContainer: {
      position: "relative"
    },

    chevIocn: {
      position: "absolute",
      zIndex: "999",
      right: "10px",
      top: "10px"
    }

  };


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            {/* <SoftBox display="flex" flexDirection="column" p={3}>
              <SoftTypography variant="h4">Edit Vehicle</SoftTypography>
            </SoftBox> */}

            {/* <SoftBox display="flex" justifyContent="center" alignItems="center" p={3}>
              <div className="image-wrapper" style={{ position: "relative" }}>

                <div class="image-upload">
                  <label for="file-input" style={styles.labelforinput}>
                    <EditRoundedIcon className="iconButton" />
                  </label>
                  <input id="file-input" type="file" onChange={handleImageSelect} />
                  {selectedImage && (
                    <img src={selectedImage} alt="Selected" style={styles.previewImage} />
                  )}
                </div>
              </div>
            </SoftBox> */}

            <SoftBox display="flex" justifyContent="center" alignItems="center" flexDirection="column" p={3}>

              <form>
                <Grid container spacing={2} mb={3}>
                  <Grid item xs={12} sm={6}>
                    <SoftTypography variant="h4"> Vehicle Information</SoftTypography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                  </Grid>
                  {/* <Grid item xs={12} sm={6}>

                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography component='label' fontSize="16px" variant='caption' fontWeight='bold'>
                        Client Name
                      </SoftTypography>
                    </SoftBox>
                    <SoftInput type='text' placeholder='John' />

                  </Grid> */}
                  <Grid item xs={12} sm={6}>

                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography component='label' fontSize="16px" variant='caption' fontWeight='bold'>
                        Model
                      </SoftTypography>
                    </SoftBox>
                    <SoftInput type='text' placeholder='BMW' />

                  </Grid>

                  <Grid item xs={12} sm={6}>

                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography component='label' fontSize="16px" variant='caption' fontWeight='bold'>
                        Year
                      </SoftTypography>
                    </SoftBox>
                    <SoftInput type='text' placeholder='2019' />

                  </Grid>

                  <Grid item xs={12} sm={6}>

                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography component='label' fontSize="16px" variant='caption' fontWeight='bold'>
                        Registration Number
                      </SoftTypography>
                    </SoftBox>
                    <SoftInput type='text' placeholder='AC-123-CD' />

                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography component='label' fontSize="16px" variant='caption' fontWeight='bold'>
                      Type of Paints
                      </SoftTypography>
                    </SoftBox>

                    <FormControl style={styles.selectContainer} fullWidth>
                      <ArrowDropDownIcon style={styles.chevIocn} />
                      <Select
                        value={selectedpaint}
                        onChange={handleChangePaintype}
                      >
                        <MenuItem value="Select an Varnish"></MenuItem>
                        <MenuItem value="DSPsubcontractor">Opaque</MenuItem>
                        <MenuItem selected value="SuperUser">Varnish</MenuItem>
                        <MenuItem value="Technician">Pearl</MenuItem>
                        <MenuItem value="Technician">Sorting Layers</MenuItem>

                      </Select>
                    </FormControl>

                  </Grid>
                  <Grid item xs={12} sm={6}>

                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography component='label' fontSize="16px" variant='caption' fontWeight='bold'>
                        Company Logo
                      </SoftTypography>
                    </SoftBox>
                    <SoftInput type='file' placeholder='choosoe file' />

                  </Grid>                 
              
                  <Grid item xs={12} sm={6}>
                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography component='label' fontSize="16px" variant='caption' fontWeight='bold'>
                        Type rims
                      </SoftTypography>
                    </SoftBox>

                    <FormControl style={styles.selectContainer} fullWidth>
                      <ArrowDropDownIcon style={styles.chevIocn} />
                      <Select
                        value={selectedOptionUser}
                        onChange={handleChangeUserType}
                      >
                        <MenuItem value="Select an Technician"></MenuItem>
                        <MenuItem value="DSPsubcontractor">United</MenuItem>
                        <MenuItem selected value="European">Polychrome</MenuItem>
                        <MenuItem value="Technician">Two-tone </MenuItem>

                      </Select>
                    </FormControl>

                  </Grid>
                  <Grid item xs={12} mt={3} display={"flex"} justifyContent={"center"} alignContent={"center"}>
                    <Button variant="contained" color="primary" style={{ color: "white" }} type="submit">
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </SoftBox>
          </Card>
        </SoftBox>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EditVehicle;
