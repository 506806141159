/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";

// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";

//Button
import Button from "@mui/material/Button";

//usestate

import { useState, useEffect } from "react";

//image
import team2 from "assets/images/team-2.jpg";

// @mui material components
import Grid from "@mui/material/Grid";
import CloseIcon from "@mui/icons-material/Close";

//icons
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

//select
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

// Overview page components

import PlatformSettings from "layouts/add-user/components/PlatformSettings";
import { Fade, Modal, TextareaAutosize } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getSingleContactUsFunction } from "util/httpService";
import { universalFun } from "util/httpService";
import { toast } from "react-toastify";
import { style } from "util/timeConverter";

function ViewContactUS() {
  const navigate = useNavigate();

  const [open1, setOpen1] = useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => {
    setOpen1(false);
    setReplayMsg("");
  };

  const [replayMsg, setReplayMsg] = useState("");
  const [replayId, setReplayId] = useState("");

  //switch

  const [GeneratePassword, setGeneratePassword] = useState(true);
  const [credential, setcredential] = useState(false);

  //Edit Image

  const [selectedImage, setSelectedImage] = useState(team2);

  const handleImageSelect = (event) => {
    setSelectedImage(URL.createObjectURL(event.target.files[0]));
  };

  //select for Country

  const [selectedOptionCountry, setselectedOptionCountry] = useState("option2");

  const handleChangeCountry = (event) => {
    setselectedOptionCountry(event.target.value);
  };

  //select for State

  const [selectedOptionState, setselectedOptionState] = useState("option1");

  const handleChangestate = (event) => {
    setselectedOptionState(event.target.value);
  };

  //select for

  const [selectedOptionUser, setselectedOptionUser] = useState("SuperUser");

  const handleChangeUserType = (event) => {
    setselectedOptionUser(event.target.value);
  };

  // custome style
  const styles = {
    icon: {
      width: "1.3rem",
    },
    labelforinput: {
      position: "absolute",
      right: "0px",
      bottom: "12px",
      background: "lightblue",
      width: "30px",
      height: "30px",
      borderRadius: "50%",
      lineHeight: "-1",
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    previewImage: {
      width: "120px",
      height: "120px,",
      borderRadius: "50%",
    },

    selectContainer: {
      position: "relative",
    },

    chevIocn: {
      position: "absolute",
      zIndex: "999",
      right: "10px",
      top: "10px",
    },
  };

  // Details of Contact Us-
  const { id } = useParams();

  useEffect(() => {
    getSingleContact();
  }, []);

  const [data, setData] = useState({});
  const getSingleContact = async () => {
    try {
      const result = await getSingleContactUsFunction(id);
      console.log("result", result?.data?.data);
      setData(result?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const replyHandler = async (e) => {
    e.preventDefault();
    const body = {
      message: replayMsg,
    };
    try {
      const resp = await universalFun("post", `send-reply/${id}`, body);
      console.log(resp);
      toast.success("Email Sent");
      handleClose1();
      // navigate("/Contact-Us")
      navigate(-1);
    } catch (error) {}
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            {/* <SoftBox display="flex" justifyContent="center" alignItems="center" p={3}>
              <div className="image-wrapper" style={{ position: "relative" }}>

                <div class="image-upload">
                  <label for="file-input" style={styles.labelforinput}>
                    <EditRoundedIcon className="iconButton" />
                  </label>
                  <input id="file-input" type="file" onChange={handleImageSelect} />
                  {selectedImage && (
                    <img src={selectedImage} alt="Selected" style={styles.previewImage} />
                  )}
                </div>
              </div>
            </SoftBox> */}

            <SoftBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              p={3}
            >
              <form>
                <Grid container spacing={2} mb={3}>
                  <Grid item xs={12} sm={6}>
                    <SoftTypography variant="h4">
                      Personal Information
                    </SoftTypography>
                  </Grid>
                  <Grid item xs={12} sm={6}></Grid>
                  <Grid item xs={12} sm={6}>
                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography
                        component="label"
                        fontSize="16px"
                        variant="caption"
                        fontWeight="bold"
                      >
                        First Name
                      </SoftTypography>
                    </SoftBox>
                    <SoftInput
                      type="text"
                      placeholder="John"
                      value={data?.first_name}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography
                        component="label"
                        fontSize="16px"
                        variant="caption"
                        fontWeight="bold"
                      >
                        Last Name
                      </SoftTypography>
                    </SoftBox>
                    <SoftInput
                      type="text"
                      placeholder="Jordan"
                      value={data?.last_name}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography
                        component="label"
                        fontSize="16px"
                        variant="caption"
                        fontWeight="bold"
                      >
                        Email Address
                      </SoftTypography>
                    </SoftBox>
                    <SoftInput
                      type="text"
                      placeholder="Eurobosse@gmail.com"
                      value={data?.email}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography
                        component="label"
                        fontSize="16px"
                        variant="caption"
                        fontWeight="bold"
                      >
                        Phone Number
                      </SoftTypography>
                    </SoftBox>
                    <SoftInput
                      type="text"
                      placeholder="9876543154"
                      value={data?.phone}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography
                        component="label"
                        fontSize="16px"
                        variant="caption"
                        fontWeight="bold"
                      >
                        Subject
                      </SoftTypography>
                    </SoftBox>
                    <SoftBox mb={2} display="flex" flexDirection="column">
                      <TextareaAutosize
                        type="text"
                        className="Textarea"
                        placeholder="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the"
                        minRows={1}
                        p={2}
                        value={data?.subject}
                      />
                    </SoftBox>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography
                        component="label"
                        fontSize="16px"
                        variant="caption"
                        fontWeight="bold"
                      >
                        Message
                      </SoftTypography>
                    </SoftBox>
                    <SoftBox mb={2} display="flex" flexDirection="column">
                      <TextareaAutosize
                        type="text"
                        className="Textarea"
                        placeholder="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the"
                        minRows={8}
                        p={2}
                        value={data?.message}
                      />
                    </SoftBox>
                  </Grid>
                </Grid>
              </form>
              <Grid
                item
                xs={12}
                display={"flex"}
                justifyContent={"center"}
                alignContent={"start"}
                className="gap-3"
              >
                <Button
                  onClick={() => navigate(-1)}
                  variant="contained"
                  color="primary"
                  style={{ color: "white" }}
                  fontWeight="medium"
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ color: "white" }}
                  onClick={handleOpen1}
                  fontWeight="medium"
                >
                  Reply
                </Button>
              </Grid>
            </SoftBox>
          </Card>
        </SoftBox>
      </SoftBox>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open1}
        onClose={handleClose1}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open1}>
          <SoftBox sx={style}>
            <SoftTypography
              component="label"
              fontSize="18px"
              variant="caption"
              fontWeight="bold"
              mb={2}
            >
              Reply
            </SoftTypography>
            <form onSubmit={replyHandler}>
              <SoftBox>
                <SoftBox display="flex" flexDirection="column">
                  <SoftBox mb={2} display="flex" flexDirection="column">
                    <TextareaAutosize
                      type="text"
                      className="Textarea"
                      placeholder=""
                      minRows={8}
                      p={2}
                      value={replayMsg}
                      onChange={(e) => setReplayMsg(e.target.value)}
                      required
                    />
                  </SoftBox>

                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    style={{ color: "white" }}
                    fontWeight="medium"
                  >
                    Reply
                  </Button>
                </SoftBox>
              </SoftBox>
            </form>
            <SoftBox className="cross-icn cursor-pointer">
              <CloseIcon onClick={handleClose1} />
            </SoftBox>
          </SoftBox>
        </Fade>
      </Modal>
      <Footer />
    </DashboardLayout>
  );
}

export default ViewContactUS;
