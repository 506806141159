
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Button from '@mui/material/Button';
import logo from "assets/images/logo.jpg"
import Grid from "@mui/material/Grid";

import EditRoundedIcon from '@mui/icons-material/EditRounded';
import {
  editStoreService,
  getProfileFunction,
  universalFun,
  updateProfileFunction,
} from "../../util/httpService";
import { viewStoreService } from "../../util/httpService";
import { uploadMedia } from "../../util/httpService";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";

import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { toast } from "react-toastify";

function EditProfile() {
  const navigate = useNavigate();

  const emailRegx = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
  const passRegx = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/;

  const params = useParams();

  const [userData, setUserData] = useState({ data: "", success: false });
  const [passwordData, setpasswordData] = useState({
    newPassword: "",
    oldPassword: "",
    cnfirmPassword: "",
  });

  const [passwordDataValidate, setpasswordDataValidate] = useState({
    newPassword: true,
    oldPassword: true,
    cnfirmPassword: true,
  });

  // get Profile function-
  const getProfile = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("Token not found in localStorage");
        return;
      }

      const response = await getProfileFunction(token);
      if (response) {
        console.log(response);
        setUserData({
          data: response?.data?.data,
          success: response?.data?.success,
        });
      }
    } catch (error) {
      console.error("Error", error);
    }
  };

  console.log(passwordData);
  console.log("userData 52", userData);

  useEffect(() => {
    getProfile();
  }, []);

  const [showSoftbox, setShowSoftbox] = useState(false);

  const [addStoreValidation, setAddStoreValidation] = useState({
    name: true,
    city: true,
    email: true,
    phone: true,
    state: true,
    country: true,
  });

  const [passShow1, setPassShow1] = useState(false);
  const [passShow2, setPassShow2] = useState(false);
  const [passShow3, setPassShow3] = useState(false);

  const handleChange = (event) => {
    console.log("blur", addStoreValidation);
    let name = event.target.name;
    let value = event.target.value;

    if (name === "city" || name === "state" || name === "country") {
      setUserData((userData) => ({
        ...userData,
        data: {
          ...userData.data,
          address: {
            ...userData.data.address,
            [event.target.name]: event.target.value,
          },
        },
      }));
    }
    setUserData((userData) => ({
      ...userData,
      data: {
        ...userData.data,
        [event.target.name]: event.target.value,
      },
    }));
    ValidationCheck(event.target.name);
  };

  //  console.log(userData, )

  //validation check for password
  function validationCheckPasswd(e) {
    switch (e.target.name) {
      case "oldPassword":
        setpasswordDataValidate((prev) => ({
          ...prev,
          oldPassword: e.target.value?.length === 0 ? false : true,
        }));
        break;
      case "newPassword":
        setpasswordDataValidate((prev) => ({
          ...prev,
          newPassword:
            e.target.value?.length < 8 || !passRegx.test(e.target.value)
              ? false
              : true,
        }));
        break;
      case "cnfirmPassword":
        setpasswordDataValidate((prev) => ({
          ...prev,
          cnfirmPassword:
            e.target.value === "" || e.target.value !== passwordData.newPassword
              ? false
              : true,
        }));
        break;
      default:
    }
  }

  //validation check for form
  function ValidationCheck(name) {
    switch (name) {
      case "name":
        setAddStoreValidation((addStoreValidation) => ({
          ...addStoreValidation,
          name: userData.data[name]?.length === 0 ? false : true,
        }));
        break;
      case "city":
        setAddStoreValidation((addStoreValidation) => ({
          ...addStoreValidation,
          city: userData.data.address[name].length === 0 ? false : true,
        }));
        break;
      case "email":
        setAddStoreValidation((addStoreValidation) => ({
          ...addStoreValidation,
          email:
            userData.data[name].length === 0 ||
            !emailRegx.test(userData.data[name])
              ? false
              : true,
        }));
        break;
      case "phone":
        setAddStoreValidation((addStoreValidation) => ({
          ...addStoreValidation,
          phone: userData.data[name].length === 0 ? false : true,
        }));
        break;
      case "state":
        setAddStoreValidation((addStoreValidation) => ({
          ...addStoreValidation,
          state: userData.data.address[name].length === 0 ? false : true,
        }));
        break;
      case "country":
        setAddStoreValidation((addStoreValidation) => ({
          ...addStoreValidation,
          country: userData.data.address[name].length === 0 ? false : true,
        }));
        break;
      case "description":
        setAddStoreValidation((addStoreValidation) => ({
          ...addStoreValidation,
          description: userData.data[name].length === 0 ? false : true,
        }));
        break;
      case "oldPassword":
        setpasswordDataValidate((prev) => ({
          ...prev,
          oldPassword: passwordData[name].length === 0 ? false : true,
        }));
        break;
      case "newPassword":
        setpasswordDataValidate((prev) => ({
          ...prev,
          newPassword: passwordData[name].length === 0 ? false : true,
        }));
        break;
      case "cnfirmPassword":
        setpasswordDataValidate((prev) => ({
          ...prev,
          cnfirmPassword: passwordData[name].length === 0 ? false : true,
        }));
        break;

      default:
    }
  }

  function checkFuncForValues(obj) {
    const keys = Object.keys(obj);
    if (keys.find((ele) => obj[ele].length === 0) === undefined) {
      return true;
    } else {
      for (let i = 0; i <= keys.length; i++) {
        ValidationCheck(keys[i]);
      }
      return false;
    }
  }

  function checkFuncForValidation(obj) {
    const keys = Object.keys(obj);
    if (keys.find((ele) => obj[ele] === false) === undefined) {
      return true;
    } else {
      for (let i = 0; i <= keys.length; i++) {
        ValidationCheck(keys[i]);
      }
      return false;
    }
  }

  const ImageUploaded = async (event) => {
    let file = event.target.files[0];
    const type = file.type;
    console.log("file name", file);
    if (type.includes("image")) {
      try {
        let formData = new FormData();
        formData.append("path", "profile_img");
        formData.append("media", file);
        let resp = await uploadMedia(formData);
        if (resp?.status === 200) {
          console.log(" login response data=> ", resp);
          setUserData((userData) => ({
            ...userData,
            data: {
              ...userData.data,
              profile_img: resp?.data?.media,
            },
          }));
          // alert("image file is selected");
        }
      } catch (error) {
        
      }
    } else {
      toast.error("Only Images are supported");
    }
  };

  //  const submit = async () => {
  //   alert(1)
  //   return;
  //   console.log(userData,"check",checkFuncForValues(userData))

  //   if(checkFuncForValues(userData) === true){
  //     if(checkFuncForValidation(addStoreValidation) === true){
  //       let filteredKeyData = Object.fromEntries(Object.entries(userData).filter(([key]) => key));
  //       console.log("data",filteredKeyData)
  //      if(filteredKeyData.password.length === 0){
  //       delete filteredKeyData.password
  //       }
  //    const data = filteredKeyData;
  //    try {
  //      const resp = await editStoreService(data);
  //      console.log(" login response data=> ", resp);
  //      if(resp?.status === 200){
  //         alert("Store Data Edited Successfully")
  //      }
  //      navigate('/StoreManagement')
  //    } catch (err) {
  //       alert(err)
  //    }
  //   }
  //   else{
  //     console.log("validation failed")
  //   }
  // }
  //   else{
  //     console.log("test")
  //   }
  //   // else{
  //   //   setAddStoreValidation({'emailValidation': (userData.email.length === 0 || !emailRegx.test(userData.email)) ? false : true,'passwordValidation': (userData.password.length === 0 || !passRegx.test(userData.password)) ? false : true})
  //   // }
  // }

  const submit = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Token not found in localStorage");
      return;
    }
    if (checkFuncForValues(userData.data) === true) {
      if (checkFuncForValidation(addStoreValidation) === true) {
        const payload = userData?.data;
        try {
          const result = await updateProfileFunction(token, payload);
          if (result) {
            toast.success("Profile Updated Successfully");
            navigate("/profile");
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  const handlePassChange = (e) => {
    setpasswordData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
    validationCheckPasswd(e);
  };

  const submitPsChnge = async () => {
    if (checkFuncForValues(passwordData) === true) {
      if (checkFuncForValidation(passwordDataValidate) === true) {
        const body = {
          id: userData?.data?.id,
          password: passwordData.newPassword,
          oldpassword: passwordData.oldPassword,
        };
        console.log(body);
        try {
          const result = await universalFun("POST", "change/password", body);
          if (result) {
            toast.success("Profile Updated Successfully");
            navigate("/profile");
          }
        } catch (error) {
         
          
        }
      }
    }
  };

  const styles = {
    icon: {
      width: "1.3rem",
    },
    labelforinput: {
      position: "absolute",
      right: "0px",
      bottom: "12px",
      background: "lightblue",
      width: "30px",
      height: "30px",
      borderRadius: "50%",
      lineHeight: "-1",
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    previewImage: {
      width: "120px",
      height: "120px",
      borderRadius: "50%",
    },

    selectContainer: {
      position: "relative",
    },

    chevIocn: {
      position: "absolute",
      zIndex: "999",
      right: "10px",
      top: "10px",
    },
  };

  console.log("userData 285", userData);

  const TabPanel = ({ value, index, children }) => {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
      >
        {value === index && <div>{children}</div>}
      </div>
    );
  };

  const [value, setValue] = React.useState(0);

  const handleChange5 = (event, newValue) => {
    // console.log(newValue)
    setValue(newValue);
    setShowSoftbox((prev) => !prev);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox display="flex" flexDirection="column" p={3}>
              <SoftTypography variant="h4">Edit Profile</SoftTypography>
            </SoftBox>

            <SoftBox
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              p={3}
            >
              <div className="image-wrapper" style={{ position: "relative" }}>
                <div className="image-upload">
                  <label htmlFor="file-input" style={styles.labelforinput}>
                    <EditRoundedIcon className="iconButton" />
                  </label>
                  <input id="file-input" type="file" onChange={ImageUploaded} />
                  {userData && (
                    <img
                      src={
                        !userData?.data?.profile_img
                          ? logo
                          : process.env.REACT_APP_IMAGE_URL +
                            "profile_img/" +
                            userData?.data?.profile_img
                      }
                      alt="Selected"
                      style={styles.previewImage}
                    />
                  )}
                </div>
              </div>
              <SoftTypography className="text-center" variant="h5">
                Upload or Add Logo
              </SoftTypography>
            </SoftBox>

            <div className="profile_Edit " spacing={2}>
              <Tabs value={value} onChange={handleChange5} centered>
                <Tab label="Profile Information" />
                <Tab label="Change Password" />
              </Tabs>

              {/* <TabPanel value={value} index={0}> */}

              {!showSoftbox && (
                <SoftBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  p={3}
                >
                  <Grid container spacing={2} mb={3}>
                    <Grid item xs={12} sm={6}>
                      <SoftBox mb={1} ml={0.5}>
                        <SoftTypography
                          component="label"
                          fontSize="16px"
                          variant="caption"
                          fontWeight="bold"
                        >
                          Full Name
                        </SoftTypography>
                      </SoftBox>
                      <SoftInput
                        type="text"
                        placeholder="Full Name"
                        name="name"
                        value={userData?.data?.name}
                        onChange={handleChange}
                        onBlur={handleChange}
                      />
                      {addStoreValidation?.name === false ? (
                        <div className="validation_message">
                          Full Name is required
                        </div>
                      ) : (
                        <></>
                      )}
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <SoftBox mb={1} ml={0.5}>
                        <SoftTypography
                          component="label"
                          fontSize="16px"
                          variant="caption"
                          fontWeight="bold"
                        >
                          Location
                        </SoftTypography>
                      </SoftBox>
                      <SoftInput
                        type="text"
                        placeholder="Location"
                        name="city"
                        value={userData?.data?.address?.city}
                        onChange={handleChange}
                        onBlur={handleChange}
                      />
                      {addStoreValidation?.city === false ? (
                        <div className="validation_message">
                          Location is required
                        </div>
                      ) : (
                        <></>
                      )}
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <SoftBox mb={1} ml={0.5}>
                        <SoftTypography
                          component="label"
                          fontSize="16px"
                          variant="caption"
                          fontWeight="bold"
                        >
                          Email address
                        </SoftTypography>
                      </SoftBox>
                      <SoftInput
                        type="text"
                        name="email"
                        placeholder="john@gmail.com"
                        value={userData?.data?.email}
                        onChange={handleChange}
                        onBlur={handleChange}
                      />
                      {addStoreValidation?.email === false ? (
                        <div className="validation_message">
                          Email is required
                        </div>
                      ) : (
                        <></>
                      )}
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <SoftBox mb={1} ml={0.5}>
                        <SoftTypography
                          component="label"
                          fontSize="16px"
                          variant="caption"
                          fontWeight="bold"
                        >
                          Mobile
                        </SoftTypography>
                      </SoftBox>
                      <SoftInput
                        type="number"
                        placeholder="+123456789"
                        name="phone"
                        value={userData?.data?.phone}
                        onChange={handleChange}
                        onBlur={handleChange}
                      />
                      {addStoreValidation?.phone === false ? (
                        <div className="validation_message">
                          Phone Number is required
                        </div>
                      ) : (
                        <></>
                      )}
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <SoftBox mb={1} ml={0.5}>
                        <SoftTypography
                          component="label"
                          fontSize="16px"
                          variant="caption"
                          fontWeight="bold"
                        >
                          State
                        </SoftTypography>
                      </SoftBox>
                      <SoftInput
                        type="text"
                        name="state"
                        placeholder=""
                        value={userData?.data?.address?.state}
                        onChange={handleChange}
                        onBlur={handleChange}
                      />
                      {addStoreValidation?.state === false ? (
                        <div className="validation_message">
                          State is required
                        </div>
                      ) : (
                        <></>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SoftBox mb={1} ml={0.5}>
                        <SoftTypography
                          component="label"
                          fontSize="16px"
                          variant="caption"
                          fontWeight="bold"
                        >
                          Country
                        </SoftTypography>
                      </SoftBox>
                      <SoftInput
                        type="text"
                        name="country"
                        placeholder="Country"
                        value={userData?.data?.address?.country}
                        onChange={handleChange}
                        onBlur={handleChange}
                      />
                      {addStoreValidation?.country === false ? (
                        <div className="validation_message">
                          Country is required
                        </div>
                      ) : (
                        <></>
                      )}
                    </Grid>

                    {/* <Grid item xs={12} sm={6}>

                      <SoftBox mb={1} ml={0.5}>
                        <SoftTypography component='label' fontSize="16px" variant='caption' fontWeight='bold'>
                          Facebook
                        </SoftTypography>
                      </SoftBox>
                      <SoftInput type='text' name='facebookUrl' placeholder='Facebook link' value={userData?.data?.facebookUrl} onChange={handleChange} onBlur={handleChange} />
                    </Grid>

                    <Grid item xs={12} sm={6}>

                      <SoftBox mb={1} ml={0.5}>
                        <SoftTypography component='label' fontSize="16px" variant='caption' fontWeight='bold'>
                          Twitter
                        </SoftTypography>
                      </SoftBox>
                      <SoftInput type='text' name='twitterUrl' placeholder='Twitter link' value={userData?.data?.twitterUrl} onChange={handleChange} onBlur={handleChange} />
                    </Grid>

                    <Grid item xs={12} sm={6}>

                      <SoftBox mb={1} ml={0.5}>
                        <SoftTypography component='label' fontSize="16px" variant='caption' fontWeight='bold'>
                          Instagram
                        </SoftTypography>
                      </SoftBox>
                      <SoftInput type='text' name='instagramUrl' placeholder='Instagram link' value={userData?.data?.instagramUrl} onChange={handleChange} onBlur={handleChange} />
                    </Grid> */}

                    <Grid item xs={12} sm={12} md={12} pt={0}>
                      <SoftBox mb={1} ml={0.5} pt={0}>
                        <SoftTypography
                          className="subdetails"
                          name="description"
                          component="label"
                          fontSize="16px"
                          variant="caption"
                          fontWeight="bold"
                        >
                          Desciption
                        </SoftTypography>
                      </SoftBox>
                      <SoftInput
                        type="text"
                        name="description"
                        placeholder="Description"
                        value={userData?.data?.description}
                        onChange={handleChange}
                        onBlur={handleChange}
                      />
                      {addStoreValidation?.description === false ? (
                        <div className="validation_message">
                          Desciption is required
                        </div>
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </Grid>
                </SoftBox>
              )}
              {showSoftbox && (
                <SoftBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  p={3}
                >
                  <Grid container spacing={2} mb={3}>
                    <Grid item xs={12} md={6} sm={6}>
                      <SoftBox mb={1} ml={0.5}>
                        <SoftTypography
                          component="label"
                          fontSize="16px"
                          variant="caption"
                          fontWeight="bold"
                        >
                          Current Password
                        </SoftTypography>
                      </SoftBox>
                      <SoftBox className="passEye">
                        {passShow1 ? (
                          <VisibilityIcon
                            onClick={() => setPassShow1((prev) => !prev)}
                          />
                        ) : (
                          <VisibilityOffIcon
                            onClick={() => setPassShow1((prev) => !prev)}
                          />
                        )}
                        <SoftInput
                          type={passShow1 ? "text" : "password"}
                          placeholder="Current Password"
                          name="oldPassword"
                          onChange={handlePassChange}
                          onBlur={handlePassChange}
                        />
                        {passwordDataValidate?.oldPassword === false ? (
                          <div className="validation_message">
                            Current Password is required
                          </div>
                        ) : (
                          <></>
                        )}
                      </SoftBox>
                    </Grid>

                    <Grid item xs={12} md={6} sm={6}>
                      <SoftBox mb={1} ml={0.5}>
                        <SoftTypography
                          component="label"
                          fontSize="16px"
                          variant="caption"
                          fontWeight="bold"
                        >
                          New Password
                        </SoftTypography>
                      </SoftBox>
                      <SoftBox className="passEye">
                        {passShow2 ? (
                          <VisibilityIcon
                            onClick={() => setPassShow2((prev) => !prev)}
                          />
                        ) : (
                          <VisibilityOffIcon
                            onClick={() => setPassShow2((prev) => !prev)}
                          />
                        )}
                        <SoftInput
                          type={passShow2 ? "text" : "password"}
                          name="newPassword"
                          placeholder="New Password"
                          onChange={handlePassChange}
                          onBlur={handlePassChange}
                        />
                        {passwordDataValidate?.newPassword === false ? (
                          <div className="validation_message">
                            Password must be of at least 8 alphanumeric
                            character include lowercase and uppercase character
                          </div>
                        ) : (
                          <></>
                        )}
                      </SoftBox>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <SoftBox mb={1} ml={0.5}>
                        <SoftTypography
                          component="label"
                          fontSize="16px"
                          variant="caption"
                          fontWeight="bold"
                        >
                          Confirm Password
                        </SoftTypography>
                      </SoftBox>
                      <SoftBox className="passEye">
                        {passShow3 ? (
                          <VisibilityIcon
                            onClick={() => setPassShow3((prev) => !prev)}
                          />
                        ) : (
                          <VisibilityOffIcon
                            onClick={() => setPassShow3((prev) => !prev)}
                          />
                        )}
                        <SoftInput
                          type={passShow3 ? "text" : "password"}
                          name="cnfirmPassword"
                          placeholder="Confirm Password"
                          onChange={handlePassChange}
                          onBlur={handlePassChange}
                        />
                        {passwordDataValidate?.cnfirmPassword === false ? (
                          <div className="validation_message">
                            Password do not match
                          </div>
                        ) : (
                          <></>
                        )}
                      </SoftBox>
                    </Grid>
                  </Grid>
                </SoftBox>
              )}
              {/* </TabPanel> */}
            </div>

            <Grid
              item
              xs={12}
              pb={3}
              display={"flex"}
              justifyContent={"center"}
              alignContent={"start"}
            >
              {!showSoftbox ? (
                <Button
                  variant="contained"
                  color="primary"
                  style={{ color: "white" }}
                  onClick={submit}
                >
                  Submit
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  style={{ color: "white" }}
                  onClick={submitPsChnge}
                >
                  Submit
                </Button>
              )}
            </Grid>
          </Card>
        </SoftBox>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

// function EditProfile(){
// return(
//   <div>
//     <h3>hello</h3>
//     {console.log("run")}
//     </div>
// )
// }

export default EditProfile;
