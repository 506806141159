import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import logo from "assets/images/logo.jpg"
import fallBackImage from "assets/images/FallbackImage.jpg"
import Grid from "@mui/material/Grid";

import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { universalFun, viewStoreService } from "../../../util/httpService";
import { uploadMedia } from "../../../util/httpService";
import { useNavigate } from 'react-router-dom';

import {useParams} from 'react-router-dom';
import {useState,useEffect} from "react";
import Button from "@mui/material/Button";

function ViewStore() {

const navigate = useNavigate()

  const [viewStoreData, setviewStoreData] = useState({
    company_logo:'',
    first_name:'',
    last_name:'',
    company_name:'',
    email:'',
    phone_number:'',
    siret_number:'',
  });
  console.log("🚀 ~ file: index.js:31 ~ ViewStore ~ viewStoreData:", viewStoreData)

  const params = useParams();


  useEffect(() => {
    async function getStoreData (){
      console.log("parms",params)
        //  let obj = {
        //   id: params.id
        //  }
         try {
           const resp = await universalFun("get",`get/store/${params.id}`,null );
           console.log("response data=> ", resp);
          if(resp?.status === 200){
             const data = resp.data?.data;
             setviewStoreData({
              company_logo:data.company_logo,
              first_name:data.first_name,
              last_name:data.last_name,
              company_name:data.company_name,
              email:data.email,
              phone_number:data.phone_number,
              siret_number:data.siret_number,
             })
            }
           }
           catch (err) {
            // alert(err)c
            console.log(err)
         }
     }
     getStoreData();
   },[])
   
   

 
  const styles = {
    icon: {
      width: "1.3rem",

    },
    labelforinput: {
      position: "absolute",
      right: "0px",
      bottom: "12px",
      background: "lightblue",
      width: "30px",
      height: "30px",
      borderRadius: "50%",
      lineHeight: "-1",
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    previewImage: {
      width: "120px",
      height: "120px",
      borderRadius: "50%",
      objectFit: "cover",
      
    },

    selectContainer: {
      position: "relative"
    },

    chevIocn: {
      position: "absolute",
      zIndex: "999",
      right: "10px",
      top: "10px"
    }

  };


   


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox display="flex" flexDirection="column" p={3}>
              <SoftTypography variant="h4">View Store</SoftTypography>
            </SoftBox>

            <SoftBox display="flex" flexDirection="column" justifyContent="center" alignItems="center" p={3}>
              <div className="image-wrapper" style={{ position: "relative" }}>

                {/* <div className="image-upload">
                  <label htmlFor="file-input" style={styles.labelforinput}>
                    <EditRoundedIcon className="iconButton" />
                  </label> */}
                  {viewStoreData && (
                    <img src={(!viewStoreData.company_logo)? fallBackImage : process.env.REACT_APP_IMAGE_URL + "profile_img/" + viewStoreData.company_logo} alt="Selected" style={styles.previewImage} />
                  )}
                </div>
              {/* </div> */}
              {/* <SoftTypography className="text-center" variant="h5">Upload or Add Logo</SoftTypography> */}

            </SoftBox>

            <SoftBox display="flex" justifyContent="center" alignItems="center" flexDirection="column" p={3}>

                <Grid container spacing={2} mb={3}>
                  <Grid item xs={12} sm={6}>
                    <SoftTypography variant="h4">Store Information</SoftTypography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                  </Grid>
                  <Grid item xs={12} sm={6}>

                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography component='label' fontSize="16px" variant='caption' fontWeight='bold'>
                        First Name
                      </SoftTypography>
                    </SoftBox>
                    <SoftInput type='text' placeholder='First Name' name='first_name' value={viewStoreData.first_name} readOnly/>
                  </Grid>
                  <Grid item xs={12} sm={6}>

                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography component='label' fontSize="16px" variant='caption' fontWeight='bold'>
                        Last Name
                      </SoftTypography>
                    </SoftBox>
                    <SoftInput type='text'  placeholder='Last Name' name='last_name' value={viewStoreData.last_name} readOnly/>
                  </Grid>

                  <Grid item xs={12} sm={6}>

                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography component='label' fontSize="16px" variant='caption' fontWeight='bold'>
                        Business Email address
                      </SoftTypography>
                    </SoftBox>
                    <SoftInput type='text' name='email'  placeholder='email@address.com' value={viewStoreData.email} readOnly/>
                  </Grid>

                  <Grid item xs={12} sm={6}>

                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography component='label' fontSize="16px" variant='caption' fontWeight='bold'>
                        Phone Number
                      </SoftTypography>
                    </SoftBox>
                    <SoftInput type='number'  placeholder='phone number' name='phone_number' value={viewStoreData.phone_number} readOnly/>
                  </Grid>

                  <Grid item xs={12} sm={6}>

                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography component='label' fontSize="16px" variant='caption' fontWeight='bold'>
                        Company Name
                      </SoftTypography>
                    </SoftBox>
                    <SoftInput type='text' name='company_name' placeholder='company name' value={viewStoreData.company_name} readOnly/>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6}>

                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography component='label' fontSize="16px" variant='caption' fontWeight='bold'>
                        SIRET Number
                      </SoftTypography>
                    </SoftBox>
                    <SoftInput type='text' name='siret_number' placeholder='SIRET Number' value={viewStoreData.siret_number} readOnly/>
                  </Grid>

                  {/* <Grid item xs={12} sm={12} md={6} pt={0}>

                    <SoftBox mb={1} ml={0.5} pt={0}>
                      <SoftTypography className="subdetails" name='password' component='label' fontSize="16px" variant='caption' fontWeight='bold'>
                        Password
                      </SoftTypography>
                    </SoftBox>
                    <SoftInput type='password' name='password' placeholder='password' value={viewStoreData.password}/>
                  </Grid> */}
                  <Grid item
                   xs={12}
                  display={"flex"}
                  justifyContent={"center"}
                  alignContent={"start"}
                  className="gap-3 mt-3"     
                  >
                  <Button onClick={()=>navigate(-1)} variant="contained" color="primary" style={{ color: "white" }} fontWeight="medium">
            Back
          </Button>
                  </Grid>
                 
                </Grid>
            
            </SoftBox>
          </Card>
        </SoftBox>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ViewStore;
