import React from 'react';
import Typography from '@mui/material/Typography';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import Fade from '@mui/material/Fade';
import  noData from   "assets/images/icons/Nodata02.png";

const EmptyComponent = ({ message = 'No data found' }) => {
  return (
    <Fade in={true} timeout={1000}>
   <div style={{ display: 'flex', alignItems: 'center', flexDirection:"column" , justifyContent: 'center', height: '400px' }}>
      <img style={{ height: '80px', width: '80px' }} src={noData}  alt =  'Data not found'/>
        {/* <SentimentDissatisfiedIcon style={{ fontSize: '48px', color: '#FF5252', marginBottom: '10px' }} /> */}
        <Typography variant="body1" color="textSecondary" style={{ marginTop: '10px', color: '#757575', fontStyle: 'italic' }}>
          {message}
        </Typography>
      </div>
    </Fade>
  );
};

export default EmptyComponent;
